import { NavBar, NavToolbar } from '../styled';
import { NavMenuAction } from "../UI";
import { useAppSelector } from '../../redux/Store';
import { ProfileMenu } from '../UI/Chip_ProfileMenu';
import { useMediaWidth } from '../../hooks';
import { DynamicThemeSwitch } from '../UI';
import { 
    Box,
    Grid, 
    useTheme, 
} from '@mui/material';


export function NavHeader() {
    const { Authenticated, userDetails } = useAppSelector(( state ) => state.Access )
    const theme = useTheme()
    let mode = theme.palette.mode
    const isMobile = useMediaWidth({ direction: 'down', breakPoint: 'laptop' })

    return (
        <NavBar className="NavBar">
            <NavToolbar variant='dense' className='headerToolBar'>
                <Grid container columns={ 2 }>
                    
                    <Grid item xsmobile={ 1 }
                        sx={{
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            margin: 0
                        }}
                    >
                        { Authenticated && !isMobile && (
                            <NavMenuAction
                                outlined
                                buttonSize='small'
                                iconSize='medium'
                                color={ mode === 'dark' ? 'info' : 'primary' }
                            />
                        )}
                        { Authenticated && isMobile && (
                            <Box sx={{ marginLeft: '0.3rem' }}>
                                <DynamicThemeSwitch
                                    size='small'
                                    color={ mode === 'dark' ? 'info' : 'primary' }
                                    disabled={ !isMobile }
                                />
                            </Box>
                        )}

                    </Grid>

                    <Grid item xsmobile={ 1 }
                        sx={{
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            margin: 0
                        }}
                    >
                        { Authenticated && !isMobile && (
                            <Box sx={{ marginRight: '1rem' }}>
                                <DynamicThemeSwitch
                                    size='small'
                                    color={ mode === 'dark' ? 'info' : 'primary' }
                                    disabled={ isMobile }
                                />
                            </Box>
                        )}

                        { Authenticated ? (
                            <ProfileMenu
                                theme={ theme }
                                chipLabel={ userDetails?.FirstName || '' }
                                fullName={ `${ userDetails?.FirstName } ${ userDetails.LastName }` || '' }
                            />
                        ) : null }

                    </Grid>
                
                </Grid>
            </NavToolbar>
        </NavBar>
    )
}