import React, { useEffect } from 'react';
import {
    Grid,
    Typography,
    Card,
    CardContent,
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { HomeContent } from '../../components/styled';
import { useAppSelector, useAppDispatch } from '../../redux/Store';
import { toggleLoading } from '../../redux/slicers/AppState';
import { getFullPackageDetail } from '../../redux/AccessThunks';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../constants/enums';
import { HomeHeader } from './HomeHeader';
import { PackageCard } from './PackageCard';


export default function Home() {
    const { session, basicPackageDetail } = useAppSelector(( state ) => state.Access )
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const isMobile = useMediaQuery( theme.breakpoints.down( 'tablet' ))

    // useEffect(() => {
    //     window.resizeTo(window.innerWidth, window.innerHeight);
    //     window.scrollTo( 0, 0 )
    // }, [])

    const handlePackageClick = async ( BenefitID: number ) => {
        dispatch( toggleLoading( true ))
        if ( !session.ContactID ) return
        const response = await dispatch( getFullPackageDetail({ ContactID: session.ContactID, BenefitID: BenefitID })).unwrap()
        if ( response.success ) {
            dispatch( toggleLoading( false ))
            navigate( RoutePaths.PACKAGE )
        }
    }

    const sortedPackagesByStatus = [ ...basicPackageDetail ].sort(( a, b ) => {
        const statusOrder: Array< string > = [ 'Active', 'Under Reconciliation', 'Ended' ]
        return statusOrder.indexOf( a.BenefitStatus ) - statusOrder.indexOf( b.BenefitStatus )
    })

    return (
        <React.Fragment>
            <HomeHeader />
            <HomeContent className='home-container'>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        Width: '100%',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start'
                    }}
                >
                    { basicPackageDetail && (
                        sortedPackagesByStatus?.map(( benefit ) => (

                            <PackageCard
                                isMobile={ isMobile }
                                key={ benefit.BenefitID }
                                theme={ theme }
                                benefit={ benefit } 
                                onCardClick={ handlePackageClick } 
                            />
                        
                        ))
                    )}

                    { basicPackageDetail.length === 0 && (
                        <Card
                            sx={{
                                backdropFilter: 'blur(10px)',
                                width: isMobile ? '90%' : '50%',
                                marginTop: '1.6rem',
                                elevation: 1,
                                backgroundColor: theme.palette.mode === 'dark' ? 
                                    theme.palette.overlays.level1 : theme.palette.background.paper
                            }}
                        >
                            <CardContent>
                                <Typography variant='subtitle1Bold'>No packages were found</Typography>
                            </CardContent>
                        </Card>
                    )}

                </Box>
            </HomeContent>
        </React.Fragment>
    )
}
