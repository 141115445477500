import React, { useEffect, useState } from 'react';
import { columnBase } from '../../../constants';
import { EditIcon, AddIcon, SaveIcon, CancelIcon } from '../Icons';
import { IconButton, Grid, Box, Typography, Divider, useTheme, Card } from '@mui/material';
import { ToolTipWrapper } from '../ToolTipWrapper';

interface DataGroupProps {
    title?: string;
    titleUpper?: boolean;
    isEditable?: boolean;
    editing?: boolean;
    onEditClick?: () => void;
    onAddClick?: () => void;
    onSaveClick?: () => void;
    onCancelClick?: () => void;
    subGroup?: boolean;
    className?: string;
    children: React.ReactNode;
    hideBackground?: boolean;
    containerRef?: ( v: number ) => void;
    minHeight?: number | null ;
    xsmobile?: number;
    mobile?: number;
    tablet?: number;
    laptop?: number;
    desktop?: number;
}

export function DataGroup({ title, children, ...props }: DataGroupProps ) {
    const {
        titleUpper,
        isEditable,
        editing,
        onEditClick,
        onAddClick,
        onSaveClick,
        onCancelClick,
        subGroup,
        className,
        hideBackground,
        containerRef,
        minHeight,
        xsmobile,
        mobile,
        tablet,
        laptop,
        desktop
    } = props
    const [ innerHeight, setInnerHeight ] = useState( 0 )
    const theme = useTheme()
    const isDarkMode = theme.palette.mode === 'dark'

    useEffect(() => {
        if ( containerRef ) containerRef( innerHeight )
    }, [ innerHeight ])

    const EditIconContainer = () => (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
            }}
        >
            <Typography variant={ subGroup ? 'dataGroupSubTitle' : 'h6' } 
                sx={{ 
                    textTransform: titleUpper ? 'uppercase' : '',
                    marginRight: 'auto'
                }}
            >
                { title ? title : '' }
            </Typography>
            {
                isEditable && onEditClick && !onAddClick && (
                    <Box>
                        {
                            !editing ? 
                                <ToolTipWrapper 
                                    title='Edit'
                                    placement='top'
                                >
                                    <IconButton onClick={ onEditClick }
                                        sx={{
                                            color: theme.palette.dataGroupIconButtons.editIcon
                                        }}
                                    >
                                        <EditIcon size='small' color='inherit' outlined={ isDarkMode } />
                                    </IconButton>
                                </ToolTipWrapper>
                            : (
                                <React.Fragment>
                                    <ToolTipWrapper 
                                        title='Cancel Changes'
                                        placement='top'
                                    >
                                        <IconButton onClick={ onCancelClick }
                                            sx={{
                                                color: theme.palette.dataGroupIconButtons.cancelIcon
                                            }}
                                        >
                                            <CancelIcon size='small' color='warning' outlined={ isDarkMode } />
                                        </IconButton>
                                    </ToolTipWrapper>
                                    {
                                        onSaveClick && (
                                            <ToolTipWrapper 
                                                title='Save Changes'
                                                placement='top'
                                            >
                                                <IconButton onClick={ onSaveClick } 
                                                    sx={{
                                                        color: theme.palette.dataGroupIconButtons.saveIcon
                                                    }}
                                                >
                                                    <SaveIcon size='small' color='inherit' outlined={ isDarkMode } />
                                                </IconButton>
                                            </ToolTipWrapper>
                                        )
                                    }
                                </React.Fragment>
                            )
                        }
                    </Box>
                )
            }
            {
                isEditable && onEditClick && onAddClick && (
                    <Box>
                        
                        {
                            !editing ?
                                <ToolTipWrapper 
                                    title='Edit'
                                    placement='top'
                                >
                                    <IconButton onClick={ onEditClick }
                                        sx={{
                                            color: theme.palette.dataGroupIconButtons.editIcon
                                        }}
                                    >
                                        <EditIcon size='small' color='inherit' outlined={ isDarkMode } />
                                    </IconButton>
                                </ToolTipWrapper>
                            : (
                                <React.Fragment>
                                    <ToolTipWrapper 
                                        title='Cancel Changes'
                                        placement='top'
                                    >
                                        <IconButton onClick={ onCancelClick }
                                            sx={{
                                                color: theme.palette.dataGroupIconButtons.cancelIcon
                                            }}
                                        >
                                            <CancelIcon size='small' color='warning' outlined={ isDarkMode } />
                                        </IconButton>
                                    </ToolTipWrapper>
                                    {
                                        onSaveClick && (
                                            <ToolTipWrapper 
                                                title='Save Changes'
                                                placement='top'
                                            >
                                                <IconButton onClick={ onSaveClick }
                                                    sx={{
                                                        color: theme.palette.dataGroupIconButtons.saveIcon
                                                    }}
                                                >
                                                    <SaveIcon size='small' color='inherit' outlined={ isDarkMode } />
                                                </IconButton>
                                            </ToolTipWrapper>
                                        )
                                    }
                                </React.Fragment>
                            )
                        }

                        {
                            !editing && (
                                <ToolTipWrapper 
                                    title='Add'
                                    placement='top'
                                >
                                    <IconButton onClick={ onAddClick }
                                        sx={{
                                            color: theme.palette.dataGroupIconButtons.addIcon
                                        }}
                                    >
                                        <AddIcon size='small' color="info" outlined={ isDarkMode } />
                                    </IconButton>
                                </ToolTipWrapper>
                            )
                        }

                    </Box>
                )
            }
            {
                !isEditable && !onEditClick && onAddClick && (
                    <Box>
                        
                        {
                            onCancelClick && editing && (
                                <ToolTipWrapper 
                                    title='Cancel Changes'
                                    placement='top'
                                >
                                    <IconButton onClick={ onCancelClick }
                                        sx={{
                                            color: theme.palette.dataGroupIconButtons.cancelIcon
                                        }}
                                    >
                                        <CancelIcon size='small' color='warning' outlined={ isDarkMode } />
                                    </IconButton>
                                </ToolTipWrapper>
                            )
                        }

                        {
                            onCancelClick && editing && (
                                <ToolTipWrapper 
                                    title='Save Changes'
                                    placement='top'
                                >
                                    <IconButton onClick={ onSaveClick }
                                        sx={{
                                            color: theme.palette.dataGroupIconButtons.saveIcon
                                        }}
                                    >
                                        <SaveIcon size='small' color='inherit' outlined={ isDarkMode } />
                                    </IconButton>
                                </ToolTipWrapper>
                            )
                        }
                        {
                            !editing && (
                                <ToolTipWrapper 
                                    title='Add'
                                    placement='top'
                                >
                                    <IconButton onClick={ onAddClick }
                                        sx={{
                                            color: theme.palette.dataGroupIconButtons.addIcon
                                        }}
                                    >
                                        <AddIcon size='small' color="info" outlined={ isDarkMode } />
                                    </IconButton>
                                </ToolTipWrapper>
                            )
                        }
                    </Box>
                )
            }
        </Box>
    )

    return (
        <Grid item key={ title }
            className={ className ? className : '' }
            xsmobile={ xsmobile ? xsmobile : false }
            mobile={ mobile ? mobile : false }
            tablet={ tablet ? tablet : false }
            laptop={ laptop ? laptop : false }
            desktop={ desktop ? desktop : false }
        >
            {
                hideBackground ? (
                    <Box
                        sx={{ 
                            padding: 1,
                            width: '100%',
                            minWidth: '100%',
                            paddingTop: '1rem',
                            backgroundColor: 'transparent',
                            borderRadius: '0.3rem'
                        }}
                    >
                        { title ? (
                            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item
                                    sx={{
                                        mb: !subGroup ? 0 : 0.5
                                    }}
                                >
                                    <EditIconContainer />
                                </Grid>
                            </Grid>
                        ) : null }
                    
                        <Grid item container
                            ref={ containerRef ? ( v: any ) => setInnerHeight( v?.clientHeight ) : null }
                            key={ title + 'data' }
                            display='flex' 
                            flexDirection="column" 
                            columns={ columnBase } 
                            spacing={ 1 } 
                            sx={{ flexFlow: 'wrap', minHeight: minHeight ? minHeight : null }}
                            xsmobile={ 4 } mobile={ 5 } tablet={ 8 } laptop={ 10 } desktop={ 12 }
                        >
                            { children }
                        </Grid>
                    </Box>
                ) : (
                    <Card
                        sx={{ 
                            padding: 1,
                            width: '100%',
                            minWidth: '100%',
                            paddingTop: '1rem',
                            backgroundColor: isDarkMode ? 
                                theme.palette.overlays.level1 : theme.palette.background.paper,
                            borderRadius: '0.3rem'
                        }}
                    >
                        { title ? (
                            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item
                                    sx={{
                                        mb: !subGroup ? 0 : 0.5
                                    }}
                                >
                                    <EditIconContainer />
                                </Grid>
                            </Grid>
                        ) : null }
                    
                        <Grid item container
                            ref={ containerRef ? ( v: any ) => setInnerHeight( v?.clientHeight ) : null }
                            key={ title + 'data' }
                            display='flex' 
                            flexDirection="column" 
                            columns={ columnBase } 
                            spacing={ 1 } 
                            sx={{ flexFlow: 'wrap', minHeight: minHeight ? minHeight : null }}
                            xsmobile={ 4 } mobile={ 5 } tablet={ 8 } laptop={ 10 } desktop={ 12 }
                        >
                            { children }
                        </Grid>
                    </Card>
                )
            }
        </Grid>
    )
}