import React from 'react';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import { IconButton } from "@mui/material";
import { NavMenuIcon, NavMenuOpenIcon } from './Icons';
import { useAppSelector, useAppDispatch } from "../../redux/Store";
import { toggleNavMenu } from '../../redux/slicers/AppState';
import { useAuth } from '../../hooks/useAuth';

interface NavMenuActionProps {
    buttonSize: "small" | "medium" | "large",
    iconSize: "small" | "medium" | "large" | "inherit",
    color: "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
    outlined: boolean
}

export function NavMenuAction( { ...props }: NavMenuActionProps ) {
    const { buttonSize, color, outlined, iconSize } = props
    const { Authenticated } = useAuth()
    const { navMenu } = useAppSelector(( state ) => state.AppState )
    const dispatch = useAppDispatch()
    
    const handleMenuAction = () => {
        dispatch( toggleNavMenu( !navMenu ))
    }

    return (
        <IconButton size={ buttonSize } onClick={ handleMenuAction } color={ color } disabled={ !Authenticated }>

            { !navMenu ? (
                    <NavMenuIcon outlined={ outlined } size={ iconSize } color='inherit' />
                ) : (
                    <NavMenuOpenIcon outlined={ outlined } size={ iconSize } color='inherit' />
                )
            }

        </IconButton>
    )
}