import React, { useState, useId } from 'react';
import { Popover, Typography, IconButton, useTheme, Box } from '@mui/material';
import { HelpIcon } from './Icons';


interface HelpPopoverProps {
    content: React.ReactNode;

}

export const HelpPopover = ({ content }: HelpPopoverProps ) => {
    const [ anchorEl, setAnchorEl ] = useState< HTMLElement | null >( null )
    const id = useId()
    const theme = useTheme()
    let isDarkMode = theme.palette.mode === 'dark'

    const onClick = ( event: React.MouseEvent< HTMLElement > ) => {
        setAnchorEl( event.currentTarget )
    }

    const onClose = () => {
        setAnchorEl( null )
    }

    const open = Boolean( anchorEl )

    return (
        <Box>
            <IconButton 
                size='small'
                aria-describedby={ id } 
                onClick={ onClick } 
            >
                <HelpIcon outlined={ isDarkMode } color='inherit' size="small" />
            </IconButton>
            <Popover
                id={ id }
                open={ open }
                anchorEl={ anchorEl }
                onClose={ onClose }
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ padding: '0.5rem' }}>{ content }</div>
            </Popover>
        </Box>
    )
}