import React from 'react';
import { Box, InputAdornment, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { InputStandard, FilledInputStandard, OutlinedInputStandard, InputLabelStandard, TextAreaStandard } from '../styled';


interface TextInputStandardProps {
    label: string;
    placeholder?: string;
    value: number | string | undefined | null;
    variant: 'outlined' | 'filled' | 'standard';
    id?: string;
    isTextArea?: boolean;
    textAreaRows?: number | string;
    onChange: ( v: any ) => void;
    disabled?: boolean;
    error?: boolean;
    helpText?: string;
    required?: boolean;
    size?: 'small' | 'medium';
    type?: 'text' | 'password' | 'email' | 'number' | 'search';
    inputProps?: any;
    startAdornment?: any;
    endAdmornment?: any;
    autoComplete?: 'on' | 'off' | 'new-password';
    inputRef?: any;
}

export function StandardTextInput({ ...props }: TextInputStandardProps) {
    const {
        label,
        placeholder,
        value,
        onChange,
        variant,
        id,
        disabled,
        error,
        helpText,
        required,
        size,
        type,
        inputProps,
        startAdornment,
        endAdmornment,
        isTextArea,
        textAreaRows,
        autoComplete,
        inputRef
    } = props;
    
    return (
        <FormControl 
            fullWidth
            variant={ variant }
        >
            { label && !isTextArea && (
                <InputLabelStandard disabled={ disabled ? disabled : false }>{ label }</InputLabelStandard>
            )}
            { variant === 'standard' && !isTextArea && (
                <InputStandard
                    { ...( id ? { id: id } : {} )}
                    fullWidth
                    placeholder={ placeholder ? placeholder : '' }
                    value={ value ? value : '' }
                    onChange={ ( value ) => onChange( value.currentTarget.value ) }
                    disabled={ disabled ? disabled : false }
                    error={ error ? error : false }
                    required={ required ? required : false }
                    size={ size ? size : 'small' }
                    type={ type ? type : 'text' }
                    inputProps={ inputProps ? inputProps : undefined }
                    startAdornment={ startAdornment ? startAdornment : null }
                    endAdornment={ endAdmornment ? endAdmornment : null }
                    autoComplete={ autoComplete ? autoComplete : 'on' }
                    inputRef={ inputRef ? inputRef : undefined }
                />
            )}
            { variant === 'filled' && !isTextArea && (
                <FilledInputStandard 
                    { ...( id ? { id: id } : {} )}
                    fullWidth
                    placeholder={ placeholder ? placeholder : '' }
                    value={ value ? value : '' }
                    onChange={ ( value ) => onChange( value.currentTarget.value ) }
                    disabled={ disabled ? disabled : false }
                    error={ error ? error : false }
                    required={ required ? required : false }
                    size={ size ? size : 'small' }
                    type={ type ? type : 'text' }
                    inputProps={ inputProps ? inputProps : undefined }
                    startAdornment={ startAdornment ? startAdornment : null }
                    endAdornment={ endAdmornment ? endAdmornment : null }
                    autoComplete={ autoComplete ? autoComplete : 'on' }
                    inputRef={ inputRef ? inputRef : undefined }
                /> 
            )}
            { variant === 'outlined' && !isTextArea && (
                <OutlinedInputStandard 
                    { ...( id ? { id: id } : {} )}
                    fullWidth
                    placeholder={ placeholder ? placeholder : '' }
                    value={ value ? value : '' }
                    onChange={ ( value ) => onChange( value.currentTarget.value ) }
                    disabled={ disabled ? disabled : false }
                    error={ error ? error : false }
                    required={ required ? required : false }
                    size={ size ? size : 'small' }
                    type={ type ? type : 'text' }
                    inputProps={ inputProps ? inputProps : undefined }
                    startAdornment={ startAdornment ? startAdornment : null }
                    endAdornment={ endAdmornment ? endAdmornment : null }
                    autoComplete={ autoComplete ? autoComplete : 'on' }
                    inputRef={ inputRef ? inputRef : undefined }
                /> 
            )}
            { isTextArea && textAreaRows && (
                <TextAreaStandard
                    { ...( id ? { id: id } : {} )}
                    label={ label }
                    fullWidth
                    variant={ variant }
                    multiline
                    rows={ `${ textAreaRows }` }
                    placeholder={ placeholder ? placeholder : '' }
                    value={ value ? value : '' }
                    onChange={ ( value ) => onChange( value.currentTarget.value ) }
                    disabled={ disabled ? disabled : false }
                    error={ error ? error : false }
                    required={ required ? required : false }
                    size={ size ? size : 'small' }
                    type={ type ? type : 'text' }
                    inputProps={ inputProps ? inputProps : undefined }
                    autoComplete={ autoComplete ? autoComplete : 'on' }
                    inputRef={ inputRef ? inputRef : undefined }
                />
            )}
            { helpText && error && (
                <FormHelperText error>{ helpText }</FormHelperText>
            )}
        </FormControl>
    )
}

