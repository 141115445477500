import React from 'react';
import { Typography } from '@mui/material';
import { StyledContentHeader } from '../../components/styled';

interface ContentHeaderProps {
    vehicle: string;
    status: string;
    rego: string;
}

export function ContactUsHeader() {
    return (
        <StyledContentHeader>
            <Typography variant='h5'>
                Contact Us
            </Typography>
            
            <Typography variant='body1'>
               We are here to help!
            </Typography>

        </StyledContentHeader>
    )
}