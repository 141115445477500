import React from 'react';
import { Typography, Grid } from '@mui/material';
import { deleteEmail } from '../../../redux/AccessThunks';
import { AppDispatch } from '../../../redux/Store';
import { emailTypeIDConverter } from '../../../components/commonUIF';
import { Dialog_DetailEdit } from '../../../components/UI';


interface DeleteEmailDialogProps {
    show: boolean;
    close: () => void;
    contactID: number;
    EmailID: number;
    EmailTypeID: number;
    EmailAddress: string;
    isOnlyEmail: boolean;
    dispatch: AppDispatch;
}

export const DeleteEmailDialog = ({ ...props }: DeleteEmailDialogProps ) => {
    const { show, close, contactID, EmailID, EmailTypeID, EmailAddress, isOnlyEmail, dispatch } = props

    const onCancel = () => {
        close()
    }

    const onDelete = async () => {
        if( isOnlyEmail ) {
            alert( 'This is the only email address we have for you. Therefore, computer says no.' )
            return
        }
        await dispatch( deleteEmail({ ContactID: contactID, EmailID: EmailID }))
        close()
    }

    return (
        <Dialog_DetailEdit
            show={ show }
            title='Please Confirm'
            confirmLabel='Yes'
            cancelLabel='Whoops, No!'
            slideDirection='up'
            onConfirm={ onDelete }
            onCancel={ onCancel }
        >
            <Grid container spacing={ 2 }>
                <Grid item>
                    <Typography variant='body1'>
                        This action cannot be undone. Are you sure you want to delete this Email Address?
                        <br />
                        <Typography variant='body1Bold'>{ EmailAddress }</Typography>
                    </Typography>
                </Grid>
            </Grid>
        </Dialog_DetailEdit>
    )
}