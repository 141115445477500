import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RootLayout, PreAuthLayout } from "../components/layout";
import { RequireAuth } from "./";
import Home from "../pages/Home";
import Login from "../pages/Login";
import PasswdReset from "../pages/ResetPassword";
import PackageDetail from "../pages/PackageDetail";
import PackageAccount from "../pages/PackageAccount";
import LeasingFAQs from "../pages/LeasingFAQs";
import Documents from "../pages/Documents";
import Profile from "../pages/Profile";
import ContactUs from "../pages/ContactUs";
import Reimbursements from "../pages/Reimbursements";
import NewRegistrations from "../pages/Registrations";
import RegistrationsJWT from "../pages/Registrations/jwt";
import ResetPasswdJWT from "../pages/ResetPassword/jwt";
import Route404 from "./Route404";
import { RoutePaths } from "../constants/enums";
import { LicenseInfo } from "@mui/x-license-pro";
const MUI = process.env.MUI_LICENCE_KEY ? process.env.MUI_LICENCE_KEY : '';


export function RouteProvider() {
    LicenseInfo.setLicenseKey( MUI )

    const authenticatedRoutes = [
        {
            element: <RequireAuth />,
            children: [
                {
                    element: <RootLayout />,
                    children: [
                        {
                            element: <Home />,
                            index: true,
                            path: RoutePaths.AUTHENTICATED_ROOT,
                        },
                        {
                            element: <PackageDetail />,
                            path: RoutePaths.PACKAGE,
                            
                        },
                        {
                            element: <PackageAccount />,
                            path: RoutePaths.PACKAGE_TRANSACTIONS,
                            
                        },
                        {
                            element: <Profile />,
                            path: RoutePaths.PROFILE,
                            
                        },
                        {
                            element: <Reimbursements />,
                            path: RoutePaths.REIMBURSEMENTS,
                            
                        },
                        {
                            element: <Documents />,
                            path: RoutePaths.DOCUMENTS,
                            
                        },
                        {
                            element: <LeasingFAQs />,
                            path: RoutePaths.FAQS,
                            
                        },
                        {
                            element: <ContactUs />,
                            path: RoutePaths.CONTACT_US,
                            
                        },
                        {
                            element: <Route404 />,
                            path: RoutePaths.ROUTE_404,
                            
                        }
                    ]
                }
            ]
        }
    ]

    const unAuthenticatedRoutes = [
        {
            element: <PreAuthLayout />,
            children: [
                {
                    element: <Login />,
                    path: RoutePaths.ROOT,
                    index: true
                },
                {
                    element: <NewRegistrations />,
                    path: RoutePaths.REGISTER
                },
                {
                    element: <PasswdReset />,
                    path: RoutePaths.PASSWORD_RESET
                },
                {
                    element: <RegistrationsJWT />,
                    path: RoutePaths.JWT_REGISTRATION_LANDING
                },
                {
                    element: <ResetPasswdJWT />,
                    path: RoutePaths.JWT_PASSWD_LANDING
                },
                {
                    element: <Route404 />,
                    path: RoutePaths.ROUTE_404
                }
            ]
        }
    ]

    const router = createBrowserRouter([
        ...unAuthenticatedRoutes,
        ...authenticatedRoutes
    ])

    return (
        <RouterProvider router={ router } />
    )
}