import {
    Drawer,
    Theme,
    CSSObject,
    List,
    ListItem,
    ListItemIcon,
    ListItemButton,
    styled,
    alpha
} from '@mui/material';


// Adjust the width of the NavMenuDrawer here
const rootDrawerWidth = '15vw'
const openMaxWidth = '210px';
const openMinWidth = '160px';
const drawerWidth = `calc( ${ rootDrawerWidth } - 2rem )`
const drawerWidthClosed = '0vw'


export const NavMenuDrawer = styled( Drawer, { shouldForwardProp: ( prop ) => prop !== 'isOpened' })<{ isOpened: boolean }>(({ theme, isOpened }) => ({
        boxSizing: 'border-box',
        flexShrink: 0,
    ...( isOpened && {
        ...openedMixin( theme ),
        [ `& .MuiDrawer-paper` ]: {
            ...openedMixin( theme ),
            height: '100vh',
            whiteSpace: 'nowrap',
            position: 'fixed',
            elevation: 0,
            backgroundColor: theme.palette.mode === 'dark' ? 
                theme.palette.overlays.level1 : theme.palette.primary.main,
            color: theme.palette.mode === 'dark' ? 
                theme.palette.text.primary : theme.palette.common.white
        }
    }),
    ...( !isOpened && {
        ...closedMixin( theme ),
        [ `& .MuiDrawer-paper` ]: {
            ...closedMixin( theme ),
            height: '100vh',
            whiteSpace: 'nowrap',
            position: 'relative',
            backdropFilter: 'blur( 3px )',
        }
    })
}))

const openedMixin = ( theme: Theme ): CSSObject => ({
    width: drawerWidth,
    minWidth: openMinWidth,
    maxWidth: openMaxWidth,
    transition: theme.transitions.create( 'width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
})

const closedMixin = ( theme: Theme ): CSSObject => ({
    width: drawerWidthClosed,
    transition: theme.transitions.create( 'width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
})

export const StyledList = styled( List )(({ theme }) => ({
    paddingTop: 0,
    paddingBottom: 0
}))

export const StyledListItem = styled( ListItem )(({ theme }) => ({
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? 
            alpha( theme.palette.common.skyCyan, 0.1 ) : alpha( theme.palette.common.skyCyan, 0.2 ),
    }
}))

export const StyledListItemButton = styled( ListItemButton )(({ theme }) => ({
    paddingLeft: '1.5rem'
}))

export const StyledListItemIcon = styled( ListItemIcon )(({ theme }) => ({
    minWidth: '2rem'
}))