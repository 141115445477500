import React from "react";
import { Grid, Card, CardContent, CardActions, Typography, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";


export default function Route404() {
    const navigate = useNavigate();

    const backToNotBeingInTheAbyss = () => navigate( - 1 );

	return (
        <Grid container 
            role="alert"
            display='flex' 
            justifyContent='center' 
            alignContent='center'
        >
            <Grid item>
                <Card sx={{ paddingTop: 1 }}>
                    <CardContent>
                        <Typography variant="subtitle2">Whoops :/ you have navigated into the abyss...</Typography>
                        <Typography variant="body2">This URL doesn't exist, please use the go back button the return to the previous page</Typography>
                        
                        <CardActions>
                            <Button variant='contained' color='primary' fullWidth size='small' onClick={ backToNotBeingInTheAbyss }>
                                <Typography variant='button'>Go Back</Typography>
                            </Button>
                        </CardActions> 
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
	);
}