import { InputAdornment, Theme, IconButton, Tooltip } from "@mui/material";
import { VisibilityIcon, VisibilityOffIcon, InputMatchIndicator } from "./Icons";


interface VisibilityProps {
    showMatchIndicator?: boolean;
    isMatch?: boolean;
    theme: Theme;
    isVisible: boolean;
    visibilityClick: () => void;
}

export const PasswdVisibility = ({ showMatchIndicator, isMatch, theme,  isVisible, visibilityClick }: VisibilityProps ) => (
    <InputAdornment position="end">
        <Tooltip 
            arrow
            placement="top"
            title={ isVisible ? 'Hide Password' : 'Show Password' }
        >
            <IconButton
                tabIndex={ -1 }
                aria-label="toggle password visibility"
                onClick={ visibilityClick }
                edge="end"
                sx={{ color: theme.palette.primary.main }}
            >
                { showMatchIndicator && (
                    <InputMatchIndicator
                        usePad={ true }
                        match={ isMatch ? true : false }
                    />
                )}

                { isVisible && (
                    <VisibilityIcon
                        color="inherit"
                        size="small"
                        outlined={ theme.palette.mode === 'dark' } 
                    /> 
                )}

                { !isVisible && (
                    <VisibilityOffIcon
                        color="inherit"
                        size="small" 
                        outlined={ theme.palette.mode === 'dark' } 
                    />  
                )}
            </IconButton>
        </Tooltip>
    </InputAdornment>
);