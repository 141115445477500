import { MUX_DataGrid } from "../../components/UI/DataGrid";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { columnBase } from "../../constants";
import { GridColDef } from "@mui/x-data-grid-premium";
import { ITransactionRecord } from "../../constants/interfaces";
import { formatDateTimeUTC_TZ } from "../../utils/commons";
import { ToolTipWrapper } from "../../components/UI/ToolTipWrapper";
import { cellValueFormating, columnHeaderFormating, currencyFormatter } from "../../components/commonUIF";



interface ITransactionsGridProps {
    transactions: Array< ITransactionRecord >;
    employer: string;
    xsmobile?: number;
    mobile?: number;
    tablet?: number;
    laptop?: number;
    desktop?: number;
}

export function TransactionsGrid({ 
    transactions,
    employer,
    xsmobile = 4,
    mobile = 5,
    tablet = 8,
    laptop = 10,
    desktop = 12
}: ITransactionsGridProps ) {
    const theme = useTheme();
    const isXsMobile = useMediaQuery( theme.breakpoints.only( 'xsmobile' ) );
    const isMobile = useMediaQuery( theme.breakpoints.only( 'mobile' ) );
    const isTablet = useMediaQuery( theme.breakpoints.only( 'tablet' ) );
    const isLaptop = useMediaQuery( theme.breakpoints.only( 'laptop' ) );
    const isDesktop = useMediaQuery( theme.breakpoints.only( 'desktop' ) );
    const isPortrait = Boolean( useMediaQuery( '( orientation: portrait )' ));

    const columns: Array< GridColDef > = [
        {
            field: "EffectiveDate",
            renderHeader: () => columnHeaderFormating( "Expense Date", true ),
            width: isXsMobile || isMobile ? 100 : 120,
            sortable: true,
            valueFormatter: ( value ) => formatDateTimeUTC_TZ( value, true ),
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return <ToolTipWrapper
                            title={ params.value as string }
                            placement="right-start"
                            TransitionType='fade'
                        >
                            { cellValueFormating( params.formattedValue ) }
                        </ToolTipWrapper>
            } 
        },
        {
            field: "PostingDate",
            renderHeader: () => columnHeaderFormating( "Date Submitted" ),
            width: 120,
            sortable: true,
            valueFormatter: ( value ) => formatDateTimeUTC_TZ( value, true ),
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.formattedValue );
            } 
        },
        {
            field: "Supplier",
            renderHeader: () => columnHeaderFormating( "Supplier" ),
            width: 130,
            sortable: false,
            align: 'left',
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                const supIsEmpty = params.value === null;
                const isPayroll = params.row.IsPayrollAllocation === true;
                const isPostTax = params.row.TransactionTypeId === 20;
                const isGstContrib = params.row.ExpenseTypeId === 14;
                const isInterBenefitTransfer = params.row.TransactionTypeId === 41 || params.row.TransactionTypeId === 40;
                const message = isPayroll ? 
                        employer 
                        :
                    isGstContrib ?
                        employer
                        :
                    isPostTax ?
                        "Inside Edge Novated Leasing"
                        :
                    isInterBenefitTransfer ?
                        "Inside Edge Novated Leasing"
                        :
                    supIsEmpty ? 
                        "N/A"
                        :
                        params.value 
                return <ToolTipWrapper
                            title={ message as string }
                            placement="left-start"
                            TransitionType='fade'
                        >
                            { cellValueFormating( message as string ) }
                        </ToolTipWrapper>
            }
        },
        {
            field: "ExpenseType",
            renderHeader: () => columnHeaderFormating( "Expense Type" ),
            width: isMobile || isXsMobile ? 55 : isLaptop ? 80 : 130,
            sortable: true,
            resizable: isXsMobile || isMobile ? false : true,
            align: "left",
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                const isPayroll = params.row.IsPayrollAllocation === true;
                const isInterBenefitTransfer = params.row.TransactionTypeId === 41 || params.row.TransactionTypeId === 40;
                const isPostTax = params.row.TransactionTypeId === 20;
                const isOpenBal = params.row.TransactionTypeId === 90;
                const message = isPayroll ? 
                        "Payroll Credit"
                        :
                    isPostTax ?
                        "Post Tax Credit"
                        :
                    isInterBenefitTransfer ?
                        "Inter-Benefit Transfer"
                        :
                    isOpenBal ?
                        "Opening Balance"
                        : 
                        params.value 
                return <ToolTipWrapper
                            title={ message as string }
                            placement="right-start"
                            TransitionType='fade'
                        >
                            { cellValueFormating( message as string ) }
                        </ToolTipWrapper>
            } 
        },
        {
            field: "AmountIncGst",
            headerAlign: 'center',
            renderHeader: () => columnHeaderFormating( "Amount(Inc)" ),
            width: isXsMobile || isMobile ? 70 : 100,
            sortable: false,
            align: 'right',
            valueFormatter: ( value ) => currencyFormatter.format( value ),
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.formattedValue );
            }
        },
        {
            field: "GSTApplies",
            headerAlign: 'left',
            renderHeader: () => columnHeaderFormating( "GST Applies" ),
            sortable: true,
            align: 'center',
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.value ? 'Yes' : 'No' );
            }
        },
        {
            field: "AmountGST",
            headerAlign: 'center',
            renderHeader: () => columnHeaderFormating( "GST" ),
            width: isXsMobile || isMobile ? 70 : 100,
            sortable: false,
            align: 'right',
            valueFormatter: ( value ) => currencyFormatter.format( value ),
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.formattedValue );
            } 
        },
        {
            field: "LND",
            headerAlign: 'center',
            renderHeader: () => columnHeaderFormating( "LND" ),
            sortable: false,
            align: 'right',
            valueFormatter: ( value ) => currencyFormatter.format( value ),
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.formattedValue );
            }
        },        
        {
            field: "MoneyIn",
            renderHeader: () => columnHeaderFormating( "Money In" ),
            sortable: false,
            align: 'right',
            valueFormatter: ( value ) => currencyFormatter.format( value ),
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.formattedValue );
            } 
        },
        {
            field: "MoneyOut",
            renderHeader: () => columnHeaderFormating( "Money Out" ),
            sortable: false,
            align: 'right',
            valueFormatter: ( value ) => currencyFormatter.format( value ),
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.formattedValue );
            } 
        },
        {
            field: "RollingBalance",
            renderHeader: () => columnHeaderFormating( "Balance" ),
            sortable: false,
            align: 'right',
            valueFormatter: ( value ) => currencyFormatter.format( value ),
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.formattedValue );
            }
        },
        {
            field: "AddedDescription",
            renderHeader: () => columnHeaderFormating( "Description" ),
            headerAlign: 'center',
            flex: 1,
            width: 150,
            sortable: false,
            align: 'left',
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                const descIsEmpty = params.value === null
                const isInterBenefitTransfer = params.row.TransactionTypeId === 41 || params.row.TransactionTypeId === 40;
                const isPostTax = params.row.TransactionTypeId === 20;
                const isOpenBal = params.row.TransactionTypeId === 90;
                const isGstContrib = params.row.ExpenseTypeId === 14;
                const isLeasePayment = params.row.ExpenseTypeId === 13;
                const isManagementFee = params.row.ExpenseTypeId === 5;
                const isPayroll = params.row.IsPayrollAllocation === true;
                let message: string = '';
                switch ( true ) {
                    case isInterBenefitTransfer:
                        message = "Funds transferred from another or previous lease";
                        break;
                    case isPostTax:
                        message = "Credited excess post tax held for previous FBT year";
                        break;
                    case isOpenBal:
                        message = "Opening Balance";
                        break;
                    case isGstContrib:
                        message = `${ employer } - GST on Contribution`;
                        break;
                    case isLeasePayment:
                        message = "Scheduled Lease Payment";
                        break;
                    case isManagementFee:
                        message = "Inside Edge - Management Fee";
                        break;
                    default:
                        message = isPayroll ? 
                            "Payroll Credit"
                            :
                        isPostTax ?
                            "Post Tax Credit"
                            :
                        isInterBenefitTransfer ?
                            "Inter-Benefit Transfer"
                            :
                        isOpenBal ?
                            "Opening Balance"
                            :
                        params.row.ExpenseType ?
                            params.row.ExpenseType
                            :
                            "N/A"
                    ;
                }
                const desc = descIsEmpty ? message : params.value;
                return  <ToolTipWrapper
                            title={ desc as string }
                            placement="left-start"
                            TransitionType='fade'
                        >
                            { cellValueFormating( desc ) }
                        </ToolTipWrapper>
            } 
        }
    ]

    const visibleColumns = responsiveColumns( isPortrait, isXsMobile, isMobile, isTablet, isLaptop, isDesktop, columns );
    console.log( 'visibleColumns: ', visibleColumns );
    return (
        <Grid item
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
        >
            <Box sx={{ maxWidth: '100%' }}>
                <Grid container
                    flexDirection='column'
                    justifyContent='center' 
                    alignItems='flex-start' 
                    columns={ columnBase }
                >
                    <Grid item
                        width="100%"
                        xsmobile={ 4 } mobile={ 6 } tablet={ 8 } laptop={ 10 } desktop={ 12 }
                    >
                        <MUX_DataGrid
                            disableColumnMenus
                            disableColumnSelector
                            columns={ visibleColumns }
                            rowId={ ( row: ITransactionRecord ) => row.TransactionID }
                            rows={ transactions }
                            hideFooter={ false }
                            pagination={ true }
                            includeInitialState={ true }
                            initialState={{
                                pagination: { paginationModel: { pageSize: 20 } }
                            }}
                            pageSizeOptions={ [ 20, 30, 40, 50 ] } 
                            includeGroupingColDef={ false }
                        />
                    </Grid>

                </Grid>
            </Box>
        </Grid>
    )
}

function responsiveColumns( isPortrait: boolean, isXsMobile: boolean, isMobile: boolean, isTablet: boolean, isLaptop: boolean, isDesktop: boolean, columns: Array< GridColDef > ) {
    const xsMobPort = columns.filter( column => [ 'EffectiveDate', 'ExpenseType', 'AmountIncGst', 'AmountGST' ].includes( column.field ) );
    const xsMobLand = columns.filter( column => [ 'EffectiveDate', 'ExpenseType', 'AmountIncGst', 'AmountGST', 'RollingBalance' ].includes( column.field ) ); 
    
    const mobPort = columns.filter( column => [ 'EffectiveDate', 'ExpenseType', 'AmountIncGst', 'AmountGST', 'RollingBalance', 'AddedDescription' ].includes( column.field ) );
    const mobLand = columns.filter( column => [ 'EffectiveDate', 'ExpenseType', 'AmountIncGst', 'AmountGST', 'MoneyIn', 'MoneyOut', 'RollingBalance', 'AddedDescription' ].includes( column.field ) );

    const tabPort = columns.filter( column => [ 'EffectiveDate', 'ExpenseType', 'AmountIncGst', 'AmountGST', 'MoneyIn', 'MoneyOut', 'RollingBalance' ].includes( column.field ) );
    const tabLand = columns.filter( column => [ 'EffectiveDate', 'ExpenseType', 'AmountIncGst','AmountGST', 'MoneyIn', 'MoneyOut', 'RollingBalance' ].includes( column.field ) )
    
    switch ( true ) {
        case isXsMobile:
            return isPortrait ? xsMobPort : xsMobLand
        case isMobile:
            return isPortrait ? mobPort : mobLand
        case isTablet:
            return isPortrait ? tabPort : tabLand
        case isLaptop:
            return columns.filter( column => [ 'EffectiveDate', 'Supplier', 'ExpenseType', 'AmountIncGst', 'GSTApplies', 'AmountGST', 'LND', 'MoneyIn', 'MoneyOut', 'RollingBalance', 'AddedDescription' ].includes( column.field ) )
        case isDesktop:
            return columns
        default:
            return columns
    }
}
// All Cols = [ 'EffectiveDate', 'PostingDate', 'Supplier', 'ExpenseType', 'Amount', 'AmountInclGST', 'GSTApplies', 'AmountGST', 'LND', 'MoneyIn', 'MoneyOut', 'AddedDescription' ]