import { LinearProgressGauge } from "./LinearProgressGauge";
import { Cell } from "../../styled";
import { ReimbOperCostType } from "../../../constants/enums";
import { IBudgetItem } from "../../../constants/interfaces";
import { currencyFormatter } from "../../../components/commonUIF";
import { HelpPopover } from "../HelpPopover";
import { budgetHelpText } from "../../../utils";
import {
    Box,
    Grid,
    useTheme,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    useMediaQuery,
    Typography
} from "@mui/material";

interface GaugeProps {
    data: Array< any >;
    xsmobile: number;
    mobile: number;
    tablet: number;
    laptop: number;
    desktop: number;
}

export const BudgetUsageIndicators = ({ data, xsmobile, mobile, tablet, laptop, desktop }: GaugeProps ) => {
    const theme = useTheme();
    const ismobile = useMediaQuery( theme.breakpoints.down( 'tablet' ));
    const isxsmobile = useMediaQuery( theme.breakpoints.down( 'mobile' ));
    const displayCosts: Array< ReimbOperCostType > = [ 
        ReimbOperCostType.FUEL,
        ReimbOperCostType.REGISTRATION,
        ReimbOperCostType.INSURANCE,
        ReimbOperCostType.MAINTENANCE,
        ReimbOperCostType.CAR_WASH,
        ReimbOperCostType.CTP,
        ReimbOperCostType.OTHER,
        ReimbOperCostType.TYRES,
        ReimbOperCostType.ROADSIDE_ASSISTANCE 
    ]
    
    const displayData = data.filter(( item: IBudgetItem ) => displayCosts.includes( item.CostTypeID ));

    const formattedData = displayData.map(( item: IBudgetItem ) => {
        const used = item.PcentSpent;
        const remaining = 100 - used;
        
        return {
            key: item.CostName,
            label: item.CostName,
            used: used,
            remaining: remaining,
            total: item.TotalBudget,
            proRataTotal: item.BudgetProrata,
            spent: item.TotalSpent
        }
    }).sort(( a, b ) => a.label.localeCompare( b.label ));

    const totalYearlyBudget = formattedData.reduce(( prevItem, currentItem ) => prevItem + currentItem.total, 0 )
    const totalProRataBudget = formattedData.reduce(( prevItem, currentItem ) => prevItem + currentItem.proRataTotal, 0 )
    const totalSpent = formattedData.reduce(( prevItem, currentItem ) => prevItem + currentItem.spent, 0 )
    const totalUsed = ( totalSpent / totalProRataBudget * 100).toFixed( 2 );

    return (
        <Grid  
            sx={{
                paddingLeft: '1rem',
                paddingRight: '0.5rem',
                marginTop: ismobile ? '1rem' : '-5rem'
            }}
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="tableHeader">Expense Category</Typography>
                        </TableCell>
                        {
                            !isxsmobile && (
                                <TableCell>
                                    <Typography variant="tableHeader">Yearly Budget</Typography>
                                </TableCell>
                            )
                        }
                        <TableCell>
                            <Typography variant="tableHeader">Pro-rata Budget</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="tableHeader">Amount Spent</Typography>
                        </TableCell>
                        {
                            !isxsmobile && (
                                <TableCell>
                                    <Typography variant="tableHeader">Budget Usage</Typography>
                                </TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                    {
                        formattedData.map(( item, index ) => (
                            <TableRow key={ `${ item.key}_${ index }` }>
                                <Cell>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="tableValue">{ item.label }</Typography>&nbsp;<HelpPopover content={ budgetHelpText( item.label ) }/>
                                    </Box>
                                </Cell>
                                {
                                    !isxsmobile && (
                                        <Cell>
                                            <Typography variant="tableValue">{ currencyFormatter.format( item.total || 0.00 ) }</Typography>
                                        </Cell>
                                    )
                                }
                                <Cell>
                                    <Typography variant="tableValue">{ currencyFormatter.format( item.proRataTotal || 0.00 ) }</Typography>
                                </Cell>
                                <Cell>
                                    <Typography variant="tableValue">{ currencyFormatter.format( item.spent || 0.00 ) }</Typography>
                                </Cell>
                                {
                                    !isxsmobile && (
                                        <Cell>
                                            <LinearProgressGauge
                                                key={ index }
                                                value={ item.used }
                                            />
                                        </Cell>
                                    )
                                }
                            </TableRow>
                        ))
                    }

                    <TableRow>
                        <Cell>
                            <Typography variant="tableValue">Overall Budget</Typography>
                        </Cell>
                        {
                            !isxsmobile && (
                                <Cell>
                                    <Typography variant="tableValue">{ currencyFormatter.format( totalYearlyBudget || 0.00 ) }</Typography>
                                </Cell>
                            )
                        }
                        <Cell>
                            <Typography variant="tableValue">{ currencyFormatter.format( totalProRataBudget || 0.00 ) }</Typography>
                        </Cell>
                        <Cell>
                            <Typography variant="tableValue">{ currencyFormatter.format( totalSpent || 0.00 ) }</Typography>
                        </Cell>
                        {
                            !isxsmobile && (
                                <Cell>
                                    <LinearProgressGauge
                                        key={ 'totalsrow' }
                                        value={ Number( totalUsed ) || 0.00 }
                                    />
                                </Cell>
                            )
                        }
                    </TableRow>
                </TableBody>
            </Table>
        </Grid>
    )
}


// interface UsageGaugeProps {
//     title: string;
//     valueUsed: number;
//     size: string;
// }

// export const UsageGauge = ({ title, valueUsed, size }: UsageGaugeProps) => (
//     <Box 
//         sx={{ 
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'center',
//             alignItems: 'center',
//             width: '100%', 
//             height: size,
//         }}
//     >
//         <Gauge
//             value={ valueUsed }
//             startAngle={ -110 }
//             endAngle={ 110 }
//             sx={{
//                 [ `& .${ gaugeClasses.valueText }` ]: {
//                     fontSize: '1rem',
//                     transform: 'translate(0px, 0px)'
//                 }
//             }}
//             text={
//                 ({ value }) => `${ value?.toFixed( 0 ) }%`
//             }
//         />
//         <Typography variant="body1">{ title }</Typography>
//     </Box>
// )