import React, { useState } from "react";
import { Grid, Typography, Divider, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { AccordionMapped } from "../../components/styled";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaqsContent } from "../../components/styled";
import { IFAQs } from "../../constants/interfaces";
import { FAQs } from "./FAQs";
import { columnBase } from "../../constants";
import { FAQsHeader } from "./FAQsHeader";

export default function LeasingFAQs() {
    const [ faqs, setFAQs ] = useState< Array< IFAQs >>( FAQs );
    
    const toggleFAQState = ( id: number ) => setFAQs( prevFAQs => prevFAQs.map( faq => ({
        ...faq,
        open: faq.key === id ? !faq.open : false
    })))
    

    return (
        <React.Fragment>

            <FAQsHeader />

            <FaqsContent>
                <Grid container
                    flexDirection='column'
                    justifyContent='center' 
                    alignItems='flex-start' 
                    columns={ columnBase }
                    p={ 1 }
                >
                    { faqs.map(( faq, index ) => (
                        <Grid item
                            key={ faq.key }
                            width={ '100%'}
                            onClick={ ( e ) => toggleFAQState( faq.key ) }
                            sx={{
                                marginTop: faq.open && index !== 0 ? '1rem' : 0,
                                marginBottom: faq.open && index !== faqs.length - 1 ? '1rem' : 0,
                                transition: 'margin 0.5s ease-in-out'
                            }}
                            xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
                        >
                            <AccordionMapped
                                expanded={ faq.open }
                                open={ faq.open }
                                index={ index }
                                blocklength={ faqs.length }
                            >
                                <AccordionSummary
                                    expandIcon={ <ExpandMoreIcon /> }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant='h6'>{ faq.question }</Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails>
                                    <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                </AccordionDetails>
                            </AccordionMapped>
                        </Grid>
                    ))}
                </Grid>
            </FaqsContent>
        </React.Fragment>
    )
}
