import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePaths, ErrorCodes, AppStatusCodes, HttpStatusCodes } from '../../constants/enums';
import { BasicButton } from './Button_Basic';
import { ThumbUpIcon, ReplayIcon } from './Icons';
import { DEContainer, DEContent, DETitle, DEActions, DEContentText } from '../styled';
import { useAppDispatch, useAppSelector } from '../../redux/Store';
import { toggleError } from '../../redux/slicers/AppState';
import { Box, useTheme, Divider, Typography } from '@mui/material';


export function SystemDialog() {
    const { error, errorCode, errorMessage } = useAppSelector( state => state.AppState );
    const showError: boolean = error !== false;
    const isDarkMode: boolean = useTheme().palette.mode === 'dark';
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onRefresh = () => {
        dispatch( toggleError({ error: false, errorMessage: '' }) );
        window.location.reload();
    }

    const onDoNothing = () => {
        dispatch( toggleError({ error: false, errorMessage: '' }) );
    }

    
    const showSingleButton: boolean = singleButtonCodes.includes( errorCode );
    const displayMessage: string = messageSwitch( errorCode, errorMessage );

    return (
        <DEContainer
            open={ showError }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <Typography variant='h6' sx={{ marginBottom: '0.5rem' }}>
                { 
                    errorCode !== AppStatusCodes.APP_LAUNCH_MESSAGE ? 
                        'System Message'
                        :
                        'Welcome to AutoDash - Your new lease management platform' 
                }
            </Typography>

            <Divider variant='fullWidth' />
            <Box sx={{ marginTop: '0.5rem' }}>
                <Typography variant='body1Bold'>
                    { errorCode !== AppStatusCodes.APP_LAUNCH_MESSAGE ? 'Details below:' : 'First time logging in? If so, please see the message below' }
                </Typography>
                <Box 
                    sx={{
                        paddingLeft: '0.5rem',
                        paddingRight: '0.5rem',
                        display: 'flex',
                        flexFlow: 'wrap'
                    }}
                >
                        {
                            errorCode !== AppStatusCodes.APP_LAUNCH_MESSAGE && <Typography variant='body1'>{ displayMessage }</Typography>
                        }
                        {
                            errorCode === AppStatusCodes.APP_LAUNCH_MESSAGE && <Typography variant='body1'>
                                <br />
                                If you had an account with our previous system, then click on the "new to AutoDash?" link.*
                                <br />
                                Then enter the email address we have on file for you to get started.
                                <br />
                                <br />
                                *"new to AutoDash?" is next to "Forgot your password?". So just to the right, over there <Typography variant='body1Bold'>{ '->' }</Typography>
                            </Typography>
                        }
                </Box>
            </Box>
            <DEActions>

                {
                    !showSingleButton && errorCode !== AppStatusCodes.APP_LAUNCH_MESSAGE && (
                        <Box 
                            sx={{ 
                                flexGrow: 1,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start' 
                            }}
                        >
                                <BasicButton 
                                    onClickHandler={ onRefresh } 
                                    size='small'
                                    text='Refresh'
                                    iconPosition='start'
                                    icon={ 
                                        <ReplayIcon 
                                            outlined={ isDarkMode ? true : false }
                                            color={ isDarkMode ? 'primary' : 'info' }
                                            size='small' 
                                        />
                                    }
                                />
                        </Box>
                    )
                }
                
                <Box 
                    sx={{ 
                        flexGrow: 1,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                >
                    <BasicButton 
                        onClickHandler={ onDoNothing }
                        size='small' 
                        text='Okay'
                        iconPosition='end'
                        icon={ 
                            <ThumbUpIcon 
                                outlined={ isDarkMode ? true : false } 
                                color={ isDarkMode ? 'primary' : 'info' } 
                                size='small' 
                            /> 
                        }
                    />
                </Box>

            </DEActions>
        </DEContainer>

    );
}

const singleButtonCodes: Array< AppStatusCodes | ErrorCodes > = [ 
    AppStatusCodes.USER_LOCKED_OUT, 
    AppStatusCodes.NO_RECORDS_FOUND, 
    AppStatusCodes.OB_DATA_VAL_FAILED, 
    AppStatusCodes.VALIDATION_FAILED 
];

function messageSwitch( code: number, serverMessage: string ): string {
    switch ( code ) {
        case AppStatusCodes.APP_LAUNCH_MESSAGE:
            return `If you had an account with our previous system, then click on the "are you new?" link,
                and then enter the email address we have on file for you to get started.
            `;
        case AppStatusCodes.NO_CURRENT_USER_SESSION_REAUTH:
            return `I'm sorry, but your session has expired. You'll need to login again to continue.`;
        case AppStatusCodes.VALIDATION_FAILED:
            return serverMessage ? serverMessage : `I found some issues with the data you entered, please check the fields and try again.`;
        case AppStatusCodes.DUPLICATE_REJECTED:
            return serverMessage ? serverMessage : `I already have a record of this data, please try again with different information.`;
        case AppStatusCodes.NEWPASS_MISMATCH:
            return `The new password and the confirmation password do not match, please try again.`;
        case AppStatusCodes.PASSWORD_COMPLEXITY_FAILURE:
            return `The password you entered does not meet the complexity requirements, of upper & lowercase letters, atleast a single number, a special character, and between 10 & 20 characters long. please try again.`;
        case ErrorCodes.PASSWORD_UPDATE_FAILED:
            return `I couldn't update your password at this time, please try again later.`;
        case AppStatusCodes.USER_LOCKED_OUT:
            return `Your account has been locked out. Continued attempts to login will result in a longer lockout period.`;
        case AppStatusCodes.NO_RECORDS_FOUND:
            return `Um this is awkward, but I couldn't find any records matching your request. If you are sure what you are looking for exists, please contact support.`;
        case AppStatusCodes.OB_DATA_VAL_FAILED:
            return `I tried to validate the data I found, but it seems to be incorrect. Please try again, if the issue persists, please contact support.`;
        case ErrorCodes.DATABASE_PROCEDURE_FAILED:
            return `I'm sorry, but I couldn't complete the operation you requested. Please try again later.`;
        case AppStatusCodes.VT_SCAN_HIGH_TIMEOUTS || AppStatusCodes.VT_SCAN_FAILED:
            case AppStatusCodes.VT_FOUND_MALICIOUS || AppStatusCodes.VT_FOUND_SUSPICIOUS || AppStatusCodes.VT_FOUND_UNDETECTED:
            return `I found something that doesn't look right with the attached files, please try again later.`;
        case AppStatusCodes.PASSWORD_REUSE_DETECTED:
            return `I'm sorry, but you can't reuse a password you have used previously. Please try a different password.`;
        case AppStatusCodes.MFA_INVALID_CODE || AppStatusCodes.VID_INVALID_CODE:
            return `The code you entered is incorrect, please try again.`;
        case AppStatusCodes.MFA_VERIFICATION_FAILED:
            return `I couldn't verify the code you entered, please try again later.`;
        case AppStatusCodes.VID_EXPIRED:
            return `The verification code has expired, please start the process from the beginning. Verification codes are only valid for 5 minutes.`;
        case ErrorCodes.SYS_EMAIL_FAILED:
            return `I tried to send an email, but it didn't work. Please try again later.`;
        case ErrorCodes.MISSING_FUNCTION_PARAMS | ErrorCodes.INVALID_FUNCTION_PARAMS:
            return `My server didn't like the data we sent please check the inputs and try again. If the issue persists, please contact support.`;
        case ErrorCodes.NETWORK_ERROR:
            return `A network error occurred, basically I have lost my connection with the AutoDash server. If the issue persists, try refreshing the page or try again later.`;
        case ErrorCodes.HTTP_STATUS:
            return `My server is being a little uncooperative right now, please try again later.`;
        case ErrorCodes.UNKOWN_FUNCTION_ERROR:
            return `Oh boy, this is all kinds of wrong. I'm not sure what happened, so I'm notifying my creators about the problem. Please try again later.`;
        case HttpStatusCodes.NOT_FOUND:
            return `I'm sorry, but I don't seem to have any resources matching that description. Please try again, if the issue persists, please contact support.`;
        default:
            return serverMessage;
    }
}