import { UploadProgress } from "./UploadProgress";
import { Typography, Box, IconButton, useTheme } from "@mui/material";
import { DeleteIcon, DotIcon, UploadIcon } from "../Icons";
import { UploadCard } from "../../styled";


export interface UploadCardProps {
    loading: boolean;
    fileName: string;
    size: string;
    error: boolean;
    onDelete: () => void;
}

export const FileUploadCard = ({ loading, fileName, size, error, onDelete, ...props }: UploadCardProps ) => {
    const isDark = useTheme().palette.mode === 'dark';
    const colors = useTheme().palette;

    const colorHandler = ( error: boolean, primary: boolean ) => {
        if ( error && primary || error && !primary) {
            return colors.error.main;
        }
        if ( !error && primary ) {
            return colors.text.primary;
        }
        return colors.text.secondary;
    }

    const statusHandler = ( loading: boolean, error: boolean ) => {
        if ( loading && !error ) {
            return 'Loading...';
        }
        if ( !loading && error || loading && error ) {
            return 'Failed';
        }
        return 'Complete';
    }
    
    return (
        <UploadCard>

            <UploadIcon
                size='large'
                outlined color='inherit'
                style={{ color: colorHandler( error, false ) }}
            />

            <Box
                sx={{ 
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    marginLeft: '2rem',
                    width: '70%', 
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%'
                }}
            >
                <Typography variant='h6' 
                    sx={{ 
                        marginBottom: '0.2rem',
                        color: colorHandler( error, true ),
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%'
                    }}
                >
                    { !error ? fileName : 'Upload Failed' }
                </Typography>
                <Typography variant='dataValue'
                    sx={{ 
                        color: colorHandler( error, false )
                    }}
                > 
                    { size ? size : '0kb' }
                    <DotIcon 
                        size='small'
                        color='inherit'
                        outlined={ false }
                        style={{ 
                            height: '0.3rem',
                            color: colorHandler( error, false )
                        }} 
                    />
                    { statusHandler( loading, error ) }
                </Typography>
            </Box>

            <Box
                sx={{ 
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '30%'
                }}
            >

                <UploadProgress loading={ loading } error={ error } />

                <IconButton
                    onClick={ onDelete } 
                    sx={{
                        marginLeft: '1.5rem',
                        color: colors.text.secondary
                    }}
                >
                    <DeleteIcon
                        size='medium'
                        outlined={ isDark }
                        color='inherit'
                    />
                </IconButton>

            </Box>

        </UploadCard>
    )
}