import React, { type PropsWithChildren } from 'react';
import { StyledMain, StyledGrid } from '../styled';
import { columnBase } from '../../constants';


interface MainGridProps extends PropsWithChildren< unknown >{}

export const MainGrid = ({ children }: MainGridProps ) => {

    return (
        <StyledMain
            className='Main'
        >
            <StyledGrid className='contentMain' container columns={ columnBase }>
                { children }
            </StyledGrid>
        </StyledMain>
    )
}