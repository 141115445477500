import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/Store';
import { updatePreferredName } from '../../redux/AccessThunks';
import { addAlert } from '../../redux/slicers/AppState';
import { Grid, useTheme, Typography } from '@mui/material';
import { DataGroup, DataFieldConditional, DataField, StandardTextInput } from '../../components/UI';
import { columnBase } from '../../constants';
import { ProfileHeader } from './ProfileHeader';
import { profileData } from './profileData';
import { ProfileContent } from '../../components/styled';
import { PhoneDetails } from './PhoneComponents/PhoneDetails';
import { EmailDetails } from './EmailComponents/EmailDetails';
import { AddressDetails } from './AddressComponents/AddressDetails';
import { UserPreferences } from './Preferences';


export default function Profile() {
    const { userDetails } = useAppSelector( state => state.Access )
    const [ editName, setEditName ] = useState< 'view' | 'edit' >( 'view' )
    const [ editingMultiple, setEditingMultiple ] = useState< 'phone' | 'email' | 'address' | 'none' >( 'none' )
    const dispatch = useAppDispatch()
    const theme = useTheme()

    // Editable Field Values \\
    const [ preferredName, setPreferredName ] = useState< string >( userDetails?.PreferredName ? userDetails.PreferredName : '' )

    // Field Data \\
    const { personDetails, employmentDetails } = profileData( userDetails )

    const handleNameChanges = async ( currentValue: string, newValue: string ) => {
        if ( newValue.length > 2 && currentValue !== newValue ) {
            const response = await dispatch( updatePreferredName({ ContactID: userDetails.ContactID, PrefName: preferredName }) ).unwrap()
            const { success, code } = response
            if ( success ) {
                dispatch( addAlert({ id: 123, message: 'Preferred name successfully edited', type: 'success' }) )
            }
            setEditName( 'view' )
        }
    }

    const cancelNameChange = () => { 
        setPreferredName( userDetails?.PreferredName ? userDetails.PreferredName : '' ), 
        setEditName( 'view' ) 
    }

    return (
        <React.Fragment>

            <ProfileHeader 
                Title={ userDetails ? `${ userDetails?.FirstName } ${ userDetails?.LastName }` : '' } 
                Employer={ userDetails?.CompanyName ? userDetails.CompanyName : '' }
                Role={ userDetails?.RoleTitle ? userDetails.RoleTitle : '' }
            />

            <ProfileContent className='ProfileContent'>
                <Grid container 
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    //alignItems='flex-start'
                    columns={ columnBase } 
                    spacing={ 1 }
                    sx={{ flexFlow: 'wrap' }}
                >

                    <DataGroup 
                        title='Personal Details'
                        className='PersonalDetails'
                        isEditable
                        editing={ editName === 'edit' }
                        onEditClick={ () => setEditName( editName === 'view' ? 'edit' : 'view' ) }
                        onCancelClick={ () => cancelNameChange() }
                        onSaveClick={ () => handleNameChanges( userDetails.PreferredName, preferredName ) }
                        xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
                    >
                        
                        <DataFieldConditional
                            condition={ editName === 'view' }
                            data={ personDetails.preferredName }
                            xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 3 }
                        >
                            <form
                                style={{ marginTop: '0.75rem' }}
                            >
                                <StandardTextInput 
                                    variant='standard'
                                    size='small'
                                    label='Preferred Name'
                                    placeholder='Min 3 characters or more'
                                    value={ preferredName }
                                    type='text'
                                    onChange={ ( value ) => {
                                        const cleanVal = value.replace( /[^a-zA-Z.\-'"`\s]/g, '' );
                                        setPreferredName( cleanVal ) 
                                    }}
                                />
                            </form>
                        </DataFieldConditional>

                        <DataField
                            className={ personDetails.fullName.key }
                            data={ personDetails.fullName }
                            xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 3 }
                        />

                        <DataField
                            data={ personDetails.firstName }
                            xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 3 }
                        />

                        <DataField
                            data={ personDetails.lastname }
                            xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 3 }
                        />

                        {/* Phone Numbers  */}
                        <PhoneDetails 
                            userDetails={ userDetails }
                            currentEdit={ editingMultiple }
                            onShowAdd={ () => setEditingMultiple( 'phone' ) }
                            xsmobile={ 4 } mobile={ 5 } tablet={ 8 } laptop={ 10 } desktop={ 12 }
                        />
                        
                        {/* Email Addresses */}
                        <EmailDetails 
                            userDetails={ userDetails }
                            currentEdit={ editingMultiple }
                            onShowAdd={ () => setEditingMultiple( 'email' ) }
                            xsmobile={ 4 } mobile={ 5 } tablet={ 8 } laptop={ 10 } desktop={ 12 }
                        />

                        <AddressDetails
                            userDetails={ userDetails }
                            currentEdit={ editingMultiple }
                            onShowAdd={ () => setEditingMultiple( 'address' ) }
                            xsmobile={ 4 } mobile={ 5 } tablet={ 8 } laptop={ 10 } desktop={ 12 }
                        />

                    </DataGroup>


                    <DataGroup
                        className='EmploymentDetails'
                        title='Employment Information'
                        xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
                    >
                        { employmentDetails ? (
                            employmentDetails.map(( employment ) => (
                                <DataField
                                    key={ employment.key }
                                    data={ employment }
                                    xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 12 }
                                />
                            ))
                        ) : (
                            <Typography variant='body2'>No Employment Details</Typography>
                        )}

                        {/* <UserPreferences
                            existingPreferences={ userDetails?.Preferences }
                            emailAddresses={ userDetails?.EmailAddresses }
                            phoneNumbers={ userDetails?.PhoneNumbers }
                            xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 12 }
                        /> */}
                    </DataGroup>
                    


                </Grid>
            </ProfileContent>
        </React.Fragment>
    )
}