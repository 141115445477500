import React, { useState, useEffect } from 'react';
import { IconButton, Grid, Box, useTheme, Collapse, Typography, useMediaQuery } from '@mui/material';
import { IUserDetails, IUserEmail } from '../../../constants/interfaces';
import { EmailType, AppStatusCodes } from '../../../constants/enums';
import { AnimatedGridItem } from '../../../components/styled';
import { DataGroup, DataFieldConditional, DataField, BasicDropDownMenu, StandardTextInput } from '../../../components/UI';
import { emailTypeIDConverter } from '../../../components/commonUIF';
import { DeleteIcon } from '../../../components/UI/Icons';
import { addNewEmail } from '../../../redux/AccessThunks';
import { addAlert } from '../../../redux/slicers/AppState';
import { useAppDispatch } from '../../../redux/Store';
import { DeleteEmailDialog } from './DeleteEmailDialog';
import { columnBase } from '../../../constants';
import { validateEmail } from '../../../utils/commons';



interface EmailComponentProps {
    userDetails: IUserDetails;
    currentEdit: 'phone' | 'email' | 'address' | 'none';
    onShowAdd: () => void;
    xsmobile: number;
    mobile: number;
    tablet: number;
    laptop: number;
    desktop: number;
}

export function EmailDetails( { userDetails, xsmobile, mobile, tablet, laptop, desktop, ...props }: EmailComponentProps ) {
    const { currentEdit, onShowAdd } = props;
    const [ editEmail, setEditEmail ] = useState< 'view' | 'edit' | 'add' >( 'view' )
    const [ showAddEmail, setShowAddEmail ] = useState( false )
    const [ showDeleteConfirm, setShowDeleteConfirm ] = useState( false )
    const [ confirmDelete, setConfirmDelete ] = useState< IUserEmail | null >( null )
    const [ emailErrors, setEmailErrors ] = useState<{ select: boolean, textfield: boolean }>({ select: false, textfield: false })
    const [ helpText, setHelpText ] = useState< string >( '' )
    const [ emailArray, setEmailArray ] = useState(
        userDetails?.EmailAddresses 
        ? userDetails.EmailAddresses.map(( email ) => ({
            key: `${ email.EmailID }`,
            label: `${ emailTypeIDConverter( email.EmailTypeID ) }` ,
            value: email.EmailAddress,
            isEdit: true,
            isPrimary: email.IsPrimary,
            isSendAllIncluded: email.IsSendAllIncluded
        })) : []
    )
    const [ newEmail, setNewEmail ] = useState< IUserEmail >({
        ContactID: userDetails.ContactID,
        EmailID: 0,
        EmailTypeID: EmailType.PERSONAL,
        EmailType: '',
        EmailAddress: '',
        IsPrimary: false,
        IsSendAllIncluded: false
    })
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const isDarkMode = theme.palette.mode === 'dark'
    const isMobile = useMediaQuery( theme.breakpoints.down( 'tablet' ))

    useEffect(() => {
        setEmailArray(
            userDetails?.EmailAddresses 
            ? userDetails.EmailAddresses.map(( email ) => ({
                key: `${ email.EmailID }`,
                label: emailTypeIDConverter( email.EmailTypeID ),
                value: email.EmailAddress,
                isEdit: true,
                isPrimary: email.IsPrimary,
                isSendAllIncluded: email.IsSendAllIncluded
            })) : []
        )
    }, [ userDetails.EmailAddresses ])

    useEffect(() => {
        if ( userDetails.ContactID !== 0 ) setNewEmail({ ...newEmail, ContactID: userDetails.ContactID }) 
    }, [ userDetails.ContactID ])

    useEffect(() => {
        
        if ( currentEdit !== 'email' ) onCancelChanges()
        
    }, [ currentEdit ])

    const onAddEmail = async () => {
        setEmailErrors({ select: false, textfield: false })
        setHelpText( '' )

        // Validation Logic Required Like Crazy.... Help dont have time! \\
        if ( newEmail.EmailType === '' ) {
            setEmailErrors({ select: true, textfield: false })
            setHelpText( 'Email Type is required' )
            return
        }
        if ( newEmail.EmailAddress === '' ) {
            setEmailErrors({ select: false, textfield: true })
            setHelpText( 'Cannot add an email if one is not provided?')
            return
        }
        if ( newEmail.EmailAddress.length < 3 ) {
            setEmailErrors({ select: false, textfield: true })
            setHelpText( 'To short to be a valid email' )
            return
        }
        if ( newEmail.EmailAddress.length > 250 ) {
            setEmailErrors({ select: false, textfield: true })
            setHelpText( 'To long to be a valid email' )
            return
        }
        if ( !validateEmail( newEmail.EmailAddress )) {
            setEmailErrors({ select: false, textfield: true })
            setHelpText( 'Please enter a valid email' )
            return
        }

        const add = await dispatch( addNewEmail( newEmail )).unwrap()
        const { success, code } = add
        if ( success && code === AppStatusCodes.SUCCESS ) {
            dispatch( addAlert({ id: Date.now(), message: `Email added successfully`, type: 'success' }))
            setShowAddEmail( false )
            setEditEmail( 'view' )
            setNewEmail({
                ContactID: userDetails.ContactID,
                EmailID: 0,
                EmailTypeID: EmailType.PERSONAL,
                EmailType: '',
                EmailAddress: '',
                IsPrimary: false,
                IsSendAllIncluded: false
            })
            return 
        }
        if ( success && code === AppStatusCodes.VID_VERIFICATION_REQUIRED ) {
            setShowAddEmail( false )
            setEditEmail( 'view' )
            setNewEmail({
                ContactID: userDetails.ContactID,
                EmailID: 0,
                EmailTypeID: EmailType.PERSONAL,
                EmailType: '',
                EmailAddress: '',
                IsPrimary: false,
                IsSendAllIncluded: false
            })
            dispatch( addAlert({ id: Date.now(), message: `Please enter the 6 digit code`, type: 'info' }))
            return
        }
        setShowAddEmail( false )
        setEditEmail( 'view' )
        setNewEmail({
            ContactID: userDetails.ContactID,
            EmailID: 0,
            EmailTypeID: EmailType.PERSONAL,
            EmailType: '',
            EmailAddress: '',
            IsPrimary: false,
            IsSendAllIncluded: false
        })
        dispatch( addAlert({ id: Date.now(), message: `Add Email failed`, type: 'error' }))
    }

    const onCancelChanges = () => {
        setShowAddEmail( false )
        setEmailErrors({ select: false, textfield: false })
        setHelpText( '' )
        setNewEmail({
            ContactID: userDetails.ContactID,
            EmailID: 0,
            EmailTypeID: EmailType.PERSONAL,
            EmailType: '',
            EmailAddress: '',
            IsPrimary: false,
            IsSendAllIncluded: false
        })
        setEmailArray(
            userDetails?.EmailAddresses 
            ? userDetails.EmailAddresses.map(( email ) => ({
                key: `${ email.EmailID }`,
                label: `${ emailTypeIDConverter( email.EmailTypeID ) }`,
                value: email.EmailAddress,
                isEdit: true,
                isPrimary: email.IsPrimary,
                isSendAllIncluded: email.IsSendAllIncluded
            })) : []
        )
        setEditEmail( 'view' )
    }

    const handleClickDeleteIcon = ( emailID: string ) => {
        let email = userDetails.EmailAddresses.find(( e ) => e.EmailID === Number( emailID ))
        console.log( email )
        if ( email ) {
            setConfirmDelete( email )
            setShowDeleteConfirm( true )
        }
    }

    const addEmialClick = () => {
        onShowAdd()
        setShowAddEmail( true )
        setEditEmail( 'add' )
    }

    return (
        <DataGroup 
            title='Email Addresses'
            subGroup
            isEditable
            hideBackground
            editing={ editEmail !== 'view' }
            onEditClick={ () => setEditEmail( editEmail === 'view' ? 'edit' : 'view' )}
            onAddClick={ addEmialClick }
            onSaveClick={ onAddEmail}
            onCancelClick={ () => onCancelChanges() }
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
        >
            {/* Below Grid Item opens downward when "showAddPhone" == true */}
            <Collapse in={ showAddEmail } unmountOnExit
                sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
            >
                <AnimatedGridItem container
                    show={ showAddEmail === true ? 'true' : 'false' }
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        width: '100%',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.4rem'
                    }}
                >
       
                    <Box
                        sx={{
                            width: isMobile ? '100%' : '30%',
                            paddingRight: isMobile ? '0rem' : '0.5rem',
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem'
                        }}
                    >
                        <BasicDropDownMenu
                            menuLabel='Email Type'
                            selectID='EmailType'
                            size='small'
                            options={[
                                { key: 1, label: 'Personal', value: EmailType.PERSONAL },
                                { key: 2, label: 'Secondary', value: EmailType.SECONDARY },
                                { key: 3, label: 'Work', value: EmailType.BUSINESS },
                                { key: 4, label: 'Other', value: EmailType.OTHER }
                            ]}
                            onChange={ ( v ) => setNewEmail({ ...newEmail, EmailTypeID: v, EmailType: emailTypeIDConverter( v )  }) }
                            error={ emailErrors.select === true }
                        />
                    </Box>

                    <Box
                        sx={{
                            width: isMobile ? '100%' : '70%',
                            paddingLeft: isMobile ? '0rem' : '0.5rem',
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem'
                        }}
                    >
                        <StandardTextInput
                            variant='standard'
                            size='small'
                            label='New Email Address'
                            value={ newEmail.EmailAddress }
                            type='text'
                            disabled={ !showAddEmail }
                            onChange={ ( value ) => {
                                let cleanVal = value
                                setNewEmail({ ...newEmail, EmailAddress: cleanVal })
                            }}
                            error={ emailErrors.textfield === true }
                        />
                    </Box>
                    <Typography variant='caption' sx={{ color: theme.palette.error.main }}>
                        { emailErrors && helpText }
                    </Typography>
                </AnimatedGridItem>
            </Collapse>
           
            { emailArray.length > 0 ? (
                emailArray.map(( email ) => (
                    <DataFieldConditional
                        key={ email.key }
                        condition={ editEmail === 'view' }
                        data={ email }
                        desktop={ emailArray.length > 3 ? 3 : emailArray.length > 2 ? 4 : emailArray.length < 2 ? 12 : 6 }
                        xsmobile={ 4 } mobile={ 5 } tablet={ 8 } laptop={ emailArray.length > 1 ? 5 : 10 }
                    >
                        <Box
                            display='flex'
                            flexDirection='row'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Box width={ `90%` }>
                                <DataField data={ email } />
                            </Box>

                            <Box 
                                flexGrow={1}
                                display='flex'
                                flexDirection='row'
                                justifyContent='center'
                            >
                                <IconButton
                                    size='medium'
                                    onClick={ () => handleClickDeleteIcon( email.key ) }
                                    sx={{
                                        color: theme.palette.dataGroupIconButtons.deleteIcon,
                                    }}
                                >
                                    <DeleteIcon outlined={ isDarkMode } color='inherit' size='small' />
                                </IconButton>
                            </Box>
                        </Box>
                    </DataFieldConditional>
                ))
            ) : (
                <Grid item container spacing={ 1 } columns={ columnBase }>
                    <DataField
                        data={{
                            key: 'noEmailDetails',
                            label: 'Email Address',
                            value: '( No Email Details Available )',
                            isEdit: false
                        }}
                        xsmobile={ 4 } mobile={ 3 } tablet={ 4 } laptop={ 5 } desktop={ 4 }
                    />
                </Grid>
            )}

            <DeleteEmailDialog
                show={ showDeleteConfirm }
                close={ () => { setShowDeleteConfirm( false ), setConfirmDelete( null ), setEditEmail( 'view' ) } }
                contactID={ userDetails.ContactID }
                EmailID={ confirmDelete?.EmailID || 0 }
                EmailTypeID={ confirmDelete?.EmailTypeID || 0 }
                EmailAddress={ confirmDelete?.EmailAddress || '' }
                isOnlyEmail={ userDetails.EmailAddresses.length === 1 }
                dispatch={ dispatch }
            />

        </DataGroup>
    )
}