import React, { useState, useRef } from "react";
import { Dialog_DetailEdit } from "../../components/UI";
import { Typography, Grid, Box } from '@mui/material';
import { StandardTextInput } from "../../components/UI";
import { IAddBankAccount } from "../../constants/interfaces";

interface ViewAttachmentsDialogProps {
    show: boolean;
    accountValues: IAddBankAccount;
    setAccountValues: ( values: IAddBankAccount ) => void;
    onAddAccount: () => void;
    close: () => void;
}

export const AddAccountDialog = ({ show, accountValues, setAccountValues, onAddAccount, close }: ViewAttachmentsDialogProps ) => {
    const accNum = useRef< HTMLInputElement >( null );
    return (
        <Dialog_DetailEdit
            show={ show }
            title='Add Bank Account'
            slideDirection='up'
            confirmLabel='Add'
            cancelLabel='Cancel'
            onConfirm={ onAddAccount }
            onCancel={ close }
        >
            <Grid container 
                spacing={ 1 }
                columns={ 1 }
                display={ 'flex' }
                flexDirection={ 'column' }
                justifyContent='center'
                alignItems='center'
            >

                <Grid item 
                    xsmobile={ 1 } mobile={ 1 } tablet={ 1 } laptop={ 1 } desktop={ 1 }
                >
                    <RenderBsbInputs 
                        accountValues={ accountValues }
                        setAccountValues={ setAccountValues }
                        onNextInput={ () => accNum.current?.focus() }
                    />
                </Grid>
                
                <Grid item 
                    xsmobile={ 1 } mobile={ 1 } tablet={ 1 } laptop={ 1 } desktop={ 1 }
                >
                    <StandardTextInput
                        label='Account Number'
                        placeholder='6 - 10 digits'
                        type="text"
                        variant="filled"
                        size='small'
                        required
                        value={ accountValues.AccNumber }
                        onChange={ ( v ) => {
                            const numericValue = v.replace(/[^0-9.]/g, '');
                            setAccountValues({ ...accountValues, AccNumber: numericValue }); 
                        }}
                        inputProps={{ maxLength: 10, minLength: 6 }}
                        inputRef={ accNum }
                    />
                </Grid>

                <Grid item 
                    xsmobile={ 1 } mobile={ 1 } tablet={ 1 } laptop={ 1 } desktop={ 1 }
                >
                    <StandardTextInput
                        label='Account Name'
                        placeholder='Account Holder Name'
                        type="text"
                        variant="filled"
                        size='small'
                        required
                        value={ accountValues.AccFormalName }
                        onChange={ ( v ) => setAccountValues({ ...accountValues, AccFormalName: v }) }
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>

                <Grid item
                    xsmobile={ 1 } mobile={ 1 } tablet={ 1 } laptop={ 1 } desktop={ 1 }
                >
                    <StandardTextInput
                        label='Account Nickname'
                        placeholder='Nickname for this account?'
                        type="text"
                        variant="filled"
                        size='small'
                        value={ accountValues.AccNickname }
                        onChange={ ( v ) => setAccountValues({ ...accountValues, AccNickname: v }) }
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>

            </Grid>
        </Dialog_DetailEdit>
    )
}

interface BsbProps {
    accountValues: IAddBankAccount;
    setAccountValues: ( values: IAddBankAccount ) => void;
    onNextInput: () => void;
}
// Split into seperate component for memoization as the fields were loosing focus as they were typed
const RenderBsbInputs = React.memo(({ accountValues, setAccountValues, onNextInput, ...props }: BsbProps ) => {
    const bsb_1 = useRef< HTMLInputElement >( null );
    const bsb_2 = useRef< HTMLInputElement >( null );
    
    const handleFirstInputChange = ( v: string ) => {
        const numericValue = v.replace(/[^0-9.]/g, '');
        setAccountValues({ ...accountValues, BSB_P1: numericValue });
        if ( v.length === 3 ) {
            bsb_2.current?.focus();
        }
    }

    const handleSecondInputChange = ( v: string ) => {
        const numericValue = v.replace(/[^0-9.]/g, '');
        setAccountValues({ ...accountValues, BSB_P2: numericValue });
        if ( v.length === 3 ) {
            bsb_2.current?.blur();
            onNextInput();
        }
    }

    return (
        <Box
            display='flex'
            flexDirection='row'
            justifyContent='center'
            alignItems='center'
            maxWidth={ 150 }
        >
            <StandardTextInput
                label='BSB'
                type="text"
                variant="filled"
                placeholder='First 3 No.'
                size='small'
                required
                value={ accountValues.BSB_P1 }
                onChange={ ( v ) => handleFirstInputChange( v ) }
                inputProps={{ maxLength: 3, minLength: 3 }}
                inputRef={ bsb_1 }
            />
            <Typography variant="h4"> <b>-</b> </Typography>
            <StandardTextInput
                label='BSB'
                placeholder='Last 3 No.'
                type="text"
                variant="filled"
                size='small'
                required
                value={ accountValues.BSB_P2 }
                onChange={ ( v ) => handleSecondInputChange( v ) }
                inputProps={{ maxLength: 3, minLength: 3 }}
                inputRef={ bsb_2 }
            />
        </Box>
    )
})