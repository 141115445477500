import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Box, useTheme } from '@mui/material';
import { ResponseDisplayContainer } from '../styled';
import { useAppSelector } from '../../redux/Store';


export function LoadingIndicator() {
    const { loading } = useAppSelector(( state ) => state.AppState );
    const [ showIndicator, setShowIndicator ] = useState< boolean >( false );
    const indicatorTimeout = 500; // Minimum duration to show the indicator

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if ( loading ) {
            setShowIndicator( true );
        } else {
            timer = setTimeout( () => {
                if ( !loading ) {
                    setShowIndicator( false );
                }
            }, indicatorTimeout );
        }

        return () => clearTimeout( timer );

    }, [ loading ])

    return (
        <Backdrop 
            open={ showIndicator }
            sx={{ 
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1050,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export function IndeterminateLoading({ show }: { show: boolean }) {
    const colors = useTheme().palette;
    return (
        <ResponseDisplayContainer
            slideon='true'
            view={ show ? 'true' : 'false' }
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '80%',
                    marginTop: '5rem',
                    padding: '0.5rem'
                }}
            >
                <CircularProgress sx={{ color: colors.common.skyCyan }} />
            </Box>
        </ResponseDisplayContainer>
    );
}