import { Typography, Box, Theme, Tooltip } from "@mui/material";
import { RegistrationsFormContainer } from "../../components/styled";
import { StandardTextInput } from "../../components/UI";
import { InputMatchIndicator } from "../../components/UI/Icons";
import { RoundedButton, TextButton } from "../../components/styled";

interface RegistrationFormProps {
    currentView: 'details' | 'mfa' | 'loading' | 'noMobile' | 'complete';
    email: string;
    confirmEmail: string;
    setEmail: ( v: string ) => void;
    emailError: boolean;
    setConfirmEmail: ( v: string ) => void;
    confirmEmailError: boolean;
    onRegister: () => void;
    onCancel: () => void;
    onKeyDown: ( e: React.KeyboardEvent ) => void;
    helpText: string;
    theme: Theme;
}

export const UserRegistrationForm = ({
    currentView,
    email,
    confirmEmail,
    setEmail,
    emailError,
    setConfirmEmail,
    confirmEmailError,
    onRegister,
    onKeyDown,
    onCancel,
    helpText,
    theme
}: RegistrationFormProps ) => (
    <RegistrationsFormContainer view={ currentView }>
        <form onKeyDown={ onKeyDown }>
            <Box
                sx={{
                    alignItems: 'flex-start',
                    marginLeft: '3rem',
                    marginRight: '3rem',
                    paddingTop: '3rem',
                }}
            >
                <Box>
                    <Typography variant="h6">
                        Confirm your details
                    </Typography>
                    <br />
                    <Typography variant="body1">
                        Please enter the email address linked with the account you are registering.
                    </Typography>
                    <br />
                </Box>
                <Box className="desktop-em-in">
                    <StandardTextInput
                        variant='standard'
                        label='Email Address'
                        placeholder='Email used on lease'
                        required
                        inputProps={{
                            'aria-label': 'Email Address'
                        }}
                        endAdmornment={
                            email.length > 4 && confirmEmail.length > 4  && (
                                <InputMatchIndicator
                                    match={ email === confirmEmail }
                                />
                            )
                        }
                        size='medium'
                        error={ emailError }
                        value={ email }
                        onChange={ ( v ) => setEmail( v )}
                        autoComplete="new-password"
                    />
                </Box>
                
                <Box className="desktop-pd-in" paddingTop='2rem'>
                    <StandardTextInput
                        variant='standard'
                        label='Confirmation Email'
                        placeholder='Re-enter email address'
                        helpText={ helpText }
                        required
                        inputProps={{
                            'aria-label': 'Email Address (Confirmation)'
                        }}
                        endAdmornment={
                            email.length > 4 && confirmEmail.length > 4  && (
                                <InputMatchIndicator
                                    match={ email.length > 4 && confirmEmail.length > 4 && confirmEmail === email }
                                />
                            ) 
                        }
                        size='medium'
                        type={ 'text' }
                        error={ confirmEmailError }
                        value={ confirmEmail }
                        onChange={ ( v ) => setConfirmEmail( v )}
                        autoComplete="new-password"
                    />
                </Box>
                
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: '3rem',
                    }}
                >
                    <RoundedButton onClick={ onCancel }>
                        <Typography variant="button">Cancel</Typography>
                    </RoundedButton>

                    <RoundedButton onClick={ onRegister }>
                        <Typography variant="button">Register</Typography>
                    </RoundedButton>

                </Box>
            </Box>
        </form>
    </RegistrationsFormContainer>
)