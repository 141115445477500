import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect, useCallback } from 'react';
import { Box, Typography, FormHelperText } from '@mui/material';
import { SecurityIcon } from './Icons';
import { MfaFormContainer } from '../styled';
import { DigitInput } from '../styled';
import { useMediaWidth } from '../../hooks';
import { mfaMethodText } from '../commonUIF';

interface Input_MFAProps {
    length: number;
    view: 'credentials' | 'loading' | 'mfa' | 'complete';
    error: boolean;
    helpText: string;
    mfaMethod?: number;
    onCompletion: ( v: string ) => void;
}

export const MFAInput = ({ length, view, error, helpText, mfaMethod, onCompletion }: Input_MFAProps ) => {
    const [ values, setValues ] = useState< Array< string >>( new Array( length ).fill( '' ) );
    const [ activeIndex, setActiveIndex ] = useState< number >( 0 );
    const inputs = useRef<( HTMLInputElement | null )[]>( [] );
    const isDesktop = useMediaWidth({ direction: 'up', breakPoint: 'tablet' });
  
    useEffect(() => {
        if ( view === 'mfa' ) {
            setActiveIndex( 0 );
            // Timeout used to allow the view to finish transitioning before focusing on the first input
            setTimeout( () => inputs.current[ 0 ]?.focus(), 500 );
        }
    }, [ view ]);

    useEffect(() => {
        const value = values.join( '' );
        if ( value.length === length ) {
            setTimeout(() => {
                onCompletion( value );
                resetValues()
            }, 500 );
        }
    }, [ values ]);

    const inputRef = useCallback(( el: HTMLInputElement | null, index: number ) => {
        inputs.current[ index ] = el;
    }, [] );
    
    const handleChange = ( e: ChangeEvent< HTMLInputElement | HTMLTextAreaElement >, index: number ) => {
        const { value } = e.target;
        
        if ( index === 0 && value.length > 1 ) {
            e.preventDefault();
            const otp = value.slice( 0, length );
            const newValues = [ ...values ];
            for ( let i = 0; i < otp.length; i++ ) {
                newValues[ i ] = otp[ i ];
            }
            setValues( newValues );
            const nextIndex = otp.length < length ? otp.length : length - 1;
            setActiveIndex( nextIndex );
            setTimeout(() => inputs.current[ nextIndex ]?.focus(), 0 );
        } else if ( index === 0 && value.length === 1 ) {

            const autofillValues = inputs.current.map(( input ) => input?.value || '' );
            if ( autofillValues.join( '' ).length === length ) {
                setValues( autofillValues );
                setActiveIndex( length - 1 );
                setTimeout(() => inputs.current[ length - 1 ]?.focus(), 0 );
            } else {
                // Normal input behavior
                const newvalues = [ ...values ];
                newvalues[ index ] = value;
                setValues( newvalues );
                setActiveIndex( index + 1 );
                setTimeout(() => inputs.current[ index + 1 ]?.focus(), 0 );
            }

        } else if ( value && /^[0-9]$/.test( value ) ) {
            const newvalues = [ ...values ];
            newvalues[ index ] = value;
            setValues( newvalues );
            if ( value !== '' && index < length - 1 ) {
                setActiveIndex( index + 1 );
                setTimeout(() => inputs.current[ index + 1 ]?.focus(), 0 ); // Timeout used to defer to next event loop so all DOM updates complete
            }
        }
    }


    const handleKeyDown = ( e: KeyboardEvent< HTMLDivElement | HTMLTextAreaElement >, index: number ) => {
        if ( e.key === 'Backspace' && values[ index ] === '' && index > 0 ) {
            e.preventDefault();
            const newvalues = [ ...values ];
            newvalues[ index - 1 ] = '';
            setValues( newvalues );
            setActiveIndex( index - 1 );
            setTimeout(() => {
                inputs.current[ index - 1 ]?.focus();
            }, 0 );
        }
    }

    const handleBlur = ( index: number ) => {
        if ( index === activeIndex && view === 'mfa' ) {
            // Re-focus if user tries to leave the active input
            setTimeout(() => inputs.current[ activeIndex ]?.focus(), 0 );
        }
    };

    const handlePaste = ( e: React.ClipboardEvent< HTMLInputElement > ) => {
        e.preventDefault();
        const paste = e.clipboardData.getData( 'text' )
        if ( paste && paste.length > 1 ) {
            const otp = paste?.slice( 0, length );
            let newValues = [ ...values ];
            for ( let i = 0; i < otp.length; i++ ) {
                newValues[ i ] = otp[ i ];
            }
            setValues( newValues );
            const nextIndex = paste.length < length ? paste.length : length - 1;
            setActiveIndex( nextIndex );
            setTimeout(() => inputs.current[ nextIndex ]?.focus(), 0 );
        }
    }

    const onInput = ( e: React.FormEvent< HTMLInputElement > ) => {
        e.preventDefault();
        const value = e.currentTarget.value;
        if ( value && value.length > 1 ) {
            const otp = value?.slice( 0, length );
            let newValues = [ ...values ];
            for ( let i = 0; i < otp.length; i++ ) {
                newValues[ i ] = otp[ i ];
            }
            setValues( newValues );
            const nextIndex = otp.length < length ? otp.length : length - 1;
            setActiveIndex( nextIndex );
            setTimeout(() => inputs.current[ nextIndex ]?.focus(), 0 );
        }
    }

    const resetValues = () => setValues( new Array( length ).fill( '' ) );

    return (
        <MfaFormContainer
            className='mfa-form-container' 
            view={ view }
            desktop={ isDesktop ? 'true' : 'false' }
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: isDesktop ? '1rem' : '0.5rem',
                }}
            >
                <SecurityIcon outlined color='warning' size='large' />&nbsp;
                { isDesktop && (
                    <React.Fragment>
                        <Typography variant='h6'>Multi-Factor Authentication</Typography>&nbsp; 
                    </React.Fragment>
                )}
                {/* Once customer the customer preferences table is created this will say the previously selected method */}
                <Typography variant='body1'>
                    Please enter the 6 digit code sent to your { mfaMethod ? mfaMethodText( mfaMethod ) : "mobile number." }
                </Typography>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1rem',
                    marginBottom: '2rem',
                    paddingLeft: '1rem',
                    marginLeft: isDesktop ? '0rem' : '-0.5rem'
                }}
            >
                <form className='totp' name="totp" id="totp" autoComplete='one-time-code'>
                    { values.map(( value, index ) => (
                        <DigitInput
                            id={ `totp${ index }` }
                            name="totp"
                            className="totp"
                            key={ index }
                            value={ value }
                            onChange={ ( e ) => handleChange( e, index ) }
                            onKeyDown={ ( e ) => handleKeyDown( e, index ) }
                            onPaste={ index === 0 ? handlePaste : undefined }
                            autoComplete='one-time-code'
                            onBlur={ () => handleBlur( index ) }
                            onInput={  index === 0 ?  onInput : undefined  }
                            inputProps={{
                                maxLength: index === 0 ? length : 1,
                                keyboardtype: 'number-pad',
                                textcontenttype: 'oneTimeCode',
                                inputMode: 'numeric',
                                type: 'text',
                                tabIndex: index === activeIndex ? 0 : -1,
                                readOnly: index !== activeIndex // Only active input is editable
                            }}
                            inputRef={ ( el ) => inputRef( el, index ) }
                            error={ error }
                            view={ view }
                            animationdelay={ '0.5' }
                            disabled={ index > activeIndex }
                        />
                    ))}
               </form>
            </Box>
            { error && helpText && (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <FormHelperText error>{ helpText }</FormHelperText>
                </div>
            )}
        </MfaFormContainer>
    )
}