import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import AppStateReducer from './slicers/AppState'
import AccessReducer from "./slicers/Access";
import StepUpAuthReducer from "./slicers/StepUpAuth";
//import { LoggingMiddleware } from "../utils";

const rootReducer = combineReducers({
    AppState: AppStateReducer,
    Access: AccessReducer,
    StepUpAuth: StepUpAuthReducer
});

export type RootState = ReturnType< typeof rootReducer >;

export const store = configureStore({
    reducer: rootReducer,
    // middleware: ( getDefaultMiddleware ) =>
    //     getDefaultMiddleware().concat(
    //         LoggingMiddleware
    //     )
});


export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = () => useDispatch();
export const useAppSelector: TypedUseSelectorHook< RootState > = useSelector;