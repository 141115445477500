import React, { type PropsWithChildren } from 'react';
import { PreAuthStyledGrid, BrandingBox, FormContainer } from '../styled';
import { BrandedLogoBanner } from '../UI/BrandedLogoBanner';

interface MainGridProps extends PropsWithChildren< unknown >{

}

export const PreAuthMainGrid = ({ children }: MainGridProps ) => (
    <PreAuthStyledGrid className='contentMain'>
        <BrandingBox className='contentMain-backgroundBrandingBox'>
            <BrandedLogoBanner />
        </BrandingBox>

        <FormContainer className='contentMainForm'>

            { children }
        
        </FormContainer>
    </PreAuthStyledGrid>
)