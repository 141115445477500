import React from 'react';
import { Typography } from '@mui/material';
import { StyledContentHeader } from '../../components/styled';

interface ContentHeaderProps {
    Title: string;
    Subtitle: string;
}

export function DocumentsHeader({ Title, Subtitle }: ContentHeaderProps ) {
    return (
        <StyledContentHeader>
            <Typography variant='h5'>
               { Title }
            </Typography>
            <Typography variant='body1'>
                { Subtitle }
            </Typography>
        </StyledContentHeader>
    )
}