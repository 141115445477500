import React from "react"
import { IReimbursmentSubmission } from "../../../../constants/interfaces";
import { validateDate } from "../../../../utils/commons";
import { reimbursmentSubmissionSchema } from "../../../../utils/validation";


export function validateSubmission( submission: IReimbursmentSubmission ) {
    let errors: { field: string, message: string }[] = [];
    let isValid = true;
    

    const schema = reimbursmentSubmissionSchema;
    const { error } = schema.validate( submission );
    
    if ( error ) {
        const joiErrors = error.details.map(( detail ) => ({
            field: detail.path.join( '.' ),
            message: detail.message.replace(/['"]/g, "")
        }))
        errors.push( ...joiErrors );
        isValid = false;
    }

    if ( submission.BenefitID === 0 ) {
        errors.push({ field: 'leaseSelect', message: 'Please select a package to claim against' });
        isValid = false;
    }

    if ( submission.BenefitOperCostTypeID === 0 ) {
        errors.push({ field: 'operCostSelect', message: 'Please select the type of reimbursement' });
        isValid = false;
    }

    if ( !submission.AmountIncTax ) {
        errors.push({ field: 'amountIncTax',  message: 'Please enter the amount to be reimbursed' });
        isValid = false;
    }

    if ( 
        parseInt( submission.AmountIncTax, 10 ) < 0.01 
        || parseInt( submission.AmountTax, 10 ) < 0.00
        || parseInt( submission.AmountIncTax, 10 ) > 999999 
        || parseInt( submission.AmountTax, 10 ) > 999999 
    ) {
        errors.push({ 
            field: `${ parseInt( submission.AmountTax, 10 ) > 999999 ? 'amountTax' : 'amountIncTax' }`, 
            message: 'Please enter a valid amount for the reimbursement' 
        });
        isValid = false;
    }

    if ( typeof submission.BankAccountID === 'number' &&  submission.BankAccountID === 0 ) {
        errors.push({ field: 'bankAccountSelect', message: 'Please select bank account' });
        isValid = false;
    }

    if ( submission.SupplierCompanyName === null || submission.SupplierCompanyName === '' ) {
        errors.push({ field: 'supplierName', message: 'Please provide supplier for the reimbursement' });
        isValid = false;
    }

    if ( submission.IsNoReferenceNumber === false && ( submission.SupplierRefText === null || submission.SupplierRefText === '' ) ) {
        errors.push({ field: 'refNumber', message: 'Please enter the reference' });
        isValid = false;
    }

    if ( submission.Odometer === null || submission.Odometer === 0 ) {
        errors.push({ field: 'odoReading', message: 'Please enter the current odometer reading' });
        isValid = false;
    }

    if ( submission.FileAttachments.length === 0 ) {
        errors.push({ field: 'files', message: 'Please attach the relevant documents for the reimbursement' });
        isValid = false;
    }
    console.log( 'errors', isValid, errors );
    return { isValid, errors };
}