import React from 'react';
import { logout } from '../redux/slicers/Access';
import { toggleLoading, toggleError, addAlert } from '../redux/slicers/AppState';
import { initiateStepUpProcess, vidRequired } from '../redux/slicers/StepUpAuth';
import { HttpHandler } from './HttpHandler';
import { IResults } from '../constants/interfaces';
import { HttpMethods, AppStatusCodes, Action, HttpStatusCodes, ErrorCodes } from '../constants/enums';


const successCodes = [ 
    AppStatusCodes.SUCCESS,
    AppStatusCodes.SUCCESS_MODIFIED_DATA,
    AppStatusCodes.AUTHENTICATED,
    AppStatusCodes.MFA_REQUIRED,
    AppStatusCodes.STEP_UP_REQUIRED,
    AppStatusCodes.STEP_UP_APPROVED,
    AppStatusCodes.VID_VERIFICATION_REQUIRED,
    AppStatusCodes.DATA_VALIDATION_JOI,
    AppStatusCodes.VALIDATION_FAILED,
    AppStatusCodes.USER_NOT_FOUND,
    AppStatusCodes.USER_LOCKED_OUT,
    AppStatusCodes.FAILED_PASSWORD
]

const snackbarCodes = [
    AppStatusCodes.USER_LOCKED_OUT,
    AppStatusCodes.NO_RECORDS_FOUND,
    AppStatusCodes.MFA_MAX_ATTEMPTS_EXCEEDED,
    AppStatusCodes.MFA_INVALID_CODE
]

export async function HttpLoadingHandler( method: HttpMethods, endpoint: any, action: Action, data: any, thunkAPI: any ): Promise< IResults > {
    
    thunkAPI.dispatch( toggleLoading( true ) );
    
    try {
        const timeout: Promise< IResults > = new Promise((_, reject) => 
            setTimeout(() => reject(new Error('Request Timeout')), 60000) // 60-second timeout
        );

        const { success, statusCode, code, message, payload } = await Promise.race([
            HttpHandler({ method, endpoint, data, thunkAPI }),
            timeout
        ]);
        if ( !success && statusCode === HttpStatusCodes.UNAUTHORIZED || code === AppStatusCodes.NO_CURRENT_USER_SESSION_REAUTH ) {
            thunkAPI.dispatch( logout() );
            thunkAPI.dispatch( toggleError({ 
                error: true, 
                errorCode: AppStatusCodes.NO_CURRENT_USER_SESSION_REAUTH, 
                errorMessage: message ? message : '' 
            }));
        }

        if ( !success && !successCodes.includes( code ) && !snackbarCodes.includes( code ) ) {
            thunkAPI.dispatch( toggleError({ error: true, errorCode: code, errorMessage: message ? message : '' }) );
        }

        if ( code === AppStatusCodes.SERVER_INIT_STEPUP ) {
            thunkAPI.dispatch( initiateStepUpProcess({ action: action, data: data }) );
        }
        if ( code === AppStatusCodes.VID_VERIFICATION_REQUIRED ) {
            const vidType: false | 'PHV' | 'EMV' = payload;
            thunkAPI.dispatch( vidRequired( vidType ) );
        }

        return {
            success: success,
            statusCode: statusCode,
            code: code,
            message: message,
            payload: payload
        } as IResults;

    } catch ( error: any ) {

        if ( error?.message === 'Request Timeout' ) {
            thunkAPI.dispatch( addAlert({ id: Date.now(),  message: 'Request Timeout', type: 'error' }) );
            return {
                success: false,
                code: ErrorCodes.HTTP_REQUEST_TIMEOUT,
                message: 'Request Timeout',
                payload: null
            }
        }
        
        thunkAPI.dispatch( toggleError({ error: true, errorCode: error.code, errorMessage: error.message }) );
        
        return error as IResults;

    } finally {

        thunkAPI.dispatch( toggleLoading( false ) );
    
    }
}