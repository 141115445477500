import { styled, Box, InputLabel, MenuItem, Select, alpha, Theme } from '@mui/material';


export const BasicSelectContainer = styled( Box )(({ theme }) => ({
    minWidth: '100%',
    width: '100%'
}))

export const BasicSelectLabel = styled( InputLabel )(({ theme }) => ({
    
}))

export const BasicSelect = styled( Select )(({ theme, error }) => ({
    border: 'none',
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${ theme.palette.inputs.border.color }`,
    '&:hover': {
        border: 'none',
        borderBottom: `1px solid ${ theme.palette.inputs.hover }`
    },
    '&.Mui-focused, &.MuiFormLabel-filled': {
        borderBottom: `1px solid ${ theme.palette.inputs.focus }`,
        backgroundColor: 'transparent',
        outline: 'none'
    },
    '&:before, &:after': {
        borderBottom: `none`, // Make sure these don't override your hover
        content: 'none',
        backgroundColor: 'transparent'
    },
    '&.Mui-disabled': {
        borderBottom: `1px dotted ${ theme.palette.text.disabled }`,
        color: theme.palette.text.disabled,
        outline: 'none',
    },
    ...( error && {
        borderBottom: `1px solid ${ theme.palette.error.main }`,
        '&:hover': {
            borderBottom: `1px solid ${ theme.palette.error.main }`
        },
        '&.Mui-focused, &.MuiFormLabel-filled': {
            borderBottom: `1px solid ${ theme.palette.error.main }`,
        }
    })
}))

export const BasicSelectMenuOption = styled( MenuItem )(({ theme }) => ({
    minHeight: '1.5rem',
    height: '1.5rem',
    '&:hover': {
        backgroundColor: alpha( theme.palette.common.skyCyan, 0.2 )
    }
}))

export const MenuStyles = ( mode: boolean, theme: Theme ) =>{
    return {
        paper: {
            backgroundColor: mode ? theme.palette.background.default : theme.palette.background.paper,
            backgroundImage: mode ? `
                linear-gradient( ${ theme.palette.background.backgroundOverlay }, ${ theme.palette.background.backgroundOverlay } ),
                linear-gradient( ${ theme.palette.overlays.level1 }, ${ theme.palette.overlays.level1 } ),
                linear-gradient( ${ theme.palette.overlays.level16 }, ${ theme.palette.overlays.level16 } )
            `
            : 'none',
        }
    }
}

