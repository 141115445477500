import { Typography, Box, Stack, Theme } from "@mui/material";
import { LoginFormContainer, SlideContainer, FadeContainer } from "../../components/styled";
import { StepperBlue, IndeterminateLoading } from "../../components/UI";
import { UserRegistrationForm } from "./UserRegistrationForm";
import { SuccessResponse, NoMobileNumber } from "./ResponseDisplay";


interface RegistrationFormProps {
    currentView: 'details' | 'mfa' | 'loading' | 'noMobile';
    currentStep: number;
    email: string;
    confirmEmail: string;
    setEmail: ( v: string ) => void;
    emailError: boolean;
    setConfirmEmail: ( v: string ) => void;
    confirmEmailError: boolean;
    onRegister: () => void;
    onCancel: () => void;
    helpText: string;
    theme: Theme;
}

export const RegistrationForm = ({
    currentView,
    currentStep,
    email,
    confirmEmail,
    setEmail,
    emailError,
    setConfirmEmail,
    confirmEmailError,
    onRegister,
    onCancel,
    helpText,
    theme
}: RegistrationFormProps) => {
    const steps: Array< string > = [ 'Confirm Details', 'Multi-Factor Authentication', 'Set Password' ]
    
    const handlekeyPress = ( event: React.KeyboardEvent ) => {
        if ( event.key === 'Enter' ) {
            onRegister()
        }
    }

    return (
        <LoginFormContainer className="new-registration-form">
            <Stack sx={{ width: '100%', height: '100%' }}>

                <FadeContainer>
                    <Typography variant="h3">
                        Welcome to <span style={{ color: theme.palette.common.elecBlue }}>AutoDash</span>
                    </Typography>

                    <Box
                        style={{
                            alignContent: 'flex-start',
                            width: '100%',
                            paddingTop: '2rem'
                        }}
                    >
                        <Typography variant="h4">Effortless access, anywhere, anytime</Typography>
                    </Box>
                </FadeContainer>

                <StepperBlue steps={ steps } activeStep={ currentStep } viewport="desktop" />

                <SlideContainer className="slide-container-reg">
                    
                    <UserRegistrationForm
                        currentView={ currentView }
                        email={ email }
                        confirmEmail={ confirmEmail }
                        setEmail={ setEmail }
                        emailError={ emailError }
                        setConfirmEmail={ setConfirmEmail }
                        confirmEmailError={ confirmEmailError }
                        onRegister={ onRegister }
                        onKeyDown={ handlekeyPress }
                        onCancel={ onCancel }
                        helpText={ helpText }
                        theme={ theme }
                    />

                    <IndeterminateLoading show={ currentView === 'loading' } />

                    <SuccessResponse
                        currentView={ currentView }
                        theme={ theme }
                    />

                    <NoMobileNumber
                        currentView={ currentView }
                        onClickHandler={ onCancel }
                        theme={ theme }
                    />

                </SlideContainer>
  
            </Stack>
        </LoginFormContainer>
    )
}