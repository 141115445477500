import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { ToolTipWrapper } from '../ToolTipWrapper';
import {
    DataCard,
    DataCardContent,
    DataCardLabel,
    DataCardValue
} from '../../styled';


interface DataFieldConditionalProps {
    condition: boolean;
    className?: string;
    data: {
        key: string;
        label: string;
        value: any;
        isEdit: boolean;
        isCurrent?: boolean;
    };
    children?: React.ReactNode;
    xsmobile?: number;
    mobile?: number;
    tablet?: number;
    laptop?: number;
    desktop?: number;
}

export function DataFieldConditional({ condition, children, data, ...props }: DataFieldConditionalProps ) {
    const { className, xsmobile, mobile, tablet, laptop, desktop } = props
    const [ isOverflowing, setIsOverflowing ] = useState( false );
    const isEditable = data.isEdit === true ? true : false
    const valueRef = useRef< HTMLSpanElement >( null );

    useEffect(() => {
        const checkOverflow = () => {
            if ( valueRef.current ) {
                const isOverflowing = valueRef.current.offsetWidth < valueRef.current.scrollWidth;
                setIsOverflowing( isOverflowing );
            }
        };

        checkOverflow();

        // Recheck on window resize
        const handleResize = () => {
            checkOverflow();
        };

            window.addEventListener( 'resize', handleResize );
        return () => {
            window.removeEventListener( 'resize', handleResize );
        };
    }, [ children ]);

    return (
        <Grid item
            className={ className ? className : '' }
            key={ data.key }
            xsmobile={ xsmobile ? xsmobile : 'auto' }
            mobile={ mobile ? mobile : 'auto' }
            tablet={ tablet ? tablet : 'auto' }
            laptop={ laptop ? laptop : 'auto' }
            desktop={ desktop ? desktop : 'auto' }
        >
            { condition === false && isEditable === true ? (
                <React.Fragment>
                    { children }
                </React.Fragment>
            ) : (
                <React.Fragment>

                    { !isOverflowing && (
                        <DataCard sx={{ marginTop: '0.2rem' }}>
                            <DataCardContent>
                                <DataCardLabel variant='dataLabel'>
                                    { data.label }
                                </DataCardLabel>
                                <DataCardValue ref={ valueRef } variant='dataValue'>
                                    { data.value }
                                </DataCardValue>
                            </DataCardContent>
                        </DataCard>
                    )}

                    { isOverflowing && (
                        <DataCard sx={{ marginTop: '0.2rem' }}>
                            <ToolTipWrapper title={ data.value }
                                placement='right-end'
                            >
                                <DataCardContent>
                                    <DataCardLabel variant='dataLabel'>
                                        { data.label }
                                    </DataCardLabel>
                                    <DataCardValue ref={ valueRef } variant='dataValue'>
                                            { data.value }
                                    </DataCardValue>
                                </DataCardContent>
                            </ToolTipWrapper>
                        </DataCard>
                    )}
                    
                </React.Fragment>
            )}
        </Grid>
    )
}