import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initUserSession, initUserDetails } from '../InitializersOrActions';
import { AppStatusCodes } from '../../constants/enums';
import { vidVerification, resetStepUpAuth } from './StepUpAuth';
import {
    IDataAccessState,
    IResults,
    IUserDetails,
    IUserPhone,
    IUserEmail,
    IUserAddress,
    IPackageBasicDetails,
    IPackageDetails,
    ILeaseAccDetailOverview,
    IPackageBalanceHistory,
    IBalanceHistory,
    AuthSuccessPayload,
    UserSession,
    IFile,
    IReimbHistoryItem,
    IReimbAttachment,
    IBankAccount,
    IPreferences,
    IBudgetItem
} from '../../constants/interfaces';
import {
    loginMFA,
    getUserDetails,
    getBasicPackageDetail,
    getFullPackageDetail,
    getPackageLeaseAccDetail,
    getBalanceHistory,
    updatePreferredName,
    updatePhone,
    updateEmail,
    updateAddress,
    updatePreferences,
    fetchDocuments,
    getReimbursementHistory,
    submitNewReimbursement,
    fetchBankAccounts,
    deletePhone,
    deleteEmail,
    addNewEmail,
    addNewPhone,
    addNewAddress,
    addNewBankAccount,
    deleteBankAccount,
    userLogout,
    fetchBudgetData
} from '../AccessThunks';


const successCodes = [ 
    AppStatusCodes.SUCCESS,
    AppStatusCodes.SUCCESS_MODIFIED_DATA,
    AppStatusCodes.AUTHENTICATED
]

const initialState: IDataAccessState = {
    session: { ...initUserSession },
    Authenticated: false,
    userDetails: initUserDetails,
    basicPackageDetail: [],
    fullPackageDetail: null,
    leaseAccDetail: {} as ILeaseAccDetailOverview,
    balanceHistory: [],
    accessControl: false,
    notifications: [],
    documents: [],
    reimbursementHistory: [],
    registeredBankAccounts: [],
    budgetData: []
}

const persistAccessState = ( state: IDataAccessState ) => {
    sessionStorage.setItem(
        'state',
        JSON.stringify({
            UserSession: state.session
        })
    )
}

export const Access = createSlice({
    name: 'Access',
    initialState,
    reducers: {
        logout: ( state ) => {
            state.session = initUserSession
            state.Authenticated = false
            state.userDetails = initUserDetails
            state.basicPackageDetail = []
            state.balanceHistory = []
            state.fullPackageDetail = null
            state.leaseAccDetail = {} as ILeaseAccDetailOverview
            state.notifications = []
            state.documents = []
            state.reimbursementHistory = []
            state.registeredBankAccounts = []
            state.budgetData = []
            sessionStorage.removeItem( 'state' )
            resetStepUpAuth()
        },
        setUserDetails: ( state, action: PayloadAction<IResults> ) => {
            state.userDetails = action.payload.payload
        },
        setPackageBasicDetail: ( state, action: PayloadAction<IResults> ) => {
            state.basicPackageDetail = action.payload.payload as Array< IPackageBasicDetails >
        },
        setPackageFullDetail: ( state, action: PayloadAction<IResults> ) => {
            state.basicPackageDetail = action.payload.payload as Array< IPackageBasicDetails >
        },
        setLeaseAccDetail: ( state, action: PayloadAction<IResults> ) => {
            state.leaseAccDetail = action.payload.payload as ILeaseAccDetailOverview
        },
        setAuthState: ( state, action: PayloadAction<{ newState: boolean, authID: string }> ) => {
            let prevAuthState = state.Authenticated;
            let newAuthState = action.payload.newState;
            let prevAuthID = state.session.AuthID;
            let authID = action.payload.authID ? action.payload.authID : '' ;
            if ( prevAuthState === newAuthState && prevAuthID === authID ) {
                return
            }
            state.Authenticated = newAuthState
            state.session.Authenticated = newAuthState
            state.session.AuthID = authID
            persistAccessState( state )
        },
        setAuthID: ( state, action: PayloadAction< string > ) => {
            if ( state.session.AuthID === action.payload ) return
            state.session.AuthID = action.payload
        },
        triggerAccessControl: ( state, action: PayloadAction<boolean> ) => {
            state.accessControl = action.payload
        },
        restoreAccessState: ( state, action: PayloadAction< any > ) => {
            const { Authenticated } = action.payload.UserSession
            if ( Authenticated ) {
                state.session = action.payload.UserSession;
                state.Authenticated = action.payload.UserSession.Authenticated;
                return
            }
            // Remove session from storage if not authenticated
            sessionStorage.removeItem( 'state' )
        },
    },
    extraReducers: ( builder ) => {
        builder.addCase( loginMFA.fulfilled, ( state, action: PayloadAction<IResults> ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            const { Session, UserDetails, BenefitList } = action.payload.payload as AuthSuccessPayload
            if ( !Session.Authenticated || !Session.AuthID ) return
            
            const session: UserSession ={
                UserID: Session.UserID,
                ContactID: Session.ContactID,
                EmailAddress: Session.EmailAddress,
                Authenticated: Session.Authenticated,
                AuthID: Session.AuthID
            }

            const userDetails: IUserDetails = {
                ContactID: UserDetails.ContactID,
                FirstName: UserDetails.FirstName,
                LastName: UserDetails.LastName,
                PreferredName: UserDetails.PreferredName,
                CompanyName: UserDetails.CompanyName,
                CompanyStreetAddress: UserDetails.CompanyStreetAddress,
                CompanyCity: UserDetails.CompanyCity,
                CompanyState: UserDetails.CompanyState,
                CompanyPostcode: UserDetails.CompanyPostcode,
                PayrollNumber: UserDetails.PayrollNumber,
                RoleTitle: UserDetails.RoleTitle,
                Preferences: UserDetails.Preferences,
                PhoneNumbers: UserDetails.PhoneNumbers,
                EmailAddresses: UserDetails.EmailAddresses,
                Addresses: UserDetails.Addresses
            }

            const filterEnded: Array< IPackageBasicDetails > = BenefitList.filter( ( item: IPackageBasicDetails ) => item.BenefitStatus !== 'Ended' )

            const Benefits: Array< IPackageBasicDetails > = BenefitList.length > 0 ? filterEnded : []
            
            if ( Session.ContactID !== UserDetails.ContactID ) return

            state.session = session
            state.Authenticated = session.Authenticated
            state.userDetails = userDetails
            state.basicPackageDetail = Benefits ? Benefits : []
            persistAccessState( state )
            
        });
        builder.addCase( getUserDetails.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.userDetails = action.payload.payload as IUserDetails
        });
        builder.addCase( getBasicPackageDetail.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code, payload } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            const filterEnded: Array< IPackageBasicDetails > = payload.filter( ( item: IPackageBasicDetails ) => item.BenefitStatus !== 'Ended' )
            state.basicPackageDetail = filterEnded
        });
        builder.addCase( getFullPackageDetail.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.fullPackageDetail = action.payload.payload as IPackageDetails
        });
        builder.addCase( getPackageLeaseAccDetail.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.leaseAccDetail = action.payload.payload as ILeaseAccDetailOverview
        });
        builder.addCase( getBalanceHistory.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            const history: IPackageBalanceHistory = {
                BenefitID: action.payload.payload[ 0 ].BenefitID,
                History: action.payload.payload as Array< IBalanceHistory >
            }
            state.balanceHistory.push( history )
        });
        builder.addCase( updatePreferredName.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.userDetails.PreferredName = action.payload.payload as string
        });
        builder.addCase( updatePhone.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.userDetails.PhoneNumbers = action.payload.payload as Array< IUserPhone >
        });
        builder.addCase( updateEmail.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.userDetails.EmailAddresses = action.payload.payload as Array< IUserEmail >
        });
        builder.addCase( updateAddress.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.userDetails.Addresses = action.payload.payload as Array< IUserAddress >
        });
        builder.addCase( updatePreferences.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            let prefs = action.payload.payload as IPreferences
            state.userDetails.Preferences = prefs
        });
        builder.addCase( fetchDocuments.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.documents = action.payload.payload as Array< IFile >
        });
        builder.addCase( getReimbursementHistory.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            let claimsArray = action.payload.payload as Array< IReimbHistoryItem >;
            if ( claimsArray.length > 0 ) {
                state.reimbursementHistory = claimsArray;
            }
        });
        builder.addCase( submitNewReimbursement.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            let claimsArray = action.payload.payload.UpdatedHistory as Array< IReimbHistoryItem >;
            if ( claimsArray.length > 0 ) {
                state.reimbursementHistory = claimsArray;
            }
        });
        builder.addCase( fetchBankAccounts.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.registeredBankAccounts = action.payload.payload as Array< IBankAccount >;
        });
        builder.addCase( deletePhone.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.userDetails.PhoneNumbers = action.payload.payload as Array< IUserPhone >
        });
        builder.addCase( deleteEmail.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.userDetails.EmailAddresses = action.payload.payload as Array< IUserEmail >
        });
        builder.addCase( addNewEmail.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.userDetails.EmailAddresses = action.payload.payload as Array< IUserEmail >
        });
        builder.addCase( addNewPhone.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.userDetails.PhoneNumbers = action.payload.payload as Array< IUserPhone >
        });
        builder.addCase( addNewAddress.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.userDetails.Addresses = action.payload.payload as Array< IUserAddress >
        });
        builder.addCase( addNewBankAccount.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.registeredBankAccounts = action.payload.payload as Array< IBankAccount >
        });
        builder.addCase( deleteBankAccount.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.registeredBankAccounts = action.payload.payload as Array< IBankAccount >
        });
        builder.addCase( vidVerification.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code, payload } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            
            if ( payload.typeCode === 'EMV' ) {
                state.userDetails.EmailAddresses = payload.data as Array< IUserEmail >
            } else if ( payload.typeCode === 'PHV' ) {
                state.userDetails.PhoneNumbers = payload.data as Array< IUserPhone >
            }
        });
        builder.addCase( fetchBudgetData.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            const { success, code, payload } = action.payload
            if ( !success || !successCodes.includes( code ) ) return
            state.budgetData = payload as Array< IBudgetItem >
        });
        builder.addCase( userLogout.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            logout()
        });
    }
})

export const {
    logout,
    setUserDetails,
    setPackageBasicDetail,
    setPackageFullDetail,
    setLeaseAccDetail,
    setAuthState,
    triggerAccessControl,
    restoreAccessState,
    setAuthID
} = Access.actions

export default Access.reducer
