import axios, { AxiosRequestConfig } from 'axios';
import { HttpMethods } from '../constants/enums';
import { IResults,  } from '../constants/interfaces';
import { logout } from '../redux/slicers/Access';
import { UUID } from '../types';
import { global_EMPTY_UUID } from '../constants';
import { HttpStatusCodes, ErrorCodes, AppStatusCodes } from '../constants/enums';

const serverAPI = process.env.API_URL

export interface IHttpHandler {
    method: HttpMethods,
    endpoint: string,
    data: any,
    thunkAPI: any;
}

const errorCodes = [
    HttpStatusCodes.BAD_REQUEST,
    HttpStatusCodes.FORBIDDEN,
    HttpStatusCodes.REQUEST_TIMEOUT,
    HttpStatusCodes.INTERNAL_SERVER_ERROR,
    HttpStatusCodes.UNSUPPORTED_MEDIA_TYPE,
    HttpStatusCodes.PAYLOAD_TOO_LARGE,
    HttpStatusCodes.CONFLICT
]

let aidPromise: Promise< UUID > | null = null;

// This function is used to check if the user is authenticated and if not, it will wait until the user is authenticated
async function awaitAID( thunkAPI: any ): Promise< UUID > {
    if ( !aidPromise ) {
        aidPromise = new Promise(( resolve, reject ) => {
            const timeout = setTimeout(() => {
                clearInterval( interval );
                reject( new Error( "AuthID retrieval timed out" ));
            }, 5000 ); // Timeout after 5 seconds

            const interval = setInterval(() => {
                const authID = thunkAPI.getState().Access.session?.AuthID as UUID;
                const storedAid = sessionStorage.getItem( 'state' );
                let parsedAID: UUID;
                
                if ( authID ) {
                    clearInterval( interval )
                    clearTimeout( timeout )
                    resolve( authID )
                    aidPromise = null;
                } else if ( storedAid ) {
                    try {
                        parsedAID = JSON.parse( storedAid )?.Access.session.AuthID as UUID
                        if ( parsedAID ) {
                            clearInterval( interval )
                            clearTimeout( timeout )
                            resolve( parsedAID )
                            aidPromise = null;
                        }
                    } catch ( error ) {
                        console.error( `Error parsing stored AID ${ error }` )
                    }
                }
            }, 50 )
        })
    }
    return aidPromise;
}

export async function HttpHandler({ method, endpoint, data, thunkAPI }: IHttpHandler ): Promise< IResults > {
    
    const authID = await awaitAID( thunkAPI );
    
    const axiosConfig: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
            AID: authID || global_EMPTY_UUID,
            SUA: thunkAPI.getState().StepUpAuth?.stepUpAuthID as UUID || global_EMPTY_UUID
        },
        method,
        url: serverAPI + endpoint,
        data,
        withCredentials: true
    }
    
    try {
        
        const response = await axios( axiosConfig );
        const { success, code, message, payload } = response.data;

        if ( process.env.NODE_ENV === 'development' ) {
            console.debug( `server = ${ serverAPI }` )
            console.debug( `endpoint = ${ endpoint } ${ JSON.stringify( response.data ) }` )
        }

        return {
            success: success,
            statusCode: response.status,
            code: Number( code ),
            message: message,
            payload: payload
        } as IResults;
        
    } catch ( error: any ) {

        let errorMessage: string;
        let errorCode: number;
        let statusCode: number;
        console.info( 'raw error: ', error )
        if ( error.response?.status === HttpStatusCodes.UNAUTHORIZED || error?.status === HttpStatusCodes.UNAUTHORIZED ) {
            thunkAPI.dispatch( logout() );
            errorCode = AppStatusCodes.NO_CURRENT_USER_SESSION_REAUTH;
            errorMessage = 'Your session has expired, please login again';
            statusCode = HttpStatusCodes.UNAUTHORIZED;
        } else if ( error?.request ) {
            // Network Error
            errorMessage = `Our servers are currently experiencing issues, please try again later`;
            errorCode = ErrorCodes.NETWORK_ERROR;
            statusCode = HttpStatusCodes.SERVICE_UNAVAILABLE;
        } else if ( error?.response ) {
            // Server Error
            errorMessage = error?.response?.data?.message || 'Our servers are currently experiencing issues, please try again later';
            errorCode = error?.response?.data?.code ? error?.response?.data?.code : ErrorCodes.HTTP_STATUS;
            statusCode = error?.response?.status;
        } else {
            // Unknown Error
            errorMessage = `An unexpected error has occurred, please try again later`;
            errorCode = ErrorCodes.UNKOWN_FUNCTION_ERROR;
            statusCode = HttpStatusCodes.INTERNAL_SERVER_ERROR;
        }

        if ( process.env.NODE_ENV === 'development' ) {
            console.error( `server = ${ serverAPI }` )
            console.error( `endpoint = ${ endpoint }, StatusCode: ${ error.response ? error.response.status: null }` )
            console.error( `error = message: ${ errorMessage }` )
        }
        
        throw {
            success: false,
            statusCode: statusCode,
            code: errorCode,
            message: errorMessage,
            payload: null
        } as IResults

    }
}