import { IFile } from '../../constants/interfaces';
import { Dialog_DetailEdit } from "../../components/UI";
import { Typography, Grid, Card } from '@mui/material';
import { fileDownload } from '../../utils/commons';

interface IClickResponse {
    FileID: number;
    FileName: string;
    Ext: string;
}

interface ViewAttachmentsDialogProps {
    show: boolean;
    attachments: Array< IFile >;
    onClick: ( data: IClickResponse ) => void;
    close: () => void;
}

export const ViewAttachmentsDialog = ({ show, attachments, onClick, close }: ViewAttachmentsDialogProps ) => {

    return (
        <Dialog_DetailEdit
            show={ show }
            title='Attachments'
            slideDirection='up'
            cancelLabel='Close'
            onCancel={ close }
        >
            <Grid container 
                spacing={ 1 }
                columns={ 1 }
                display={ 'flex' }
                flexDirection={ 'column' }
                justifyContent='center'
                alignItems='center'
                minWidth='100%'
            >
                {
                    attachments.map( ( file, index ) => (
                        <Grid item 
                            key={ index }
                            xsmobile={ 1 } mobile={ 1 } tablet={ 1 } laptop={ 1 } desktop={ 1 }
                        >
                            <Card style={{ 
                                    cursor: 'pointer',
                                    padding: '0.5rem',
                                    width: '6vw'
                                }}
                                onClick={ () => onClick({ 
                                        FileID: file.FileID,
                                        FileName: `${ file.FileID }_${ file.FileNameOnly }`,
                                        Ext: file.FileExtension 
                                    }) 
                                }
                            >
                                <Typography 
                                    variant='body1' 
                                    color='primary' 
                                    style={{ cursor: 'pointer' }}
                                >
                                    { file.FileNameOnly }
                                </Typography>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </Dialog_DetailEdit>
    )
}
