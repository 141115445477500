import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled( LinearProgress )<{ value: number }>(({ theme, value }) => ({
    height: 10,
    borderRadius: 5,
    [ `&.${ linearProgressClasses.colorPrimary }` ]: {
        backgroundColor: "#FFF",
        borderRadius: 5,
        overflow: 'hidden',
        padding: 1,
        boxShadow: `inset 0 0 0 1px ${ theme.palette.text.disabled }`,

    },
    [ `& .${ linearProgressClasses.bar }` ]: {
        borderRadius: 5,
        background: `linear-gradient(to right, 
            ${ theme.palette.primary.main } ${ 100 - value }%,
            ${ "#A07CFF" } ${ 150 - value }%,
            ${ theme.palette.common.sunflare } ${ 200 - value }%
        )`,
        marginRight: 1
    },
}));


interface LinearProgressGaugeProps {
    value: number;
}
export const LinearProgressGauge = ({ value }: LinearProgressGaugeProps ) => (
    <Box sx={{ flexGrow: 1 }}>
        <Typography variant='caption'>{ value > 100 ? '>100' : value }% used { value > 100 ? `(${ value }%)` : "" }</Typography>
        <BorderLinearProgress variant="determinate" value={ value > 100 ? 100 : value } />
    </Box>
)