import React from 'react';
import { useTheme } from '@mui/material';
import { SocialsContainer } from '../styled';
import {
    FaceBookIC,
    InstagramIC,
    LinkedInIC,
    YouTubeIC,
    GitHubIC,
    IedgeIC
} from './Icons';


interface SocialsProps {
    className?: string;
    WB?: boolean;
    FB?: boolean;
    IG?: boolean;
    LI?: boolean;
    YT?: boolean;
    GH?: boolean;
    reverseOrder?: boolean;
    align: 'flex-start' | 'center' | 'flex-end';
    buttonSizes?: 'small' | 'medium' | 'large';
    iconSizes?: 'small' | 'medium' | 'large' | 'inherit';
    IElogoPx?: string;
    color?: string;
    disableAll?: boolean;
    login?: boolean;
}

export function Socials({ WB, FB, IG, LI, YT, GH, align, reverseOrder, buttonSizes, iconSizes, IElogoPx, color, disableAll }: SocialsProps ) {
    const theme = useTheme();
    const mode = theme.palette.mode;

    const website = process.env.IEDGE_WEBSITE ? process.env.IEDGE_WEBSITE : 'https://iedge.com.au'
    const facebook = process.env.IEDGE_FACEBOOK ? process.env.IEDGE_FACEBOOK : 'https://www.facebook.com/InsideEdgeNovatedknowHow/'
    const instagram = process.env.IEDGE_INSTAGRAM ? process.env.IEDGE_INSTAGRAM : 'https://www.instagram.com/insideedgenovatedknowhow/'
    const linkedin = process.env.IEDGE_LINKEDIN ? process.env.IEDGE_LINKEDIN : 'https://www.linkedin.com/company/inside-edge-know-how'
    const youtube = process.env.IEDGE_YOUTUBE ? process.env.IEDGE_YOUTUBE : 'https://www.youtube.com/watch?v=PQ9cNqoP39E'
    const github = process.env.IEDGE_GITHUB ? process.env.IEDGE_GITHUB : 'https://github.com/BraddlesUnravels'

    return (
        <SocialsContainer align={ align } reverse={ reverseOrder ? true : undefined }>

            <IedgeIC
                visable={ WB ? true : false }
                buttonSize={ buttonSizes ? buttonSizes : 'small' }
                iconSize='inherit'
                pxIELogoSize={ IElogoPx ? IElogoPx : '24px' }
                mode={ mode }
                link={ website }
                doClick={ ( v ) => window.open( v, '_blank' ) }
                disabled={ disableAll ? true : false }
            />

            <FaceBookIC
                visable={ FB ? true : false }
                buttonSize={ buttonSizes ? buttonSizes : 'small' }
                iconSize={ iconSizes ? iconSizes : 'small' }
                color={ color ? color : 'inherit' }
                link={ facebook }
                doClick={ ( v ) => window.open( v, '_blank' ) }
                disabled={ disableAll ? true : false }
            />

            <InstagramIC
                visable={ IG ? true : false }
                buttonSize={ buttonSizes ? buttonSizes : 'small' }
                iconSize={ iconSizes ? iconSizes : 'small' }
                color={ color ? color : 'inherit' }
                link={ instagram }
                doClick={ ( v ) => window.open( v, '_blank' ) }
                disabled={ disableAll ? true : false }
            />

            <LinkedInIC
                visable={ LI ? true : false }
                buttonSize={ buttonSizes ? buttonSizes : 'small' }
                iconSize={ iconSizes ? iconSizes : 'small' }
                color={ color ? color : 'inherit' }
                link={ linkedin }
                doClick={ ( v ) => window.open( v, '_blank' ) }
                disabled={ disableAll ? true : false }
            />

            <YouTubeIC
                visable={ YT ? true : false }
                buttonSize={ buttonSizes ? buttonSizes : 'small' }
                iconSize={ iconSizes ? iconSizes : 'small' }
                color={ color ? color : 'inherit' }
                link={ youtube }
                doClick={ ( v ) => window.open( v, '_blank' ) }
                disabled={ disableAll ? true : false }
            />

            <GitHubIC
                visable={ GH ? true : false }
                buttonSize={ buttonSizes ? buttonSizes : 'small' }
                iconSize={ iconSizes ? iconSizes : 'small' }
                color={ color ? color : 'inherit' }
                link={ github }
                doClick={ ( v ) => window.open( v, '_blank' ) }
                disabled={ disableAll ? true : false }
            />

        </SocialsContainer>
    )
}