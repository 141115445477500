import React from 'react';
import { InputBase, Box, styled, alpha } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface AppBarSearchProps {
    inputPlacholder: string, 
    defaultValue: string,
    onChange: ( event: any ) => void,
}

const Search = styled( Box )(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha( theme.palette.common.white, 0.15 ),
    '&:hover': {
        backgroundColor: alpha( theme.palette.common.white, 0.25 ),
    },
    marginRight: theme.spacing( 1 ),
    marginLeft: 0,
    width: '100%',
    [ theme.breakpoints.up( 'mobile' ) ]: {
        marginLeft: theme.spacing( 2 ),
        width: 'auto',
    },
}))

const SearchIconWrapper = styled( Box )(({ theme }) => ({
    padding: theme.spacing( 0, 1 ),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}))

const StyledInputBase = styled( InputBase )(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        // vertical padding + font size from searchIcon
        paddingLeft: `calc( 1em + ${ theme.spacing( 4 ) })`,
        transition: theme.transitions.create( 'width' ),
        width: '20ch',
        fontSize: '0.8em'
    }

}))

export default function AppBarSearch({ ...props }: AppBarSearchProps ) {
    const { inputPlacholder, defaultValue, onChange } = props



    return (
        <Search sx={{}}>
            <SearchIconWrapper>
                <SearchIcon fontSize='small' />
            </SearchIconWrapper>
            <StyledInputBase
                size='small'
                defaultValue={ defaultValue }
                placeholder={ inputPlacholder }
                inputProps={{ 'aria-label': 'search' }}
                onChange={ onChange }
            />
        </Search>
    )
}