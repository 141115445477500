import React, { useState, useEffect } from "react";
import { SubmitEmail } from "./SubmitEmail";
import { useAppDispatch } from "../../redux/Store";
import { validateEmail } from "../../utils/commons";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutePaths, AppStatusCodes } from "../../constants/enums";
import { addAlert } from "../../redux/slicers/AppState";
import { resetPassword } from "../../redux/AppStateThunks";
import { mapErrorMessages } from "../../utils/validation";

// Error Maps
const userErrorMap = {
    email: ''
}
const mfaErrorMap = {
    mfaCode: ''
}
const passwordErrorMap = {
    newPasswd: '',
    confirmPasswd: ''
}


export default function PasswordReset() {
    const [ view, setView ] = useState< 'user' | 'mfa' | 'verify' | 'reset' | 'complete' >( 'user' )
    const [ currentStep, setCurrentStep ] = useState< number >( 0 )
    const [ username, setUsername ] = useState< string >( '' )
    // Errors
    const [ helperText, setHelperText ] = useState< string >( '' )
    const [ usernameError, setUsernameError ] = useState< boolean >( false )
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    

    useEffect(() => {
        switch( view ) {
            case 'user':
                setCurrentStep( 0 )
                break
            case 'mfa':
                setCurrentStep( 1 )
                break
            case 'verify':
                setCurrentStep( 1 )
                break
            case 'reset':
                setCurrentStep( 2 )
                break
            case 'complete':
                setCurrentStep( 3 )
                break
            default:
                setCurrentStep( 0 )
        }
    }, [ view ])

    async function onSubmitUsername() {
        if ( !validateEmail( username )) {
            setUsernameError( true )
            setHelperText( 'Please enter a valid email address' )
            return
        }
        setUsernameError( false )
        setHelperText( '' )
        setView( 'verify' )
        const { success, code, payload } = await dispatch( resetPassword({ email: username })).unwrap()
        if ( success && code === AppStatusCodes.JWT_VERIFICATION_REQUIRED ) {
            setView( 'mfa' )
            return
        }
        if ( !success && code === AppStatusCodes.USER_LOCKED_OUT ) {
            setUsernameError( true )
            setView( 'user' )
            setHelperText( 'User is locked out. Please contact support' )
            return
        }
        if ( !success && code === AppStatusCodes.USER_NOT_FOUND ) {
            setUsernameError( true )
            setView( 'user' )
            setHelperText( `Cannot reset password for ${ username }` )
            return
        }
        if ( !success && code === AppStatusCodes.DATA_VALIDATION_JOI ) {
            const errors: any = mapErrorMessages( payload, userErrorMap )
            setUsernameError( true )
            setView( 'user' )
            setHelperText( errors?.email )
            return
        }
        dispatch( addAlert({ id: Date.now(), message: 'An unexpected error occurred', type: 'error' }))
        setView( 'user' )
    }

    const reset = () => {
        setView( 'user' )
        setCurrentStep( 0 )
        setUsername( '' )
        setUsernameError( false )
        setHelperText( '' )
    }

    const onCancel = () => {
        reset()
        navigate( RoutePaths.LOGIN, { state: { from: location.pathname }, replace: true } )
    }
    
    return (
        <SubmitEmail 
            currentView={ view }
            currentStep={ currentStep }
            username={ username }
            helpText={ helperText }
            setUsername={ setUsername }
            submitUsername={ onSubmitUsername }
            onCancel={ onCancel }
            // Errors
            emailError={ usernameError }
        />
    )
}