import React from 'react';
import { Typography } from '@mui/material';
import { StyledContentHeader } from '../../components/styled';

interface ContentHeaderProps {
    Title: string;
    TabIndex: number;
    TabOneSubTitle: string;
    TabTwoSubTitle: string;
    TabThreeSubTitle: string;
}

export function ReimbursementsHeader({ ...props }: ContentHeaderProps ) {
    const { 
        Title, TabIndex, TabOneSubTitle, TabTwoSubTitle, TabThreeSubTitle 
    } = props

    let subtitle = ''
    switch( TabIndex ) {
        case 0:
            subtitle = TabOneSubTitle
            break;
        case 1:
            subtitle = TabTwoSubTitle
            break;
        case 2:
            subtitle = TabThreeSubTitle
            break;
        default:
            subtitle = '(Reimbursements)'
    }
    
    return (
        <StyledContentHeader>

            <Typography variant='h5'>
               { Title }
            </Typography>

            <Typography variant='body1'>
                { subtitle }
            </Typography>

        </StyledContentHeader>
    )
}