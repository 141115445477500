import React from "react";
import { Typography, Box, Stack, useTheme } from "@mui/material";
import { LoginFormContainer, SlideContainer, FadeContainer } from "../../components/styled";
import { UserEmailSubmissionForm } from "./UserForm";
import { StepperBlue, IndeterminateLoading } from "../../components/UI";
import { SuccessResponse } from "./ResponseDisplay";

interface PasswdResetProps {
    currentView: 'user' | 'mfa' | 'verify' | 'reset' | 'complete';
    currentStep: number;
    username: string;
    setUsername: ( v: string ) => void;
    helpText: string;
    submitUsername: () => void;
    onCancel: () => void;
    // Errors
    emailError: boolean;
}

export const SubmitEmail = ({ 
    currentView,
    currentStep,
    username,
    setUsername,
    helpText,
    submitUsername,
    onCancel,
    emailError
}: PasswdResetProps ) => {
    const theme = useTheme();
    const colors = theme.palette;
    const steps: Array< string > = [ 'Email', 'Multi-Factor Authentication', 'New Password' ]

    const handlekeyPress = ( event: React.KeyboardEvent ) => {
        if ( event.key === 'Enter' && currentView === 'user' ) {
            submitUsername()
        }
    }

    return (
        <LoginFormContainer className="desktop-passwd-reset">
            <Stack sx={{ width: '100%', height: '100%' }}>
                
                <FadeContainer>
                    <Typography variant="h3">
                        Welcome to <span style={{ color: colors.primary.main }}>AutoDash</span>
                    </Typography>
                    
                    <Box
                        style={{
                            alignContent: 'flex-start',
                            width: '100%',
                            paddingTop: '2rem'
                        }}
                    >
                        <Typography variant="h4">Effortless access, anywhere, anytime</Typography>
                    </Box>
                </FadeContainer>

                <StepperBlue steps={ steps } activeStep={ currentStep } viewport="desktop" />

                <SlideContainer>

                    <UserEmailSubmissionForm
                        currentView={ currentView }
                        error={ emailError }
                        helpText={ helpText }
                        username={ username }
                        setUsername={ setUsername }
                        onSubmit={ submitUsername }
                        onKeyDown={ handlekeyPress }
                        onCancel={ onCancel }
                        theme={ theme }
                    />

                    <IndeterminateLoading show={ currentView === 'verify' } />

                    <SuccessResponse
                        theme={ theme }
                        currentView={ currentView }
                    />

                </SlideContainer>
                
            </Stack>
        </LoginFormContainer>
    )
}