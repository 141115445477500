import React from "react"
import { Typography } from "@mui/material"

export const budgetHelpText = ( rawLabel: string ) => {
    const label = rawLabel.toLowerCase()
    
    const category: Array<{ label: string, explaination: React.ReactNode }> = [
        {
            label: 'fuel',
            explaination: <React.Fragment>
                <Typography variant="caption">
                    Purchasing fuel via Inside Edge fuel cards is a breeze.
                    <br />
                    With Ampol, BP and Shell cards available your never far away from an outlet.
                </Typography>
            </React.Fragment>
        },
        {
            label: 'registration',
            explaination: <React.Fragment>
                <Typography variant="caption">
                    Your annual registration and CTP renewals will be sent to you directly by the registry for payment by you.
                    <br />
                    Please use the reimbursement portal to upload your renewal and proof of payment.
                </Typography>
            </React.Fragment>
        },
        {
            label: 'insurance',
            explaination: <React.Fragment>
                <Typography variant="caption">
                    Your insurance budget is set at the start of the lease to reflect the expected cost of comprehensive insurance.
                    <br />
                    Where insurance is provided via Inside Edge, payments will be made from your lease account monthly.
                    <br />
                    You have the freedom to choose your own insurer with an annual premium that you pay before uploading to our reimbursement portal.
                </Typography>
            </React.Fragment>
        },
        {
            label: 'maintenance',
            explaination: <React.Fragment>
                <Typography variant="caption">
                    Servicing is available at your choice of dealership or independent workshop.
                    <br />
                    Our Smartfleet portal allows most repairers to submit approved invoices for payment directly by Inside Edge.  
                </Typography>
            </React.Fragment>
        },
        {
            label: 'car wash',
            explaination: <React.Fragment>
                <Typography variant="caption">
                    This budget is for the cleaning of your vehicle. 
                    <br />
                    You can use any car wash facility you like, just keep your receipt and upload it to the reimbursement portal.
                </Typography>
            </React.Fragment>
        },
        {
            label: 'ctp',
            explaination: <React.Fragment>
                <Typography variant="caption">
                    Depending where you live you may know this one as a "Green Slip".
                    <br />
                    In some Australian states this insurance is included in the registration costs, and others you may need to purchase it seperately.
                    <br />
                    Check with your local transport authority for more information.
                </Typography>
            </React.Fragment>
        },
        {
            label: 'other',
            explaination: <React.Fragment>
                <Typography variant="caption">
                    This is a miscellaneous budget for other claimable expenses not explicitly covered by other budgets.
                </Typography>
            </React.Fragment>
        },
        {
            label: 'tyres',
            explaination: <React.Fragment>
                <Typography variant="caption">
                    Replacement tyres and repairs are available from your choice of tyre retailer. 
                    <br />
                    Please direct your chosen tyre retailer to our Smartfleet portal for authorisation and direct payment by Inside Edge.  
                </Typography>
            </React.Fragment>
        },
        {
            label: 'roadside assistance',
            explaination: <React.Fragment>
                <Typography variant="caption">
                    For those of you who like to be prepared, this budget is for your roadside assistance membership.
                    <br />
                    Inside Edge offers roadside assistance to customers who have requested it on thier lease, if you are not sure if you have this please contact us.
                    <br />
                    Otherwise you can purchase your own through a provider of your choice.Keep your receipt and upload it to the reimbursement portal.
                </Typography>
            </React.Fragment>
        }
 
    ]

    const findItem = category.filter( item => item.label === label )    
    return findItem.length > 0 ? findItem[0].explaination : <Typography variant="caption"></Typography>
}