import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { toggleNavMenu } from '../../redux/slicers/AppState';
import { useAppSelector, useAppDispatch } from '../../redux/Store';
import { DrawerContextProvider } from '../../context/DrawerContext';
import { useMediaWidth } from '../../hooks';
import { RootContainer, OuterContainer, InnerContainer } from '../styled';
import { LoadingIndicator } from '../UI/LoadingIndicator';
import { SystemDialog, AppSnackbar, StepUpAuthentication } from '../UI';
import { 
    NavBarMobile,
    MobileNavigationContainer,
    BaseFooter,
    NavMenu,
    MainGrid, NavHeader
} from '../layout';


export function RootLayout() {
    const { appName, version, navMenu } = useAppSelector(( state ) => state.AppState )
    const [ viewport, setViewport ] = useState< string >( '100vh' )
    const dispatch = useAppDispatch()
    const width = useMediaWidth({ direction: 'down', breakPoint: 'laptop' })
    let navmenu = navMenu
    
    useEffect(() => {
        const windowResize = () => {
            const viewportHeight = window.innerHeight;
            const vhUnits = viewportHeight * 0.01;
            setViewport( `${ vhUnits }px` )
        }
        windowResize()
        window.addEventListener( 'resize', windowResize )
        return () => window.removeEventListener( 'resize', windowResize )
    }, [])
    
    useEffect(() => {
        if ( width ) {
            dispatch( toggleNavMenu( false ))
        }
        if ( !width ) {
            dispatch( toggleNavMenu( true ))
        }
    }, [ width ])

    return (
        <RootContainer className='RootContainer'>
            <Helmet>
                <meta charSet='utf-8' />
                <link rel='preconnect' href='https://fonts.googleapis.com' />
                <link rel='stylesheet' href='https://fonts.googleapis.com/icon?family=Material+Icons' />
            </Helmet>
            
            <DrawerContextProvider>
                <OuterContainer className='OuterContainer' height={ viewport }> 
                    
                    <NavMenu />

                    <InnerContainer className='ContentContainer' navmenu={ navmenu }>
                        
                        <NavHeader />
                        
                        <MainGrid>
                            <Outlet />
                        </MainGrid>

                        <BaseFooter
                            appName={ appName }
                            version={ version }
                        />
                        
                    </InnerContainer>

                    <MobileNavigationContainer isMobileView={ width }>
                        <AppSnackbar />
                        <NavBarMobile />

                    </MobileNavigationContainer>

                </OuterContainer>
            </DrawerContextProvider>
            <StepUpAuthentication />
            <LoadingIndicator />
            <SystemDialog />
            <AppSnackbar />
        </RootContainer>
    )
}