import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { RegistrationForm } from "./Registration";
import { useAppDispatch } from "../../redux/Store";
import { validateEmail } from "../../utils/commons";
import { newRegistration } from "../../redux/AccessThunks";
import { toggleError } from "../../redux/slicers/AppState";
import { AppStatusCodes, ErrorCodes, RoutePaths } from "../../constants/enums";
import { IResults } from "../../constants/interfaces";
import { addAlert } from "../../redux/slicers/AppState";


export default function NewRegistrations() {
    const [ view, setView ] = useState< 'details' | 'mfa' | 'loading' | 'noMobile' >( 'details' );
    const [ currentStep, setCurrentStep ] = useState< number >( 0 );
    const [ email, setEmail ] = useState< string >( '' );
    const [ confirmEmail, setConfirmEmail ] = useState< string >( '' );
    // Errors
    const [ emailError, setEmailError ] = useState< boolean >( false );
    const [ confirmEmailError, setConfirmEmailError ] = useState< boolean >( false );
    const [ helperText, setHelperText ] = useState< string >( '' );
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        switch( view ) {
            case 'details':
                setCurrentStep( 0 )
                break
            case 'mfa':
                setCurrentStep( 1 )
                break
            case 'loading':
                setCurrentStep( 2 )
                break
            case 'noMobile':
                setCurrentStep( 2 )
                break
            default:
                setCurrentStep( 0 )
        }
    }, [ view ])

    async function onRegister() {
        try {
            reset();
            if (
                !email
                || !confirmEmail
                || email === '' 
                || confirmEmail === ''
            ) {
                setHelperText( 'Please enter an email address' );
                return;
            }
    
            if ( !validateEmail( email ) ) {
                setEmailError( true );
                setHelperText( 'Invalid email address' );
                return;
            }
            if ( email !== confirmEmail ) {
                setConfirmEmailError( true );
                setHelperText( 'Emails do not match' );
                return;
            }
    

    
            setView( 'loading' );
            const { success, code } = await dispatch( newRegistration({ email, confirmEmail }) ).unwrap()
            if ( !success && code !== AppStatusCodes.JWT_VERIFICATION_REQUIRED && code !== ErrorCodes.HTTP_REQUEST_TIMEOUT ) {
                setEmailError( true );
                setConfirmEmailError( true );
                setView( 'details' );
                setHelperText( 'Registration Failed. Please try again.' );
                dispatch( addAlert({ id: Date.now(),  message: 'Registration Failed', type: 'error' }) );
                return;
            }
            if ( success && code === AppStatusCodes.MFA_REQUIRED ) {
                dispatch( toggleError({ 
                    error: true,
                    errorMessage: 'I can see you have already verified your email with us. To finishing setting up your account we need to set a password for you. Before we do though, I have sent you a 6 digit code on your mobile. Please enter it on this page.' 
                }));
                navigate( RoutePaths.JWT_REGISTRATION_LANDING );
                return;
            }
            if ( code === AppStatusCodes.NO_MOBILE_PHONE_ASSOCIATED_WITH_USER ) {
                setView( 'noMobile' );
                dispatch( addAlert({ id: Date.now(),  message: 'Registration Failed', type: 'error' }) );
                return;
            }
            if ( code === AppStatusCodes.JWT_VERIFICATION_REQUIRED ) {
                setView( 'mfa' );
                return;
            }
            if ( code === ErrorCodes.HTTP_REQUEST_TIMEOUT ) {
                setView( 'details' );
                return;
            }
            setView( 'details' );
        } catch ( error: any ) {
            console.error( error );
        }
    }

    function reset() {
        setEmail( '' )
        setConfirmEmail( '' )
        setEmailError( false )
        setConfirmEmailError( false )
        setHelperText( '' )
    }

    const onCancel = () => {
        reset();
        navigate( RoutePaths.LOGIN, { state: { from: location.pathname }} );
    }

    return (
        <RegistrationForm 
            currentView={ view }
            currentStep={ currentStep }
            email={ email }
            confirmEmail={ confirmEmail }
            setEmail={ setEmail }
            emailError={ emailError }
            setConfirmEmail={ setConfirmEmail }
            confirmEmailError={ confirmEmailError }
            onRegister={ onRegister }
            onCancel={ onCancel }
            helpText={ helperText }
            theme={ theme }
        />
    )
}