import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/Store';
import { IProfileMenuItem } from '../../constants/interfaces';
import { RoutePaths } from '../../constants/enums';
import { Box, ListItemText, ListItemButton } from '@mui/material';
import type { Theme } from '@mui/material';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { userLogout } from '../../redux/AccessThunks';
import { logout } from '../../redux/slicers/Access';
import {
    ProfileMenuElement,
    ProfileMenuList,
    ProfileMenuListItem,
    ProfileMenuListItemIcon,
    ProfileMenuChip,
    ProfileMenuAvatar 
} from '../styled';
import {
    LogoutIcon,
    ManagePreferencesIcon,
    PersonIcon,
    DynamicNotificationIcon
} from '../UI/Icons';


interface IProfileMenuProps {
    theme: Theme;
    disabled?: boolean;
    chipLabel: string;
    fullName: string;
}

export function ProfileMenu({ ...props }: IProfileMenuProps ) {
    const { theme, disabled, chipLabel, fullName } = props
    const { notifications } = useAppSelector(( state ) => state.Access )
    const { Authenticated, setAuth } = useAuth()
    const [ anchorEl, setAnchorEl ] = useState< null | HTMLElement >( null )
    const dispatch = useAppDispatch()
    const location = useLocation
    const navigate = useNavigate()
    const open = Boolean( anchorEl )
    let isDarkMode = theme.palette.mode === 'dark';

    const handleMenuOpen = ( event: React.MouseEvent< HTMLElement, MouseEvent > ) => {
        if ( !disabled ) {
            setAnchorEl( event.currentTarget )
        }
    }

    const handleMenuClose = async ( path: string ) => { 
        if ( path === RoutePaths.LOGIN ) {
            setAuth( false )
            await dispatch( userLogout( null ) )
            dispatch( logout() )
        }
        navigate( path )
        setAnchorEl( null )
    }

    const ProfileMenuItems: Array< IProfileMenuItem > = [
        {
            icon: DynamicNotificationIcon({ outlined: true, notifications: notifications, size: 'small', notificationsNoneColor: isDarkMode ? 'info' : 'primary', notificationsColor: 'warning' }),
            label: 'Notifications (Coming Soon)',
            path: RoutePaths.NOTIFICATIONS
        },
        {
            icon: PersonIcon({ outlined: false, size: 'small', color: isDarkMode ? 'info' : 'primary' }),
            label: 'Profile',
            path: RoutePaths.PROFILE
        },
        {
            icon: ManagePreferencesIcon({ outlined: false, size: 'small', color: isDarkMode ? 'info' : 'primary' }),
            label: 'Preferences (Coming Soon)',
            path: RoutePaths.PREFERENCES
        },
        {
            icon: LogoutIcon({ outlined: false, size: 'small', color: isDarkMode ? 'info' : 'primary' }),
            label: 'Logout',
            path: RoutePaths.LOGIN
        }
    ] 

    return (
        <div>
            <Box
                aria-haspopup="true"
                onClick={ handleMenuOpen }
                id="basic-button"
                aria-controls={ open ? 'basic-menu' : undefined }
                aria-expanded={ open ? 'true' : undefined }
                sx={{ cursor: 'pointer' }}
            >
                <ProfileMenuChip
                    id='profile-chip'
                    label={ chipLabel }
                    avatar={ <ProfileMenuAvatar name={ fullName } /> }
                />
            </Box>
            <ProfileMenuElement
                id='basic-menu'
                anchorEl={ anchorEl }
                open={ open }
                onClose={ () => setAnchorEl( null ) }
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <ProfileMenuList>
                { ProfileMenuItems.map(( item: IProfileMenuItem ) => (
                    <ProfileMenuListItem key={ item.path }>
                        <ListItemButton 
                            disabled={ item.path === RoutePaths.PREFERENCES || item.path === RoutePaths.NOTIFICATIONS } 
                            onClick={ () => handleMenuClose( item.path ) }
                            sx={{ cursor: 'pointer' }}
                        >
                            <ProfileMenuListItemIcon>
                                { item.icon }
                            </ProfileMenuListItemIcon>
                            <ListItemText primary={ item.label } />
                        </ListItemButton>
                    </ProfileMenuListItem>
                ))}
                </ProfileMenuList>
            </ProfileMenuElement>
        </div>
    )
}



