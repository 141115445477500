import React, { createContext, useState, useMemo, ReactNode } from 'react';
import { useAppDispatch } from '../redux/Store';
import { updateTheme } from '../redux/slicers/AppState';
import GenerateTheme from './GenerateTheme';
import { Theme, ThemeProvider, CssBaseline } from '@mui/material';
import { PaletteMode } from '@mui/material';

export * from './ColorTokens'

interface ThemeContextProps {
    children: ReactNode
}

// context for color mode
export const ColorModeContext = createContext({
    toggleColorMode: () => {}
});

export const useMode = () => {
    const settingsExist = localStorage.getItem( 'appSettings' );
    const restoreTheme = settingsExist ? JSON.parse( settingsExist ).appTheme : 'light';
    const [ mode, setMode ] =  useState< PaletteMode >( restoreTheme );
    const dispatch = useAppDispatch();

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode(( prev ) => ( prev === 'light' ? 'dark' : 'light' )),
                dispatch( updateTheme( mode === 'light' ? 'dark' : 'light' ))
            }
        }), 
        [ mode ]
    );

    const theme: Theme = useMemo(() => GenerateTheme( mode ), [ mode ]);

    return [ colorMode, theme, mode ] as const;
};

export function ThemeContextProvider({ children }: ThemeContextProps ) {
    const [ colorMode, theme ] = useMode();

    return (
        <ColorModeContext.Provider value={ colorMode }>
            <ThemeProvider theme={ theme }>
                <CssBaseline enableColorScheme />
                    { children }
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}