import React, { useEffect, useState } from 'react';
import { Grid, Box, useTheme, useMediaQuery, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/Store';
import { getReimbursementHistory, requestFileDownload } from '../../../redux/AccessThunks';
import { addAlert } from '../../../redux/slicers/AppState';
import { columnBase } from '../../../constants';
import { IReimbHistoryItem, IFile } from '../../../constants/interfaces';
import { GridColDef  } from '@mui/x-data-grid-premium';
import { MUX_DataGrid } from '../../../components/UI/DataGrid';
import { ViewAttachmentsDialog } from '../ViewAttachmentsDialog';
import { fileDownload } from '../../../utils/commons';
import { columnHeaderFormating, cellValueFormating, currencyFormatter } from '../../../components/commonUIF';
import { formatDateTimeUTC_TZ } from '../../../utils/commons';
import { DownloadIcon } from '../../../components/UI/Icons';
import { BasicButton, ToolTipWrapper } from '../../../components/UI';


export function ReimbursementHistory() {
    const { userDetails } = useAppSelector( state => state.Access );
    const reimbursementHistory = useAppSelector( state => state.Access.reimbursementHistory );
    const [ viewAttachments, setViewAttachments ] = useState< boolean >( false );
    const [ attachments, setAttachments ] = useState< Array< IFile >>( [] );
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const isXsMobile = useMediaQuery( theme.breakpoints.only( 'xsmobile' ) );
    const isMobile = useMediaQuery( theme.breakpoints.only( 'mobile' ) );
    const isTablet = useMediaQuery( theme.breakpoints.only( 'tablet' ) );
    const isLaptop = useMediaQuery( theme.breakpoints.only( 'laptop' ) );
    const isDesktop = useMediaQuery( theme.breakpoints.only( 'desktop' ) );
    const mobile = isMobile === true || isXsMobile === true ? true : false;
    const isPortrait = Boolean( useMediaQuery( '(orientation: portrait)' ) );
    
    useEffect(() => {
        if ( reimbursementHistory.length === 0 ) {
            ( async () => {
                await dispatch( getReimbursementHistory( userDetails.ContactID ) );
            })();
        }
    }, []);

    async function onDownload( fileId: number, fileName: string, fileExtension: string ) {
        if ( !fileId ) dispatch( addAlert({ id: Date.now(), message: 'Cannot download this file', type: 'error' }) )
        
        const { success, code, payload } = await dispatch( requestFileDownload({ fileId: fileId, fileType: 1 }) ).unwrap();
        if ( !success ) {
            dispatch( addAlert({ id: Date.now(), message: 'Error downloading file', type: 'error' }) )
        } else {
            const data = payload.FileData
            const download = fileDownload( data, fileName, fileExtension );
            if ( download instanceof Error ) {
                dispatch( addAlert({ id: Date.now(), message: 'Error occurred downloading file', type: 'error' }) )
            } else {
                dispatch( addAlert({ id: Date.now(), message: 'File Downloaded Successfully', type: 'success' }) )
            }
        }
    }

    function onViewAttachments( id: number ) {
        console.log( id )
        const findAttachments = reimbursementHistory?.filter(( item ) => item.ReimbID === id ) || [];
        const attachments: Array< IFile > = findAttachments[ 0 ].FileAttachments || [];
        switch ( attachments.length ) {
            case 0:
                dispatch( addAlert({ id: Date.now(), message: 'No attachments found', type: 'warning' }));
                break;
            case 1:
                console.log( attachments[ 0 ] )
                onDownload( 
                    attachments[ 0 ].FileID, 
                    `${ attachments[ 0 ].FileID }_${ attachments[ 0 ].FileNameOnly }`, 
                    attachments[ 0 ].FileExtension
                );
                break;
            default:
                setAttachments( attachments );
                setViewAttachments( true )
            ;
        }
    }

    const columns: Array< GridColDef > = [
        {
            field: 'ReimbID',
            renderHeader: () => columnHeaderFormating( 'Claim ID' ),
            width: 90,
            sortable: false,
            resizable: false,
            renderCell: ( params ) => {
                if ( params.value === undefined || !params.value ) return ''
                return cellValueFormating( params.value )
            }
        },
        {
            field: 'BenefitDesc',
            headerName: 'Package',
            renderHeader: () => columnHeaderFormating( 'Package' ),
            width: 150,
            sortable: false,
            resizable: false,
            renderCell: ( params ) => {
                if ( params.value === undefined || !params.value ) return ''
                return <ToolTipWrapper
                            title={ params.value as string }
                            placement="left-start"
                            TransitionType='fade'
                        >
                            { cellValueFormating( params.value ) }
                        </ToolTipWrapper>
            }
        },
        {
            field: 'CostTypeName',
            headerName: 'Category',
            renderHeader: () => columnHeaderFormating( 'Category' ),
            width: mobile ? 80 : 100,
            sortable: false,
            resizable: false,
            renderCell: ( params ) => {
                if ( params.value === undefined || !params.value ) return ''
                return <ToolTipWrapper
                            title={ params.value as string }
                            placement="left-start"
                            TransitionType='fade'
                        >
                            { cellValueFormating( params.value ) }
                        </ToolTipWrapper>
            }
        },
        {
            field: 'SubmittedDate',
            renderHeader: () => columnHeaderFormating( 'Submited Date' ),
            width: 100,
            sortable: false,
            resizable: false,
            valueFormatter: ( value ) => formatDateTimeUTC_TZ( value, true ),
            renderCell: ( params ) => {
                if ( params.formattedValue === undefined || !params.value ) return ''
                return cellValueFormating( params.formattedValue )
            }
        },
        {
            field: 'PurchaseDate',
            renderHeader: () => columnHeaderFormating( 'Invoice Date' ),
            width: 100,
            sortable: false,
            resizable: false,
            valueFormatter: ( value ) => formatDateTimeUTC_TZ( value, true ),
            renderCell: ( params ) => {
                if ( params.formattedValue === undefined || !params.value ) return ''
                return cellValueFormating( params.formattedValue )
            }
        },
        {
            field: 'SupplierName',
            renderHeader: () => columnHeaderFormating( 'Supplier' ),
            width: 100,
            sortable: false,
            resizable: false,
            renderCell: ( params ) => {
                if ( params.value === undefined || !params.value ) return ''
                return <ToolTipWrapper
                            title={ params.value as string }
                            placement="left-start"
                            TransitionType='fade'
                        >
                            { cellValueFormating( params.value ) }
                        </ToolTipWrapper>
            }
        },
        {
            field: 'AmountIncTax',
            renderHeader: () => columnHeaderFormating( 'Amount (inc GST)' ),
            width: !mobile ? 120 : 80,
            sortable: false,
            resizable: false,
            align: 'right',
            valueFormatter: ( value ) => currencyFormatter.format( value ),
            renderCell: ( params ) => {
                if ( params.formattedValue === undefined || !params.value ) return ''
                return cellValueFormating( params.formattedValue )
            }
        },
        {
            field: 'AmountTax',
            renderHeader: () => columnHeaderFormating( 'GST Amount' ),
            width: 100,
            sortable: false,
            resizable: false,
            align: 'right',
            valueFormatter: ( value ) => currencyFormatter.format( value ),
            renderCell: ( params ) => {
                if ( params.value === undefined ) return ''
                if ( !params.value ) return cellValueFormating( currencyFormatter.format( 0 ) )
                return cellValueFormating( params.formattedValue )
            }
        },
        {
            field: 'SubmissionComment',
            renderHeader: () => columnHeaderFormating( 'Submission Comment' ),
            width: 200,
            sortable: false,
            resizable: false,
            renderCell: ( params ) => {
                if ( params.value === undefined ) return ''
                if ( !params.value ) return cellValueFormating( 'No comment' )
                return <ToolTipWrapper
                            title={ params.value as string }
                            placement="left-start"
                            TransitionType='fade'
                        >
                            { cellValueFormating( params.value ) }
                        </ToolTipWrapper>
            }
        },
        {
            field: 'Status',
            renderHeader: () => columnHeaderFormating( 'Status' ),
            width: !mobile ? 100 : 70,
            sortable: false,
            resizable: false,
            renderCell: ( params ) => {
                if ( params.value === undefined || !params.value ) return ''
                return cellValueFormating( params.value )
            }
        },
        {
            field: 'LastActionDate',
            renderHeader: () => columnHeaderFormating( 'Last Actioned' ),
            width: 150,
            sortable: false,
            resizable: false,
            valueFormatter: ( value ) => formatDateTimeUTC_TZ( value, true ),
            renderCell: ( params ) => {
                if ( params.formattedValue === undefined || !params.value ) return ''
                return cellValueFormating( params.formattedValue )
            }
        },
        {
            field: 'ActionReason',
            renderHeader: () => columnHeaderFormating( 'Reason' ),
            sortable: false,
            renderCell: ( params ) => {
                if ( params.value === undefined || !params.value ) return ''
                return <ToolTipWrapper
                            title={ params.value as string }
                            placement="left-start"
                            TransitionType='fade'
                        >
                            { cellValueFormating( params.value ) }
                        </ToolTipWrapper>
            }
        },
        {
            field: 'FileAttachments',
            renderHeader: () => columnHeaderFormating( 'Attachments' ),
            headerAlign: 'center',
            sortable: false,
            resizable: false,
            flex: 1,
            minWidth: !mobile ? 100 : 50,
            renderCell: ( params ) => {
                if ( !params.row.FileAttachments || params.row.FileAttachments.length === 0 ) return ''
                return (
                    <Box
                        sx={{ 
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%'
                        }}
                    >
                        { 
                            !mobile && <BasicButton
                                variant='outlined'
                                color={ isDarkMode ? 'info' : 'primary' }
                                text="View"
                                size='small'
                                onClickHandler={ () => onViewAttachments( params.row.ReimbID as number ) }
                            />
                        }
                        {
                            mobile && <IconButton
                                onClick={ () => onViewAttachments( params.row.ReimbID as number ) }
                            >
                                <DownloadIcon color='primary' size="medium" outlined={ isDarkMode } />
                            </IconButton>
                        }
                    </Box>
                )
            }
        }
    ]

    const visibleColumns = responsiveColumns( isPortrait, isXsMobile, isMobile, isTablet, isLaptop, isDesktop, columns );

    function onViewAttachmentsClose() {
        setViewAttachments( false );
        setAttachments( [] );
    }

    return (
        <Box sx={{ width: '100%', maxWidth: '100%' }}>
            <Grid container
                flexDirection='column'
                justifyContent='center' 
                alignItems='flex-start' 
                columns={ columnBase }
                spacing={ 1 }
                p={ 1 }
            >
                <Grid item
                    width='100%'
                    xsmobile={ 4 } mobile={ 6 } tablet={ 8 } laptop={ 10 } desktop={ 12 }
                >
                    <MUX_DataGrid
                        disableColumnMenus
                        disableColumnSelector
                        rows={ reimbursementHistory }
                        columns={ visibleColumns }
                        rowId={ ( row: IReimbHistoryItem ) => row.ReimbID }
                        hideFooter={ false }
                        includeInitialState={ true }
                        includeGroupingColDef={ false }
                        initialState={{
                            pagination: { paginationModel: { pageSize: 20 } }
                        }}
                        pagination={ true }
                        pageSizeOptions={ [ 20, 40, 50 ] }
                    />
                </Grid>
            </Grid>
            <ViewAttachmentsDialog
                show={ viewAttachments }
                attachments={ attachments }
                onClick={ ( data ) => onDownload( data.FileID, data.FileName, data.Ext ) }
                close={ onViewAttachmentsClose }
            />
        </Box>
    )
}

function responsiveColumns( isPortrait: boolean, isXsMobile: boolean, isMobile: boolean, isTablet: boolean, isLaptop: boolean, isDesktop: boolean, columns: Array< GridColDef > ) {
    const xsMobPort = columns.filter( column => [ 'SubmittedDate', 'CostTypeName', 'AmountIncTax', 'FileAttachments' ].includes( column.field ) );
    const xsMobLand = columns.filter( column => [ 'CostTypeName', 'SubmittedDate', 'AmountIncTax', 'Status', 'FileAttachments' ].includes( column.field ) );

    const mobPort = columns.filter( column => [ 'BenefitDesc', 'CostTypeName', 'SubmittedDate', 'AmountIncTax', 'Status', 'FileAttachments' ].includes( column.field ) );
    const mobLand = columns.filter( column => [ 'BenefitDesc', 'CostTypeName', 'SubmittedDate', 'SupplierName', 'AmountIncTax', 'Status', 'FileAttachments' ].includes( column.field ) );

    const tabPort = columns.filter( column => [ 'BenefitDesc', 'CostTypeName', 'SubmittedDate', 'SupplierName', 'AmountIncTax', 'Status', 'FileAttachments' ].includes( column.field ) );
    const tabLand = columns.filter( column => [ 'BenefitDesc', 'CostTypeName', 'SubmittedDate', 'SupplierName', 'AmountIncTax', 'Status', 'FileAttachments' ].includes( column.field ) );

    switch ( true ) {
        case isXsMobile:
            return isPortrait ? xsMobPort : xsMobLand;
        case isMobile:
            return isPortrait ? mobPort : mobLand;
        case isTablet:
            return isPortrait ? tabPort : tabLand;
        case isLaptop:
            return columns.filter( column => [ 'ReimbID', 'BenefitDesc', 'CostTypeName', 'SubmittedDate', 'PurchaseDate', 'SupplierName', 'AmountIncTax', 'AmountTax', 'Status', 'FileAttachments' ].includes( column.field ) );
        case isDesktop:
            return columns;
        default:
            return columns;
    }
}