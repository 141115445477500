import {
    styled,
    Tab,
    Tabs,
    Box
} from '@mui/material';

export const TabsComponentContainer = styled( Box )(({ theme }) => ({
    width: '100%',
}));

export const TabHeaderContainer = styled( Box )(({ theme }) => ({
    borderBottom: 1,
    borderColor: 'divider'
}));

export const TabHeader = styled( Tabs )(({ theme }) => ({
    ariaLabel: 'basic-tabs-component'
}));

export const TabTitle = styled( Tab )(({ theme }) => ({
    typography: 'tabHeadings',
}));

export const TabContentContainer = styled( 'div' )<{ index: number, value: number }>(({ theme, index, value }) => ({
    flexGrow: 1,
    role: 'tabpanel',
    hidden: index !== value,
    id: `tabpanel-${ index }`,
    ariaLabelledby: `tab-${ index }`
}));

export const TabContent = styled( Box )(({ theme }) => ({
    flexGrow: 1,
    maxWidth: '100%'
}));