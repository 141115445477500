import { Button, styled } from "@mui/material";

export const RoundedButton = styled( Button )(({ theme }) => ({
    backgroundColor: theme.palette.common.elecBlue,
    color: '#FFF',
    borderRadius: '1rem',
    textTransform: 'none',
    size: 'large',
    '&:hover': {
        backgroundColor: theme.palette.common.skyCyan,
        color: theme.palette.common.elecBlue
    }
}))

export const TextButton = styled( Button )(({ theme }) => ({
    backgroundColor: 'none',
    color: theme.palette.common.elecBlue,
    borderRadius: '1rem',
    textTransform: 'none',
    size: 'large',
    '&:hover': {
        backgroundColor: 'none',
        color: theme.palette.common.skyCyan
    }
}))