import { PaletteMode } from '@mui/material';

export const tokens = ( mode: PaletteMode ) => ({
    ...( mode === 'dark' ? {
        elecBlue: {
            50:  "#E6F1FF",
            100: "#C9E0FF",
            200: "#ADCFFF",
            300: "#91BFFF",
            400: "#75AFFF",
            500: "#8AB3FF",  // Base Color: #8AB3FF (Dark Mode Blue)
            600: "#6396E0",
            700: "#4D7ABD",
            800: "#395F9B",
            900: "#24427A"
        },
        softLight: {
            50:  "#F4FBFB",
            100: "#DAF4F4",
            200: "#C2ECEC",
            300: "#AADDDD",
            400: "#91CCCC",
            500: "#C0E0E0",  // Base Color: #C0E0E0 (Dark Mode Light Cyan)
            600: "#A6BFC0",
            700: "#8B9F9F",
            800: "#6F7F7F",
            900: "#4A5555"
        },
        skyCyan: {
            50:  "#EBFCFF",
            100: "#CAF8FF",
            200: "#A9F3FF",
            300: "#88EFFF",
            400: "#67EBFF",
            500: "#7FE6FF",  // Base Color: #7FE6FF (Dark Mode Cyan)
            600: "#66C1E0",
            700: "#5096BD",
            800: "#3A6B9B",
            900: "#22407A" 
        },
        sunflare: {
            50:  "#FFF2DD",
            100: "#FFE1B3",
            200: "#FFD189",
            300: "#FFC160",
            400: "#FFB837",
            500: "#FFB347",  // Base Color: #FFB347 (Dark Mode Orange)
            600: "#E09641",
            700: "#BD7A3A",
            800: "#9A5F33",
            900: "#7A422A"
        },
        vortex: {
            50:  "#F2E6FA",
            100: "#E0C6F4",
            200: "#CEA7ED",
            300: "#BC87E6",
            400: "#AA68DF",
            500: "#B56FE0",  // Base Color: #B56FE0 (Dark Mode Purple)
            600: "#965BC0",
            700: "#77479F",
            800: "#58327E",
            900: "#3A1F5E"
        },
        ecoMint: {
            50:  "#E6FFE6",
            100: "#C2FFC2",
            200: "#99FF99",
            300: "#7FFF7F",
            400: "#66FF66",
            500: "#66FF66",  // Base Color: #66FF66 (Dark Mode Green)
            600: "#54D954",
            700: "#40B240",
            800: "#2E8B2E",
            900: "#1A631A"
        },
        vibeRed: {
            50:  "#FFE5E5",
            100: "#FFBABA",
            200: "#FF8E8E",
            300: "#FF7272",
            400: "#FF6666",
            500: "#FF7979",  // Base Color: #FF7979 (Dark Mode Red)
            600: "#E06B6B",
            700: "#C05B5B",
            800: "#A04C4C",
            900: "#703636"
        },
        carbon: {
            50:  "#FFFFFF",
            100: "#F8F8F8",
            200: "#EDEDED",
            300: "#E0E0E0",
            400: "#D1D1D1",
            500: "#E0E0E0",  // Base Color: #E0E0E0 (Dark Mode Light Gray)
            600: "#BEBEBE",
            700: "#9C9C9C",
            800: "#7A7A7A",
            900: "#585858"
        }
    } : {
        elecBlue: {
            50:  "#E3E8FF",
            100: "#B9C4FF",
            200: "#91A2FF",
            300: "#6D80FF",
            400: "#5267FF",
            500: "#053CFF",  // Accent Colour -------- Electric Blue
            600: "#0037E0",
            700: "#0031B8",
            800: "#002790",
            900: "#001860"   
        },
        softLight: {
            50:  "#FFFFFF", 
            100: "#FCFDFD",
            200: "#F7FBFB",
            300: "#F3F8F8",
            400: "#F1F7F7",
            500: "#F0FAFA",  // Accent Colour -------- Soft Light
            600: "#D9E6E6",
            700: "#B5C7C7",
            800: "#8EA6A6",
            900: "#5E7A7A" 
        },
        skyCyan: {
            50:  "#E4FAFF",
            100: "#B8F2FF",
            200: "#8AEAFF",
            300: "#5DE2FF",
            400: "#38DCFF",
            500: "#19D2FF",  // Accent Colour -------- Skyline Cyan
            600: "#16BADF",
            700: "#12A3BF",
            800: "#0F8B9F",
            900: "#0A6170" 
        },
        sunflare: {
            50:  "#FFEEDD",
            100: "#FFD5AA",
            200: "#FFBD77",
            300: "#FFA644",
            400: "#FF941A",
            500: "#FF9400",  // Accent Colour -------- Sunflare
            600: "#E68200",
            700: "#CC7000",
            800: "#B35E00",
            900: "#804300"
        },
        vortex: {
            50:  "#F3E3F9",
            100: "#E1B8F0",
            200: "#CF8CE6",
            300: "#BC60DC",
            400: "#AE3BD3",
            500: "#8C28BE",  // Accent Colour -------- Violet Vortex
            600: "#7B22A8",
            700: "#681C91",
            800: "#54157A",
            900: "#3B0E55"
        },
        ecoMint: {
            50:  "#E5FFE5",
            100: "#B8FFB8",
            200: "#8CFF8C",
            300: "#60FF60",
            400: "#3BFF3B",
            500: "#00E100",  // Accent Colour -------- Eco Mint
            600: "#00C700",
            700: "#00A000",
            800: "#007800",
            900: "#004E00"
        },
        vibeRed: {
            50:  "#FFE5E5", 
            100: "#FFB8B8",
            200: "#FF8A8A",
            300: "#FF5C5C",
            400: "#FF3434",
            500: "#FF4C4C",  // Vibrant Red
            600: "#E04444",
            700: "#C03C3C",
            800: "#A03333",
            900: "#702323"  
        },
        carbon: {
            50:  "#E6E6E6",
            100: "#BFBFBF",
            200: "#999999",
            300: "#737373",
            400: "#4D4D4D",
            500: "#161616",  // Carbon
            600: "#141414",
            700: "#101010",
            800: "#0D0D0D",
            900: "#0A0A0A"
        }
    })
})