import React, { useMemo, useState } from 'react';
import { LinearProgress, lighten, alpha, useTheme } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../../redux/Store';
import { DataGridPremium, GridColDef, GridToolbar, useGridApiRef, GridAggregationModel, GridRowSelectionModel  } from '@mui/x-data-grid-premium';

type MUX_DataGridProps = {
    columns: Array< GridColDef >; // Import the "GridColDef" from '@mui/x-data-grid-premium' on the component passing the prop
    rows: Array<any>;
    rowId: ( row: any ) => any;
    hideFooter?: boolean;
    pagination?: boolean;
    disableColumnSelector?: boolean;
    disableColumnMenus?: boolean;
    // includeAggregation: boolean;
    // aggregationModel?: GridAggregationModel;
    includeGroupingColDef: boolean;
    groupingColDef?: any;
    includeInitialState: boolean;
    initialState?: object;
    loading?: boolean;
    // selectedRows?: ( v: Array< any > ) => any;
    pageSizeOptions?: Array<number>;
    // fullGridToolBar?: boolean;
    // customToolBar?: any;
    // slotProps?: {},
    autoPageSize?: boolean;
    rowHeight?: number;
}

// const useStyles = makeStyles({
//     pointerCursor: {
//         '& .MuiDataGrid-row': {
//             cursor: 'pointer',
//         },
//     } 
// });

export function MUX_DataGrid( props: MUX_DataGridProps ) {
    const {
        columns,
        rows,
        rowId,
        disableColumnSelector,
        disableColumnMenus,
        // includeAggregation,
        // aggregationModel,
        includeGroupingColDef,
        groupingColDef,
        includeInitialState,
        initialState,
        hideFooter,
        loading,
        // selectedRows,
        pageSizeOptions,
        // fullGridToolBar,
        // customToolBar,
        // slotProps,
        pagination,
        autoPageSize,
        rowHeight,
        // onColumnOrderChange,
        // columnVisibilityModel,
        // onColumnVisibilityChange
    } = props;
    const { processing } = useAppSelector(( state ) => state.AppState )
    // const classes = useStyles();
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';
    

    return (

        <DataGridPremium
            // className={ classes.pointerCursor }
            // loading={ loading || processing }
            
            columns={ columns }
            disableColumnSelector={ disableColumnSelector ? true : false }
            disableColumnMenu={ disableColumnMenus ? true : false }
            rows={ rows }
            getRowId={ rowId }
            rowHeight={ rowHeight ? rowHeight : 30 }
            hideFooter={ hideFooter ? true : false }
            // aggregationModel={ includeAggregation ? aggregationModel : {} }
            groupingColDef={ includeGroupingColDef ? groupingColDef : undefined }
            initialState={ includeInitialState ? { ...initialState } : {} }
            pagination={ pagination ? true : false }
            paginationMode='client'
            autoPageSize={ autoPageSize ? true : false }
            pageSizeOptions={ pageSizeOptions ? pageSizeOptions : [] }
            // onRowSelectionModelChange={
            //     selectedRows ?
            //     ( rowSelectionModel: GridRowSelectionModel ) => selectedRows( rowSelectionModel )
            //     : null 
            // }
            // slots={{ 
            //     toolbar: fullGridToolBar ? GridToolbar : customToolBar ? customToolBar : null, 
            //     loadingOverlay: LinearProgress  
            // }}
            // slotProps={
            //     slotProps ? { ...slotProps } : {}
            // }

            // onColumnOrderChange={ onColumnOrderChange ? onColumnOrderChange : null }
            // columnVisibilityModel={ columnVisibilityModel ? columnVisibilityModel : {} }
            // onColumnVisibilityModelChange={ onColumnVisibilityChange ? onColumnVisibilityChange : null }
            sx={{
                backgroundColor: isDark ? theme.palette.overlays.level1 : theme.palette.background.paper,
                '& .MuiDataGrid-columnHeader': {
                    backgroundColor: isDark ? '' : theme.palette.background.paper,
                    backgroundImage: isDark ? `
                        linear-gradient( ${ theme.palette.overlays.level1 }, ${ theme.palette.overlays.level1 } ),
                        linear-gradient( ${ theme.palette.overlays.level2 }, ${ theme.palette.overlays.level2 } ),
                        linear-gradient( ${ theme.palette.background.backgroundOverlay }, ${ theme.palette.background.backgroundOverlay } )
                    `
                    : 'none',
                    whiteSpace: 'normal', // Allow text to wrap
                    overflow: 'visible', // Ensure the text is fully visible
                    lineHeight: '1.2', // Adjust line height for better readability (optional)
                    paddingLeft: '0.3rem',
                    paddingRight: '0.3rem',
                },
                '& .MuiDataGrid-filler': {
                    backgroundColor: isDark ? '' : theme.palette.background.paper,
                    backgroundImage: isDark ? `
                        linear-gradient( ${ theme.palette.overlays.level1 }, ${ theme.palette.overlays.level1 } ),
                        linear-gradient( ${ theme.palette.overlays.level2 }, ${ theme.palette.overlays.level2 } ),
                        linear-gradient( ${ theme.palette.background.backgroundOverlay }, ${ theme.palette.background.backgroundOverlay } )
                    `
                    : 'none',
                },
                '& .MuiDataGrid-cell': {
                    paddingLeft: '0.3rem',
                    paddingRight: '0.3rem',
                }
            }}
        />

    )
}