import React, { useState, useEffect } from 'react';
import {
    BasicSelectContainer,
    BasicSelectLabel,
    BasicSelect,
    BasicSelectMenuOption,
    MenuStyles
} from '../styled';
import { FormControl, FormHelperText, Typography, useTheme } from '@mui/material';

export interface BasicSelectOption {
    key: any;
    label: string;
    value: any;
}

interface BasicDropDownMenuProps {
    menuLabel: string;
    selectID: string;
    size: 'small' | 'medium';
    options: Array< BasicSelectOption >;
    defaultOption?: BasicSelectOption;
    value?: any;
    disabled?: boolean;
    error?: boolean;
    helpText?: string;
    onChange: ( value: any ) => void;
}

export function BasicDropDownMenu({ ...props }: BasicDropDownMenuProps ) {
    const { options, onChange, selectID, menuLabel, size, defaultOption, value, error, helpText } = props;
    const [ selectedValue, setSelectValue ] = useState< any >( value );
    const theme = useTheme();
    const isDark: boolean = theme.palette.mode === 'dark';

    useEffect(() => {
        if ( value !== selectedValue ) {
            setSelectValue( value );
        }
    }, [ value ]);

    let nonOption = { key: '0', label: 'Select an Option', value: 0 };
    const selectList = [ nonOption, ...options ];
    
    let handleSelection = ( value: any ) => {
        setSelectValue( value.target.value );
        onChange( value.target.value );
    }

    return (
        <BasicSelectContainer>
            <FormControl fullWidth disabled={ props.disabled || false } size={ size ? size : 'medium' }>
                <BasicSelectLabel
                    id={ `select-${ menuLabel }` }
                    disabled={ props.disabled || false }
                >
                    { menuLabel }
                </BasicSelectLabel>
                <BasicSelect
                    fullWidth
                    variant='standard'
                    labelId={ `select-${ selectID }-label` }
                    id={ selectID }
                    value={ selectedValue || '' }
                    onChange={ handleSelection }
                    size={ size ? size : 'medium' }
                    disabled={ props.disabled || false }
                    error={ error === true }
                    MenuProps={{
                        PaperProps: {
                            sx: MenuStyles( isDark, theme ).paper
                        }
                    }}
                >
                    { selectList.length > 0 && options.map(( option ) => (
                        <BasicSelectMenuOption key={ option.key } value={ option.value }>
                            <Typography variant='dataValue' >{ option.label }</Typography>
                        </BasicSelectMenuOption>
                    ))}
                    { options.length === 0 && 
                        <BasicSelectMenuOption value={ '' }>
                            <Typography variant='dataValue'>No Options</Typography>
                        </BasicSelectMenuOption> 
                    }
                </BasicSelect>
                { error && <FormHelperText error>{ helpText }</FormHelperText> }
            </FormControl>
        </BasicSelectContainer>
    );
}