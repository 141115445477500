import React from 'react';
import { DateTime } from 'luxon';
import { currentDate } from '../../utils/commons';
import { DateOnlySelector } from '../styled';
import { useTheme } from '@mui/material';
import { addAlert } from '../../redux/slicers/AppState';
import { useAppDispatch } from '../../redux/Store';


interface DateSelectorProps {
    label: string;
    value: any;
    useDefaultValue?: boolean;
    variant: 'outlined' | 'filled' | 'standard';
    size: 'small' | 'medium';
    disabled?: boolean;
    error: boolean;
    helpText?: string;
    onChange: ( date: any ) => void;
}

export function DateSelector({ ...props }: DateSelectorProps) {
    const { label, value, useDefaultValue, onChange, size, variant, error, helpText } = props;
    const dispatch = useAppDispatch();
    const theme = useTheme();
    let today = currentDate().startOf( 'day' );
    let now = currentDate();


    const handleChange = ( value: DateTime ) => {
        if ( value.isValid || value < today ) {
        const selectedDate = value.toISO(); // Convert Luxon DateTime to ISO 8601 date string
        onChange( selectedDate ); // Call the onChange function with the selected date
        } else {
            dispatch( addAlert({ id: Date.now(), message: 'Invalid or future date selected', type: 'warning' }) );
        }
    };

    return (
        <DateOnlySelector
            {...props }
            label={ label }
            format='dd/MM/yyyy'
            defaultValue={ useDefaultValue ? today : null }
            value={ value ? DateTime.fromISO( value ).toUTC() : now }
            onChange={ ( date ) => handleChange( date ? date : now ) }
            maxDate={ now }
            disabled={ props.disabled || false }
            slotProps={{
                textField: {
                    fullWidth: true,
                    variant: variant,
                    size: size,
                    disabled: props.disabled || false,
                    sx: {
                        minWidth: '100%',
                        border: 'none',
                        background: 'transparent',
                        outline: 'none',
                        '&:hover': {
                            borderBottom: `none`,
                        },
                        '& .MuiInput-underline:before': {
                            borderBottom: 'none !important', // Remove default underline
                            content: 'none',
                        },
                        '& .MuiInput-underline:after': {
                            borderBottom: 'none !important', // Remove the underline after the element
                        },
                        '& .MuiInputBase-root.Mui-focused:after': {
                            borderBottom: `none`,
                            backgroundColor: 'transparent',
                            outline: 'none'
                        },
                        '&.Mui-disabled': {
                            borderBottom: `none`,
                            color: theme.palette.text.disabled,
                            outline: 'none',
                            '&:hover': {
                                borderBottom: `none`,
                            }
                        },
                        '&:hover:not(.Mui-disabled):before': {
                            borderBottom: 'none !important'
                        }

                    }
                }
            }}
        />
    )
}