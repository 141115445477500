import React from "react";
import { SvgIcon, styled } from "@mui/material";

const CustomCheckCircle = ( props: any ) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            d="M12 2.5C6.76 2.5 2.5 6.76 2.5 12S6.76 21.5 12 21.5 21.5 17.24 21.5 12 17.24 2.5 12 2.5z"
            fill="none"
            stroke="#053CFF"
            strokeWidth="2"
        />
        <path
            d="M5.5 13.5L8.41 16.41L11 13.83L17.59 7.1" 
            fill="none"
            stroke="#00E100"
            strokeWidth="2"
            strokeDasharray="24"
            strokeDashoffset="24"
            className="tick-path"
        />
    </SvgIcon>
)

const styles ={
    '@keyframes draw': {
        '0%': {
            strokeDashoffset: '24'
        },
        '100%': {
            strokeDashoffset: '0'
        }
    },
    '.tick-path': {
        animation: 'draw 2s ease forwards'
    }
}

export const AnimatedCheckCircle = styled( CustomCheckCircle )( styles );

// These damn gradient simply will not work, have settled for the current setup for the time being
const AnimatedLoading = ( props: any ) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <defs>
            <linearGradient id="gradient1" x1="0" y1="0" x2="1" y2="1">
                <stop offset="0%" stopColor="#053CFF" />
                <stop offset="100%" stopColor="#19D2FF" />
            </linearGradient>
        </defs>
        <path
            d="M12 2.5C6.76 2.5 2.5 6.76 2.5 12S6.76 21.5 12 21.5 21.5 17.24 21.5 12 17.24 2.5 12 2.5z"
            fill="none"
            stroke="#053CFF"
            strokeWidth="2"
        />
        {/* <path
            d="M12 2.5C6.76 2.5 2.5 6.76 2.5 12S6.76 21.5 12 21.5 21.5 17.24 21.5 12 17.24 2.5 12 2.5z"
            fill="none"
            stroke="white"
            strokeWidth='2'
            strokeDasharray="62.8"  // Updated to the correct circumference
            strokeDashoffset="62.8"
            className="spinning-circle"
        /> */}
        <path
            d="M12 2.5C6.76 2.5 2.5 6.76 2.5 12S6.76 21.5 12 21.5 21.5 17.24 21.5 12 17.24 2.5 12 2.5z"
            fill="none"
            stroke="#19D2FF"
            strokeWidth="2"
            strokeDasharray="5, 57.8"  // Small dash for the marker
            strokeDashoffset="62.8"
            className="marker"
        />
    </SvgIcon>
)

const spinStyles = {
    '@keyframes draw-circle': {
        '0%': {
            strokeDashoffset: '62.8'
        },
        '50%': {
            strokeDashoffset: '31.4'
        },
        '100%': {
            strokeDashoffset: '0'
        },
    },
    '.spinning-circle': {
        animation: 'draw-circle 2s linear infinite'
    },
    '@keyframes move-marker': {
        '0%': {
            strokeDashoffset: '62.8'
        },
        '50%': {
            strokeDashoffset: '31.4'
        },
        '100%': {
            strokeDashoffset: '0'
        }
    },
    '.marker': {
        animation: 'move-marker 2s linear infinite'
    }
};

export const AnimatedLoadingCheckCircle = styled( AnimatedLoading )(({ theme }) => spinStyles );


/* 
            {/* <linearGradient id="gradient2" x1="0" y1="0" x2="1" y2="1">
                <stop offset="0%" stopColor="#1235ce" />
                <stop offset="15%" stopColor="#6699FF" />
                <stop offset="30%" stopColor="#bdd2fe" />
                <stop offset="75%" stopColor="#6699FF" />
                <stop offset="90%" stopColor="#1235ce" />
                <stop offset="100%" stopColor="#1235ce" />
            </linearGradient> 
*/
