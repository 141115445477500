import { IObjectKeyValues } from "../../constants/interfaces"
import { DataGroup, DataField } from "../../components/UI";


interface IVehicleDetailsProps {
    vehicleDetails: IObjectKeyValues;
    financeDetails: IObjectKeyValues;
    xsmobile?: number;
    mobile?: number;
    tablet?: number;
    laptop?: number;
    desktop?: number;
}

export const VehicleDetails = ({ 
    vehicleDetails,
    financeDetails,
    xsmobile = 4,
    mobile = 5,
    tablet = 4,
    laptop = 5,
    desktop = 6
}: IVehicleDetailsProps ) => (
    <DataGroup
        title='Vehicle Details'
        className='VehicleDetails'
        xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
    >

        <DataField
            data={ vehicleDetails.vin }
            xsmobile={ 2 } mobile={ 3 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
        />
        <DataField
            data={ vehicleDetails.engineNumber }
            xsmobile={ 2 } mobile={ 2 } tablet={ 2 } laptop={ 4 } desktop={ 4 }
        />
        <DataField
            data={ financeDetails.vehAmountFinancedExGST }
            xsmobile={ 2 } mobile={ 3 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
        />
        <DataField
            data={ financeDetails.residualValueExGST }
            xsmobile={ 2 } mobile={ 2 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
        />
        <DataField
            data={ financeDetails.residualGST }
            xsmobile={ 2 } mobile={ 3 } tablet={ 2 } laptop={ 4 } desktop={ 4 }
        />
        <DataField
            data={ financeDetails.residualValeIncGST }
            xsmobile={ 2 } mobile={ 2 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
        />
        <DataField
            data={ vehicleDetails.regoNum }
            xsmobile={ 2 } mobile={ 3 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
        />
        <DataField
            data={ vehicleDetails.regoState }
            xsmobile={ 2 } mobile={ 2 } tablet={ 2 } laptop={ 4 } desktop={ 4 }
        />
        <DataField
            data={ vehicleDetails.color }
            xsmobile={ 2 } mobile={ 3 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
        />
        
    </DataGroup>
)