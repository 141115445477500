import { stateAcronymConverter, currencyFormatter } from  "../../components/commonUIF";
import { formatDateTimeUTC_TZ } from "../../utils/commons";
import { IPackageDetails, IObjectKeyValues } from "../../constants/interfaces";

export function packageData( packageDetails: IPackageDetails | null ): { vehicleDetails: IObjectKeyValues, financeDetails: IObjectKeyValues } {

    const vehDetails: IObjectKeyValues = {
        vin: {
            key: 'vin',
            label: 'VIN Number',
            value: packageDetails?.VehicleVIN ? packageDetails.VehicleVIN : '(No Data)',
            isEdit: false
        },
        engineNumber: {
            key: 'engineNumber',
            label: 'Engine Number',
            value: packageDetails?.VehicleEngineNo ? packageDetails.VehicleEngineNo : '(No Data)',
            isEdit: false
        },
        color : {
            key: 'color',
            label: 'Color',
            value: packageDetails?.VehicleColour ? packageDetails.VehicleColour : '(No Data)',
            isEdit: false
        },
        regoNum: {
            key: 'rego',
            label: 'Rego Plate',
            value: packageDetails?.Rego ? packageDetails.Rego : '(No Data)',
            isEdit: false
        },
        regoState: {
            key: 'regoState',
            label: 'Rego State',
            value: packageDetails?.RegoStateCode ? stateAcronymConverter( packageDetails.RegoStateCode ) : '(No Data)',
            isEdit: false
        }
    }

    const finDtails: IObjectKeyValues = {
        financier: {
            key: 'financier',
            label: 'Financier',
            value: packageDetails?.FinancierName ? packageDetails.FinancierName : '(No Data)',
            isEdit: false
        },
        contractNumber: {
            key: 'contractNumber',
            label: 'Contract Number',
            value: packageDetails?.ContractNumber ? packageDetails.ContractNumber : '(No Data)',
            isEdit: false
        },
        startDate: {
            key: 'startDate',
            label: 'Start Date',
            value: packageDetails?.LeaseStartDate ? `${ formatDateTimeUTC_TZ( packageDetails.LeaseStartDate, true, false ) }` : '(No Data)',
            isEdit: false
        },
        endDate: {
            key: 'endDate',
            label: 'End Date',
            value: packageDetails?.LeaseEndDate ? `${ formatDateTimeUTC_TZ( packageDetails.LeaseEndDate, true, false ) }` : '(No Data)',
            isEdit: false
        },
        paymentExGST: {
            key: 'paymentExGST',
            label: 'Payment (Ex GST)',
            value: packageDetails?.LeasePaymentExclGST ? currencyFormatter.format( packageDetails.LeasePaymentExclGST ) : '(No Data)',
            isEdit: false
        },
        paymentGST: {
            key: 'paymentGST',
            label: 'GST',
            value: packageDetails?.LeasePaymentGSTAmt ? currencyFormatter.format( packageDetails.LeasePaymentGSTAmt ) : '(No Data)',
            isEdit: false
        },
        paymentIncGST: {
            key: 'paymentIncGST',
            label: 'Payment (Inc GST)',
            value: packageDetails?.LeasePaymentInclGST ? currencyFormatter.format( packageDetails.LeasePaymentInclGST ) : '(No Data)',
            isEdit: false
        },
        paymentFrequency: {
            key: 'paymentFrequency',
            label: 'Payment Frequency',
            value: packageDetails?.LeasePaymentFreqName ? packageDetails.LeasePaymentFreqName : '(No Data)',
            isEdit: false
        },
        term: {
            key: 'term',
            label: 'Term',
            value: packageDetails?.LeaseTerm ? `${ packageDetails.LeaseTerm }` : '(No Data)',
            isEdit: false
        },
        firstHeldEmployer: {
            key: 'FBTDateFirstHeldByEmployer',
            label: 'Lease First Held By Employer',
            value: packageDetails?.FBTDateFirstHeldByEmployer ? `${ formatDateTimeUTC_TZ( packageDetails.FBTDateFirstHeldByEmployer, true, false ) }` : '(No Data)',
            isEdit: false
        },
        vehAmountFinancedExGST: {
            key: 'vehAmountFinancedExGST',
            label: 'Vehicle Amount Financed (Ex GST)',
            value: packageDetails?.VehAmtFinancedExclGST ? currencyFormatter.format( packageDetails.VehAmtFinancedExclGST ) : '(No Data)',
            isEdit: false
        },
        residualValueExGST: {
            key: 'residualValueExGST',
            label: 'Residual Value (Ex GST)',
            value: packageDetails?.ResidualAmtExclGST ? currencyFormatter.format( packageDetails.ResidualAmtExclGST ) : '(No Data)',
            isEdit: false
        },
        residualGST: {
            key: 'residualGST',
            label: 'GST',
            value: packageDetails?.ResidualAmtGSTAmt ? currencyFormatter.format( packageDetails.ResidualAmtGSTAmt ) : '(No Data)',
            isEdit: false
        },
        residualValeIncGST: {
            key: 'residualValeIncGST',
            label: 'Residual Value (Inc GST)',
            value: packageDetails?.ResidualAmtInclGST ? currencyFormatter.format( packageDetails.ResidualAmtInclGST ) : '(No Data)',
            isEdit: false
        }
    }

    return {
        vehicleDetails: vehDetails,
        financeDetails: finDtails
    }
}