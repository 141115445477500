
export enum PhoneType {
    MOBILE = 515,
    HOME = 514,
    BUSINESS = 512,
    FAX = 513,
    BIZ_DIRECT = 2059
}

export enum EmailType {
    PERSONAL = 672,
    BUSINESS = 669,
    SECONDARY = 670,
    OTHER = 671
}

export enum AddressType {
    HOME = 841,
    MAIL = 2008,
    BUSINESS = 840,
    BILLING = 839
}

export enum ExpenseType {
	FUEL = 1,
	MAINTENANCE = 2,
	MANAGEMENT_FEE = 5,
	LEASE_PAYMENT = 13,
	GST_ON_CONTRIBUTION = 14,
	INSURANCE = 4,
	TYRES = 3,
	REGISTRATION = 6,
	CTP = 7,
	OTHER = 9,
	FEES = 39,
	CAR_WASH = 41,
	FINANCE_DOCUMENTATION = 47,
	CARBON_OFFSET = 42,
	TOLLS = 38,
	CONTINGENCY = 45,
	GAP_INSURANCE = 46,
	EXTENDED_WARRANTY = 40,
	LEASE_PROTECTION_INSURANCE = 43,
	ACCIDENT_MANAGEMENT = 34,
	ROADSIDE_ASSISTANCE = 33,
	VEHICLE_ACQUISITION = 44,
	FLEET_CARD = 8
}

export enum ReimbOperCostType {
    NONE = 0,
    FUEL = 1,
    MAINTENANCE = 2,
    TYRES = 3,
    INSURANCE = 4,
    MANAGEMENT_FEE = 5,
    REGISTRATION = 6,
    CTP = 7,
    FLEET_CARD = 8,
    OTHER = 9,
    PAYROLL_ALLOCATION = 10,
    LEASE_PAYMENT = 13,
    GST_ON_CONTRIBUTION = 14,
    RESIDUAL_PROTECTION_COVER = 32,
    ROADSIDE_ASSISTANCE = 33,
    ACCIDENT_MANAGEMENT = 34,
    TOLLS = 38,
    FEES = 39,
    EXTENDED_WARRANTY = 40,
    CAR_WASH = 41,
    CARBON_OFFSET = 42,
    LEASE_PROTECTION_INSURANCE = 43,
    VEHICLE_ACQUISITION = 44,
    CONTINGENCY = 45,
    GAP_INSURANCE = 46,
    FINANCE_DOCUMENTATION = 47,
    HOME_CHARGING_REIMBURSEMENT = 48,
    COMMERCIAL_CHARGING_REIMBURSEMENT = 49
}

export enum PreferredContactMethod {
    MOBILE = 1,
    EMAIL = 2,
    FAX = 3,
    SPOUSE = 4
}

export enum BankAccountType {
    SYS_RE = -1,
    EMPLOYER_PAYROLL_CLEARING = 1,
    EMPLOYEE_OPERATING = 2,
    EMPLOYER_GST_HOLD = 3,
    EMPLOYEE_FBT_HOLDINGS = 4,
    SUPPLIER_CLEARING = 5,
    EMPLOYEE_LUXNONDED_HOLDINGS = 6,
    EMPLOYER_BANK_ACCOUNT = 7,
    TRACKING_EMPLOYEE_PAYROLL = 8,
    COMPANY_CREDIT_CARD = 9,
    EMPLOYEE_PERSONAL_ACCOUNT = 10,
    EMPLOYER_UNDER_OVERS = 11,
    EMPLOYER_OPERATIONAL_ACCOUNT = 12,
    EMPLOYER_COMPANY_ACCOUNT = 13,
    COMPANY_FBT_HOLDING = 14,
    COMPANY_LUXNONDED_HOLDING = 15,
    AGENCY_FLOAT = 16,
    EMPLOYER_FBT_SUSPENSE = 17,
    EMPLOYER_LND_SUSPENSE = 18,
    ABA_TRACE_ACCOUNT = 19,
    OTHER_SALPACK_PROVIDER = 20
}

export enum ReimbStatus {
    PENDING = 1,
    PAID = 2,
    ON_HOLD = 3,
    DECLINED = 4
}

export enum ContactUsSubject {
    NONE = 0,
    ACCOUNT_ENQUIRY = 2000,
    FUEL_CARDS = 2010,
    REIMBURSEMENT = 2020,
    REFINANCE_VEHICLE = 2030,
    NEW_VEHICLE_ENQUIRY = 2040,
    MOVING_EMPLOYER = 2050,
    VEHICLE_PAYOUT = 2060,
    INSURANCE = 2070,
    COVID_19 = 2080,
    OTHER = 2090
}

export enum BankAccApprovalStatus {
    DECLINED = 0,
    PENDING_APPROVAL = 1,
    PARTIALLY_APPROVED = 2,
    FULLY_APPROVED = 4,
    APPROVAL_NOT_REQUIRED = 128,
    ACCOUNT_DELETED = 512
}

