import React, { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { ContactUsContent } from "../../components/styled";
import { ContactUsHeader } from "./ContactUsHeader"
import { ContactForm } from "../../components/UI";
import { useAppDispatch, useAppSelector } from "../../redux/Store";
import { lodgeContactRequest } from "../../redux/AppStateThunks";
import { addAlert } from "../../redux/slicers/AppState";
import { columnBase, ContactUsSubjectOptions } from "../../constants";
import { ContactRequest } from "../../constants/classes";


const defaultContactRequest: ContactRequest = new ContactRequest();

export default function ContactUs() {
    const { userDetails, basicPackageDetail } = useAppSelector(( state ) => state.Access );
    const [ contactRequest, setContactRequest ] = useState< ContactRequest >( defaultContactRequest );
    const [ errorLease, setErrorLease ] = useState< boolean >( false );
    const [ errorEnqCat, setErrorEnqCat ] = useState< boolean >( false );
    const [ errorMessage, setErrorMessage ] = useState< boolean >( false );
    const [ helpText, setHelpText ] = useState< string >( '' );
    const dispatch = useAppDispatch();

    
    // Map opbject for package selection
    const packages = basicPackageDetail.map(( veh ) => {
        return {
            key: `${ veh.BenefitID } - ${ veh.Rego }`,
            label: `${ veh.Rego } - ${ veh.VehicleDescription }`,
            value: veh.BenefitID
        }
    })

    // Handle Benefit Selection
    function handleBenefitSelection( value: number ) {
        let selectedBenefit = basicPackageDetail.filter(( benefit ) => benefit.BenefitID === value );
        setContactRequest({ 
            ...contactRequest, SubmittedByContactID: userDetails.ContactID, PackageID: value, PackageDesc: selectedBenefit[ 0 ].VehicleDescription 
        });
    }

    const handleSendMessage = async () => {
        setErrorMessage( false );
        setErrorLease( false );
        setErrorEnqCat( false );
        setHelpText( '' );
        
        if ( contactRequest.PackageID === 0 ) {
            setErrorLease( true );
            setHelpText( 'Please select the package you are enquiring about' );
            return;
        }
        if ( contactRequest.SubjectID === 0 ) {
            setErrorEnqCat( true );
            setHelpText( 'Please select an enquiry category' );
            return;
        }
        if ( contactRequest.Message === '' || contactRequest.Message.length < 20 ) {
            setErrorMessage( true );
            setHelpText( 'Please enter a detailed message describing your query' );
            return;
        }
        const response = await dispatch( lodgeContactRequest( contactRequest ) ).unwrap();
        if ( response.success ) {
            resetState();
            dispatch( addAlert({ id: Date.now(), message: 'Your message has been sent', type: 'success' }) );
            return;
        } else {
            resetState();
            dispatch( addAlert({ id: Date.now(), message: 'Error occurred sending request', type: 'error' }) );
        }
    }

    function resetState() {
        setContactRequest( defaultContactRequest );
        setErrorMessage( false );
        setErrorLease( false );
        setErrorEnqCat( false );
        
    }

    return (
        <React.Fragment>

            <ContactUsHeader />

            <ContactUsContent>
                <Grid container
                    flexDirection='column'
                    justifyContent='center' 
                    alignItems='flex-start' 
                    columns={ columnBase }
                    spacing={ 1 }
                    p={ 1 }
                >

                    <ContactForm
                        packages={ packages }
                        subjectOptions={ ContactUsSubjectOptions }
                        message={ contactRequest.Message }
                        selectedPackage={ contactRequest.PackageID || 0 }
                        selectedSubject={ contactRequest.SubjectID || 0 }
                        handleSubject={ ( value ) => setContactRequest({ ...contactRequest, SubjectID: value }) }
                        handleBenefitSelection={ ( value ) => handleBenefitSelection( value ) }
                        handleMessage={ ( value ) => setContactRequest({ ...contactRequest, Message: value }) }
                        onClickHandler={ handleSendMessage }
                        xsmobile={ 4 } mobile={ 6 } tablet={ 8 } laptop={ 5 } desktop={ 6 }
                        leaseError={ errorLease }
                        categoryError={ errorEnqCat }
                        messageError={ errorMessage }
                        helpText={ helpText }
                    />

                    <Grid item
                        xsmobile={ 4 } mobile={ 6 } tablet={ 8 } laptop={ 5 } desktop={ 6 }
                    >
                        <Box sx={{ maxWidth: '100%', minWidth: '100%', maxHeight: '100%', minHeight: '100%', paddingTop: '1rem' }}>
                            <Typography variant="body1Bold">
                                Can't find what you need?
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="body1">
                                If you can't find what you're looking for, or if you have any questions, then you can 
                                fill out the form and we'll be in contact as soon as possible.
                                <br />
                                Alternatively, you can contact us directly on <a href='tel:1300551987'>1300 551 987</a> or email us at <a href='mailto:novatedoperations@iedge.com.au'>novatedoperations@iedge.com.au</a>
                            </Typography>
                        </Box>
                    </Grid>

                </Grid>
            </ContactUsContent>

        </React.Fragment>
    )
}