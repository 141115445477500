import React, { useState } from "react";
import { ReimbursementsContent } from '../../components/styled';
import { ReimbursementsHeader } from "./ReimbursementsHeader";
import { ReimbursementHistory, SubmitNewReimbursement, BankAcounts } from "./tabs";
import { BasicTabsComponent, TabProps } from '../../components/UI';



export default function Reimbursements() {
    const [ tabIndex, setTabIndex ] = useState< number >( 0 );
    
    const tabComponents: Array< TabProps > = [
        {
            tabTitle: 'Submit Reimbursement',
            tabComponent: <SubmitNewReimbursement />,
            index: 0
        },
        {
            tabTitle: 'Reimbursement History',
            tabComponent: <ReimbursementHistory />,
            index: 1
        },
        {
            tabTitle: 'Bank Accounts',
            tabComponent: <BankAcounts />,
            index: 2
        }
    ]

    return (
        <React.Fragment>

            <ReimbursementsHeader 
                Title='Reimbursements'
                TabIndex={ tabIndex }
                TabOneSubTitle='Submit a New Reimbursement Claim'
                TabTwoSubTitle='Reimbursement History'
                TabThreeSubTitle='Reimbursement Bank Accounts'
            />

            <ReimbursementsContent>
                
                <BasicTabsComponent
                    tabComponents={ tabComponents }
                    CurrentTabIndex={ ( i: number ) => setTabIndex( i ) }
                />

            </ReimbursementsContent>
            
        </React.Fragment>
    )
}