import React from "react";
import { SvgIcon, styled } from "@mui/material";

const CustomCancelCircle = ( props: any ) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            d="M12 2.5C6.76 2.5 2.5 6.76 2.5 12S6.76 21.5 12 21.5 21.5 17.24 21.5 12 17.24 2.5 12 2.5z"
            fill="none"
            stroke="#053CFF"
            strokeWidth="2"
            className="circle-path"
        />
        <path 
            d="M7.5 7.5L16.5 16.5M16.5 7.5L7.5 16.5"
            fill="none"
            stroke="#FF4C4C"
            strokeWidth="2"
            strokeDasharray="24"
            className="cross-path"
        />
    </SvgIcon>
)

const styles = {
    '@keyframes fly-in': {
        "0%": {
            transform: 'translateY(-50px)',
            opacity: 0
        },
        '50%': {
            transform: 'translateY(0)',
            opacity: 1
        },
        '80%': {
            transform: 'translateY(-5px)',
        },
        '100%': {
            transform: 'translateY(0)',
        }
    },
    '@keyframes impact': {
        '0%': {
            transform: 'translateX(0)'
        },
        '25%': {
            transform: 'translateX(-2px)'
        },
        '50%': {
            transform: 'translateX(2px)'
        },
        '75%': {
            transform: 'translateX(-2px)'
        },
        '100%': {
            transform: 'translateX(0)'
        }
    },
    '.cross-path': {
        animation: 'fly-in 0.5s ease-out forwards, impact 0.2s ease 0.5s forwards'
    }
}

export const AnimatedCancelCircle = styled( CustomCancelCircle )( styles )