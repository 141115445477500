import {
    styled,
    Chip,
    Avatar,
    List,
    ListItem,
    ListItemIcon,
    Menu,
    Box,
    IconButton,
    TableCell,
    Accordion,
    Card,
    alpha,
    Grid
} from '@mui/material';
import { stringToColor } from '../commonUIF';

// Sub folder exports \\
export * from "./Layout";
export * from "./NavMenu";
export * from "./BasicTabs";
export * from "./NavBarMobileView";
export * from "./BasicDropDown";
export * from "./Inputs_data";
export * from "./DataGroup";
export * from "./DialogComponents";
export * from "./Buttons";
export * from "./Alert";
export * from "./charts";
export * from "./ToggleSwitch";

// Profile Page \\
export const ProfileContent = styled( Box )(({ theme }) => ({
    elevation: 0,
    flex: 1,
    width: "100%",
    height: '100%'
}))

// Home Page \\
export const HomeContent = styled( Box )(({ theme }) => ({
    elevation: 0,
    flex: 1,
    height: '100%',
    width: "100%"
}))

// Package Account \\
export const AccountContent = styled( Box )(({ theme }) => ({
    elevation: 0,
    flex: 1,
    width: "100%",
    height: '100%'
}))

// Package Detail \\
export const PackageContent = styled( Box )(({ theme }) => ({
    elevation: 0,
    flex: 1,
    width: "100%",
    height: '100%'
}))

// Reimbursements \\
export const ReimbursementsContent = styled( Box )(({ theme }) => ({
    elevation: 0,
    flex: 1,
    width: "100%",
    height: '100%'
}))

// Documents \\
export const DocumentsContent = styled( Box )(({ theme }) => ({
    elevation: 0,
    flex: 1,
    width: "100%",
    height: '100%'
}))

// Contact Us \\
export const ContactUsContent = styled( Box )(({ theme }) => ({
    elevation: 0,
    flex: 1,
    width: "100%",
    height: '100%'
}))

// FAQ's \\
export const FaqsContent = styled( Box )(({ theme }) => ({
    elevation: 0,
    flex: 1,

}))

// Notifications \\
export const NotificationsContent = styled( Box )(({ theme }) => ({
    elevation: 0,
    flex: 1,
    width: "100%",
    height: '100%'
}))

// Header Menu & Profile Chip \\
export const ProfileMenuChip = styled( Chip )(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#e1e1e1',
    color: theme.palette.text.primary
}))

export const ProfileMenuAvatar = styled( Avatar )<{ name: string }>(({ theme, name }) => ({
    backgroundColor: stringToColor( name, theme.palette.mode === 'dark' ).hsl,
    '&.MuiAvatar-root': {
        color: stringToColor( name, theme.palette.mode === 'dark' ).textColor
    }
}))

export const ProfileMenuList = styled( List )(({ theme }) => ({
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0
}))

export const ProfileMenuListItem = styled( ListItem )(({ theme }) => ({
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
        backgroundColor: alpha( theme.palette.common.skyCyan, 0.2 )
    }
}))

export const ProfileMenuListItemIcon = styled( ListItemIcon )(({ theme }) => ({
    minWidth: '1.3rem'
}))

export const ProfileMenuElement = styled( Menu )(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundColor: `${ theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper }`,
        backgroundImage: theme.palette.mode === 'dark' ? `
            linear-gradient( ${ theme.palette.background.backgroundOverlay }, ${ theme.palette.background.backgroundOverlay } ),
            linear-gradient( ${ theme.palette.overlays.level1 }, ${ theme.palette.overlays.level1 } ),
            linear-gradient( ${ theme.palette.overlays.level16 }, ${ theme.palette.overlays.level16 } )
        `
        : 'none',
    }
}))


// Content Header \\
export const StyledContentHeader = styled( Box )(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : theme.palette.background.default,
    height: '5vh',
    width: '100%',
    pt: '1vh',
    pb: '1vh',
    marginBottom: '0.5rem',
    elevation: 0,
    boxShadow: theme.shadows[ 0 ]
}))


// Socials Component \\
export const SocialsContainer = styled( Box, { shouldForwardProp: ( prop ) => prop !== 'align' })<{ align: 'flex-start' | 'center' | 'flex-end', reverse: boolean | undefined }>(({ theme, align, reverse }) => ({
    display: 'flex',
    flexDirection: reverse ? 'row-reverse' : 'row',
    justifyContent: align,
    alignItems: 'baseline',
    height: 'inherit',
    elevation: 0,
    borderRadius: 0,
    backgroundColor: 'transparent',
    border: `none`,
}))

export const CustomIconButton = styled( IconButton )<{ customcolor?: string }>(({ theme, customcolor }) => ({
    color: customcolor ? customcolor : '',
}))


// File Upload Component \\
export const FileUploaderHeader = styled( Card )(({ theme }) => ({
    padding: '1rem',
    paddingLeft: '0.5rem', 
    borderRadius: '0rem',
    elevation: 0,
    borderTopLeftRadius: '0.3rem',
    borderTopRightRadius: '0.3rem',
    backgroundColor: theme.palette.mode === 'dark' ? 
        `${ theme.palette.overlays.level1 } !important` 
        : theme.palette.background.paper
}))

export const HiddenFileInput = styled( 'input' )(({ theme }) => ({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    width: 1,
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap'
}))

export const FileDropZone = styled( Box )<{ error: 'true' | 'false' }>(({ theme, error }) => ({
    border: `0.115rem dashed ${ theme.palette.borders.color }`,
    borderRadius: '0rem',
    borderBottomLeftRadius: '0.3rem',
    borderBottomRightRadius: '0.3rem',
    backgroundColor: theme.palette.mode === 'dark' ? 
        theme.palette.overlays.level1 : theme.palette.background.paper,
    padding: '0.3rem',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    overflow: 'hidden',
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    '&:hover': {
        borderColor: theme.palette.borders.hover,
        backgroundColor: theme.palette.mode === 'dark' ? 
            theme.palette.overlays.level2 : theme.palette.action.hover
    },
    ...( error === 'true' && {
        borderColor: theme.palette.error.main,
        '&:hover': {
            borderColor: theme.palette.error.light
        }
    })
}))

export const DropZoneCell = styled( TableCell )(({ theme }) => ({
    whiteSpace: 'nowrap', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis',
    padding: '0.5rem'
}))

export const UploadCard = styled( Card )(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '0.5rem',
    padding: '0.4rem',
    height: '3rem',
    borderRadius: '0.3rem',
    backgroundColor: theme.palette.mode === 'dark' ? 
    theme.palette.overlays.level1 : theme.palette.background.paper
}))


// Login Components \\
export const MobileLoginFormContainer = styled( Box )(({ theme }) => ({
    opacity: 0,
    display: 'none',
    transition: 'opacity 0.4s ease, transform 0.4s ease',
    '@media (max-width: 840px)': {
        opacity: 1,
        width: '75%',
        maxWidth: '350px',
        display: 'block'
    }
}))

export const LoginFormContainer = styled( Box )(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '35vw',
    height: '100vh',
    position: 'relative',
    transition: 'width 0.4s ease, height 0.4s ease',
    '@media (max-width: 840px)': {
        width: '100vw',
        height: '75vh',
    }
}))

export const SlideContainer = styled( Box )(({ theme }) => ({
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
}))

export const MobileSlideContainer = styled( Box )(({ theme }) => ({
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%'
}))

export const FadeContainer = styled( Box )(({ theme }) => ({
    flexDirection: 'row',
    position: 'relative',
    alignContent: 'flex-start',
    width: '100%',
    paddingTop: '6rem',
    paddingLeft: '3rem',
    opacity: 1,
    transition: 'opacity 0.4s ease, visibility 0.4s ease',
    '@media (max-width: 840px)': {
        opacity: 0,
        Visibility: 'hidden',
        position: 'absolute'
    },
    '@media (min-width: 841px)': {
        opacity: 1,
        Visibility: 'visible'
    }
}))

export const CredentialsFormContainer = styled( Box )<{ view: 'credentials' | 'loading' | 'mfa' | 'complete' }>(({ theme, view }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    transform: view === 'credentials' ? 'translateX(0%)' : 'translateX(-100%)', // Smooth transition using translateX
    transition: 'transform 0.4s ease'
}))

export const RegistrationsFormContainer = styled( Box )<{ view: 'details' | 'mfa' | 'loading' | 'noMobile' | 'complete' }>(({ theme, view }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    transform: view === 'details' ? 'translateX(0%)' : 'translateX(-100%)', // Smooth transition using translateX
    transition: 'transform 0.4s ease'
}))

export const MobileCredentialsFormContainer = styled( Box )<{ view: 'credentials' | 'mfa' | 'complete' }>(({ theme, view }) => ({
    position: 'absolute',
    width: '100%',
    top: 0,
    left: view === 'credentials' ? '0%' : '-100%',
    transition: 'left 0.4s ease'
}))

export const MfaFormContainer = styled( Box )<{ desktop: string, view: 'credentials' | 'loading' | 'mfa' | 'complete' }>(({ theme, desktop, view }) => ({
    paddingTop: desktop === 'true' ? '2rem' : '0',
    position: 'absolute',
    width: '100%',
    top: 0,
    left: view === 'mfa' ? '0%' : view === 'complete' ? '-100%' : '100%',
    transition: 'left 0.4s ease'
}))

export const LoginCompleteContainer = styled( Box )<{ view: 'credentials' | 'loading' | 'mfa' | 'complete' }>(({ theme, view }) => ({
    position: 'absolute',
    width: '100%',
    top: 0,
    left: view === 'complete' ? '0%' : '100%',
    transition: 'left 0.4s ease'
}))

export const StepUpSlideContainer = styled( Box )( ({ theme }) => ({
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 100,
    marginTop: '1.5rem'
}))

export const StepUpInputContainer = styled( Box )<{ view: 'code' | 'loading' }>(({ theme, view }) => ({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    top: 0,
    left: view === 'code' ? '0%' : '-100%',
    transition: 'left 0.4s ease',
}))

export const StepUpLoaderContainer = styled( Box )<{ view: 'code' | 'loading' }>(({ theme, view }) => ({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    top: 0,
    left: view === 'loading' ? '0%' : '100%',
    transition: 'left 0.4s ease',
}))

// Password Reset Components \\
export const UsernameEntryContainer = styled( Box )<{ view: 'user' | 'mfa' | 'verify' | 'reset' | 'complete' }>(({ theme, view }) => ({
    position: 'absolute',
    width: '100%',
    top: 0,
    left: view === 'user' ? '0%' : '-100%',
    transition: 'left 0.4s ease'
}))

export const PasswdConfirmationContainer = styled( Box )<{ view: 'user' | 'mfa' | 'verify' | 'reset' | 'complete' | 'failure' }>(({ theme, view }) => ({
    position: 'absolute',
    width: '100%',
    top: 0,
    left: view === 'reset' ? '0%' : '100%',
    transition: 'left 0.4s ease'
}))

export const SetPasswdContainer = styled( Box )<{ view: 'details' | 'mfa' | 'loading' | 'setPass' | 'complete' }>(({ theme, view }) => ({
    position: 'absolute',
    width: '100%',
    top: 0,
    left: view === 'setPass' ? '0%' : '100%',
    transition: 'left 0.4s ease'
}))

export const MobileUserEntryContainer = styled( Box )<{ view: 'user' | 'mfa' | 'verify' | 'reset' | 'complete' }>(({ theme, view }) => ({
    position: 'absolute',
    width: '100%',
    top: 0,
    left: view === 'user' ? '0%' : '-100%',
    transition: 'left 0.4s ease'
}))

export const MobileCofirmationContainer = styled( Box )<{ view: 'user' | 'mfa' | 'verify' | 'reset' | 'complete' }>(({ theme, view }) => ({
    position: 'absolute',
    width: '100%',
    top: 0,
    left: view === 'reset' ? '0%' : '100%',
    transition: 'left 0.4s ease'
}))

export const AccordionMapped = styled( Accordion )<{ open: boolean, index: number, blocklength: number }>(({ theme, open, index, blocklength }) => ({
    elevation: 0,
    border: 'none',
    borderTop: theme.palette.mode === 'dark' ? 
        `${ index === 0 ? 'none' : '1px solid rgba(81, 81, 81, 1)' }`
        :
        `${ index === 0 ? 'none' : '1px solid #e0e0e0' }`,
    borderRadius: '0px',
    '&.MuiPaper-root': {
        borderRadius:'0px',
        borderTopLeftRadius: index === 0 ? '0.3rem' : 0,
        borderTopRightRadius: index === 0 ? '0.3rem' : 0,
        borderBottomLeftRadius: index === blocklength - 1 ? '0.3rem' : 0,
        borderBottomRightRadius: index === blocklength - 1 ? '0.3rem' : 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.overlays.level1 : theme.palette.background.paper
    }
}))

// Add Phone Collapsable Grid Item \\
export const AnimatedGridItem = styled( Grid )<{ show: string }>(({ theme, show }) => ({
    opacity: 0,
    transform: 'translateY(-20px)',
    transition: 'opacity 0.4s ease, transform 0.4s ease',
    ...( show === 'true' && {
        opacity: 1,
        transform: 'translateY(0)'
    })
}))

// Password Reset Response Display \\
export const ResponseDisplayContainer = styled( Box )<{ view: string, slideon: string }>(({ theme, view, slideon }) => ({
    position: 'absolute',
    marginTop: view === 'noMobile' ? '3rem' : '2rem',
    width: '100%',
    height: '5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: view === slideon ? '0%' : '100%',
    transition: 'left 0.4s ease'
}))