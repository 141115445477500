import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomNavigation, useTheme, BottomNavigationAction, alpha, Box } from '@mui/material';
import { MobileNavMenuItems } from '../../constants';


export function NavBarMobile() {
    const [value, setValue] = React.useState('recents');
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme()
    const isDark = theme.palette.mode === 'dark'
    const handleTextColor = isDark ? theme.palette.text.primary : "#FFFFFF"
    const handleBackgroundColor = isDark ? alpha( theme.palette.common.skyCyan, 0.1 ) : alpha( theme.palette.common.skyCyan, 0.2 )

    const handleChange = ( event: React.SyntheticEvent, newValue: string ) => {
        setValue( newValue );
    };

    const mobleViewNavigator = ( path: string ) => {
        navigate( path, { state: { from: location.pathname }})
    }

    return (
        <BottomNavigation 
            value={ value }
            onChange={ handleChange }
            showLabels
            sx={{
                zIndex: 1,
                overflowX: 'hidden',
                width: '100%',
                backgroundColor: isDark ? theme.palette.background.default : theme.palette.primary.main,
                backgroundImage: isDark ? `
                    linear-gradient( ${ theme.palette.background.backgroundOverlay }, ${ theme.palette.background.backgroundOverlay } ),
                    linear-gradient( ${ theme.palette.overlays.level1 }, ${ theme.palette.overlays.level1 } ),
                    linear-gradient( ${ theme.palette.overlays.level3 }, ${ theme.palette.overlays.level3 } )
                `
                : 'none',
            }}
        >
            
            { MobileNavMenuItems.map(( item ) => (
                <BottomNavigationAction
                    key={ item.path }
                    label={ item.label }
                    value={ item.label }
                    icon={ item.icon }
                    onClick={ () => mobleViewNavigator( item.path ) }
                    sx={{
                        color: `${ handleTextColor } !important`, // Change color when selected
                        '&.Mui-selected': {
                            backgroundColor: handleBackgroundColor, // Apply the color of your choice for the selected item
                        },
                    }}
                />
            ))}

        </BottomNavigation>
    );
}


