import { useState, useEffect } from "react";
import { Box, Typography, Theme, alpha } from "@mui/material";
import { ResponseDisplayContainer, RoundedButton } from "../../components/styled";

interface ResponseDisplayProps {
    currentView: 'user' | 'mfa' | 'verify' | 'reset' | 'complete';
    theme: Theme;
}

export const SuccessResponse = ({ currentView, theme }: ResponseDisplayProps ) => (
    <ResponseDisplayContainer
        slideon="mfa"
        view={ currentView }
    >
        <Box
            sx={{
                width: '80%',
                padding: '0.5rem',
                borderRadius: '0.3rem',
                backgroundColor: alpha( theme.palette.success.main, 0.15 )
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Typography variant="h5">
                    Password reset initiated
                </Typography>
            </Box>
            <Box
                sx={{ 
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: '1rem'
                }}
            >
                <Typography variant="body1">
                    Please check your email inbox. We sent you an email with further instructions.
                </Typography>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '1rem'
                }}
            >
                
                <Typography  variant="dataValue">
                    Please close this window and check your email.
                </Typography>
            </Box>
        </Box>
    </ResponseDisplayContainer>
);



interface ResponseDisplayPropsReg {
    currentView: 'user' | 'mfa' | 'verify' | 'reset' | 'complete' | 'failure';
    theme: Theme;
    onClickHandler: () => void;
} 
export const PasswdResetSuccess = ({ currentView, onClickHandler, theme }: ResponseDisplayPropsReg ) => (
    <ResponseDisplayContainer
        slideon="complete"
        view={ currentView }
    >
        <Box
            sx={{
                width: '80%',
                padding: '0.5rem',
                borderRadius: '0.3rem',
                backgroundColor: alpha( theme.palette.success.main, 0.15 )
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Typography variant="h5">
                    Password reset successful
                </Typography>
            </Box>
            <Box
                sx={{ 
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: '1rem'
                }}
            >
                <Typography variant="body1">
                    Your password has been reset, you can now login.
                </Typography>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '1rem'
                }}
            >
                <RoundedButton onClick={ onClickHandler }>
                    <Typography  variant="button">
                        Login page
                    </Typography>
                </RoundedButton>
            </Box>
        </Box>
    </ResponseDisplayContainer>
);

export const PasswdResetFailure = ({ currentView, onClickHandler, theme }: ResponseDisplayPropsReg ) => (
    <ResponseDisplayContainer
        slideon="failure"
        view={ currentView }
    >
        <Box
            sx={{
                width: '80%',
                padding: '0.5rem',
                borderRadius: '0.3rem',
                backgroundColor: alpha( theme.palette.error.main, 0.15 )
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Typography variant="h5">
                    Password reset Failed
                </Typography>
            </Box>
            <Box
                sx={{ 
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: '1rem'
                }}
            >
                <Typography variant="body1">
                    Your password could not be reset. Please try again. If the problem persists, contact support.
                </Typography>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '1rem'
                }}
            >
                <RoundedButton onClick={ onClickHandler }>
                    <Typography  variant="button">
                        Login page
                    </Typography>
                </RoundedButton>
            </Box>
        </Box>
    </ResponseDisplayContainer>
);