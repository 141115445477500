import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { DataGroup, BasicButton } from '../../components/UI';
import { SendIcon } from "../../components/UI/Icons";
import { StandardTextInput, BasicDropDownMenu, BasicSelectOption} from '.';

interface ContactFormProps {
    packages: Array< BasicSelectOption >;
    subjectOptions: Array< BasicSelectOption >;
    message: string;
    selectedPackage: number;
    selectedSubject: number;
    leaseError: boolean;
    categoryError: boolean;
    messageError: boolean;
    helpText: string;
    handleBenefitSelection: ( value: number ) => void;
    handleSubject: ( value: number ) => void;
    handleMessage: ( value: string ) => void;
    onClickHandler: () => void;
    minHeight?: number | null;
    xsmobile: number;
    mobile: number;
    tablet: number;
    laptop: number;
    desktop: number;
}

export function ContactForm({ packages, subjectOptions, message, helpText, ...props }: ContactFormProps) {
    const {
        selectedPackage,
        selectedSubject,
        handleBenefitSelection,
        handleSubject,
        handleMessage,
        onClickHandler,
        messageError,
        categoryError,
        leaseError,
        minHeight,
        xsmobile,
        mobile,
        tablet,
        laptop,
        desktop
    } = props;
    const theme = useTheme();
    const isDark: boolean = theme.palette.mode === 'dark';


    return (
        <DataGroup title='Contact Form'
            minHeight={ minHeight ? minHeight : null }
            xsmobile={ xsmobile }
            mobile={ mobile }
            tablet={ tablet }
            laptop={ laptop }
            desktop={ desktop }
        >
            <Grid item
                xsmobile={ 4 }
                mobile={ 5 }
                tablet={ 8 }
                laptop={ 10 }
                desktop={ 12 } 
                sx={{ display: 'block', paddingBottom: '0.5rem' }}
            >
                <Typography variant='body1'>
                    If you prefer us to contact you, fill out the form and we will get back to you as soon as possible
                </Typography>
            </Grid>

            {/* Package Select  */}
            <Grid item
                xsmobile={ 4 }
                mobile={ 5 }
                tablet={ 4 }
                laptop={ 5 }
                desktop={ 6 }
                sx={{ width: '100%' }}
            >
                <BasicDropDownMenu
                    menuLabel='Select the lease you are enquiring about'
                    selectID='packageSelect'
                    options={ packages}
                    size='small'
                    value={ selectedPackage }
                    onChange={ ( value ) => handleBenefitSelection( value ) }
                    error={ leaseError }
                />
            </Grid>
            
            {/* Subject Selection  */}
            <Grid item
                xsmobile={ 4 }
                mobile={ 5 }
                tablet={ 4 }
                laptop={ 5 }
                desktop={ 6 }
                sx={{ width: '100%' }}
            >
                <BasicDropDownMenu
                    menuLabel='Attempt to categorise your enquiry'
                    selectID='subject-select'
                    options={ subjectOptions }
                    value={ selectedSubject }
                    size='small'
                    onChange={ ( value ) => handleSubject( value ) }
                    error={ categoryError }
                />
            </Grid>

            {/* Message Text Area */}
            <Grid item
                xsmobile={ 4 }
                mobile={ 5 }
                tablet={ 8 }
                laptop={ 10 }
                desktop={ 12 }
            >
                <StandardTextInput
                    label='Message'
                    isTextArea
                    textAreaRows={ 5 }
                    type='text'
                    variant='outlined'
                    size='small'
                    value={ message }
                    onChange={ ( value ) => {
                        const cleanVal = value.replace( /[^a-zA-Z0-9.,?$@/-\s]/g, '' );
                        handleMessage( cleanVal ) 
                    }}
                    error={ messageError }
                />
                <Typography variant='caption' sx={{ color: theme.palette.error.main }}
                >
                    { helpText }
                </Typography>
            </Grid>

            {/* Send Button */}
            <Grid item
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                xsmobile={ 4 }
                mobile={ 5 }
                tablet={ 8 }
                laptop={ 10 }
                desktop={ 12 }
            >
                <BasicButton
                    text='Send Message'
                    icon={ <SendIcon outlined={ isDark } size='small' color='inherit' /> }
                    iconPosition='end'
                    onClickHandler={ onClickHandler }
                    size='small'
                    disabled={ false }
                />
            </Grid>

        </DataGroup>
    )
}