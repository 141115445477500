import React, { ReactElement } from "react";
import { useAppSelector } from "../../redux/Store";
import { PreAuthErrorBoundary, AuthErrorBoundary } from ".";
import { useMediaWidth } from '../../hooks';
import { ErrorBoundary, FallbackRender } from "@sentry/react";

export const ErrorFallback: FallbackRender = ({ error }) => {
	const { Authenticated } = useAppSelector(( state ) => state.Access )
    const { navMenu } = useAppSelector(( state ) => state.AppState )
    const width = useMediaWidth({ direction: 'down', breakPoint: 'laptop' })  
    function resetErrorBoundary() {
        window.location.reload()
    }

    const BoundaryComponent = Authenticated ? AuthErrorBoundary : PreAuthErrorBoundary
    
    return (
        <BoundaryComponent
            width={ width }
            nav={ navMenu }
            resetBoundary={ resetErrorBoundary }
            error={ error }
            version={ `${ process.env.APP_VERSION }` }
            appName={ `${ process.env.APP_NAME }` }
        />
    )
}