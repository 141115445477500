import React, { createContext, ReactNode, useState, useMemo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/Store";
import { restoreAccessState, setAuthState } from "../redux/slicers/Access";
import { getUserDetails, getBasicPackageDetail } from "../redux/AccessThunks";
import { restoreAppSettings } from "../redux/slicers/AppState";


type Props = {
    children: ReactNode
}

type IAuthContextType = {
    Authenticated: boolean,
    loading: boolean,
    setAuth: ( newState: boolean ) => void
}

const initialValue = {
    Authenticated: false,
    loading: true,
    setAuth: ( newState: boolean ) => {}
}

export const AuthContext = createContext< IAuthContextType >( initialValue )

export const AuthProvider = ({ children }: Props ) => {
    const { Authenticated, session, userDetails } = useAppSelector(( state ) => state.Access )
    const [ loading, setLoading ] = useState< boolean >( true ) 
    const dispatch = useAppDispatch()

    let AuthID = session?.AuthID || ''

    useEffect( () => {
        const appSettings = localStorage.getItem( 'appSettings' )
        if ( appSettings ){
            dispatch( restoreAppSettings( JSON.parse( appSettings ) ))
        }
        const stateObj = sessionStorage.getItem( 'state' )
        if ( stateObj ) {
            dispatch( restoreAccessState( JSON.parse( stateObj ) ))
        }

        setLoading( false )
    }, [] )

    useEffect( () => {
        if ( !userDetails?.ContactID && Authenticated && session?.ContactID ) {
            ( async () => {
                await dispatch( getUserDetails({ ContactID: session.ContactID }) );
                await dispatch( getBasicPackageDetail({ ContactID: session.ContactID }) );
                setLoading( false )
            })();
        }
    }, [ Authenticated ] )

    const setAuth = ( newState: boolean ) => {
        if ( Authenticated !== newState && AuthID !== '' ) {
            dispatch( setAuthState({ newState: newState, authID: AuthID }))
        }
    }

    const contextValue = useMemo(
        () => ({
            Authenticated,
            loading,
            setAuth
        }), 
        [ Authenticated ]
    )
    
    return (
        <AuthContext.Provider value={ contextValue }>
            { children }
        </AuthContext.Provider>
    )
}

export default AuthProvider;