import React, { useState } from 'react';
import { RadioToggle, RadioToggleLabel } from '../styled';
import { FormControl } from '@mui/material';

interface RadioToggleInputProps {
    defaultChecked: boolean;
    label: string | React.ReactNode;
    size: 'small' | 'medium';
    variant: 'outlined' | 'filled' | 'standard';
    labelPlacement?: 'start' | 'top' | 'bottom' | 'end';
    disabled?: boolean;
    onChange: ( value: boolean ) => void;
}

export function RadioToggleInput({ ...props }: RadioToggleInputProps ) {
    const { onChange, defaultChecked, label, size, variant, labelPlacement } = props;
    const [ selectedValue, setSelectedValue ] = useState< boolean >( defaultChecked );

    const handleChange = (value: boolean) => {
        setSelectedValue( value );
        onChange( value )
    };

    return (
        <FormControl component="fieldset"
            fullWidth
            variant={ variant }
            disabled={ props.disabled || false }
        >
            <RadioToggleLabel
                label={ label }
                labelPlacement={ labelPlacement ? labelPlacement : 'end' }
                disabled={ props.disabled || false }
                control={ 
                    <RadioToggle
                        checked={ selectedValue === true }
                        onClick={ () => handleChange( !selectedValue ) }
                        value={ selectedValue }
                        name="radio-select"
                        size={ size ? size : 'medium' }
                        disabled={ props.disabled || false }
                        inputProps={{ 'aria-label': `${ label }` }}
                    /> 
                }
            />
        </FormControl>
    );
}