import { styled, Divider, Box, Typography } from '@mui/material';

export const DataCard = styled( Box )(({ theme }) => ({
    padding: '0.3rem',
    backgroundColor: 'transparent'
}))

export const DataCardContent = styled( Box )(({ theme }) => ({
    padding: '0rem',
    '&:last-child': {
        paddingBottom: '0rem'
    }
}))

export const DataCardLabel = styled( Typography )(({ theme }) => ({
    component: 'span',
    display: 'block',
    paddingBottom: '0.7rem',
    ...theme.typography.dataLabel
}))

export const DataCardValue = styled( Typography )(({ theme }) => ({
    component: 'span',
    display: 'block',
    paddingBottom: '0.5rem',
    borderBottom: `1px solid ${ theme.palette.text.primary }`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
}))

export const DataDivider = styled( Divider )(({ theme }) => ({

}))